// Helper function to generate memory addresses (for visualization)
function generateMemoryAddress() {
    return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
}

class BinaryTreeNode {
    constructor(data, address) {
      this.data = data;
      this.left = null;
      this.right = null;
      this.address = address;
    }
}
  
class BinaryTreeForPython {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'None', child: newNode.address }
            }));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;

            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'None'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));

                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'None', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };
  
    // Level Order Traversal
    levelOrderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {


        await highlightLine(6);
        await customSleep(6);
  
        await highlightLine(7);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep(7);
        setHighlightedRootVariables([]);

        await highlightLine(8);
        setHighlightedRootVariables(['current']);
        await customSleep(8);
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(9);
            await customSleep(9);
            return;
        }

        await highlightLine(11);
        setHighlightedRootVariables(['current']);
        let queue = [current];
        let _queue = [current.address];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        setHighlightedStackVariables(['queue']);
        await customSleep(null, 1200);
        setHighlightedStackVariables([]);
        setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
        await customSleep(11);
        setHighlightedStackIndex([]);
        setHighlightedRootVariables([]);
    
        while (queue.length > 0) {
            await highlightLine(12);
            setHighlightedStackVariables(['queue']);
            await customSleep(12);
            setHighlightedStackVariables([]);

            await highlightLine(13);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep();
            setHighlightedStackIndex([]);
            current = queue.shift();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            _queue.shift();
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(13);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            await highlightLine(14);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(14);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);
    
            await highlightLine(15);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep(15);
            setHighlightLeftPart([]);
            if (current.left !== null) {
                await highlightLine(16);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                _queue.push("");
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue.push(current.left);
                _queue[_queue.length - 1] = current.left ? current.left.address : 'None';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(16);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightLeftPart([]);
            }

            await highlightLine(17);
            setHighlightRightPart([`${current.address}`]);
            await customSleep(17);
            setHighlightRightPart([]);
            if (current.right !== null) {
                await highlightLine(18);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                _queue.push("");
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue.push(current.right);
                _queue[_queue.length - 1] = current.right ? current.right.address : 'None';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(18);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightRightPart([]);
            }

            if (!(queue.length > 0)) {
                await highlightLine(12);
                setHighlightedStackVariables(['queue']);
                await customSleep(12);
                setHighlightedStackVariables([]);
            }
        }
        setStackVariables({});
    };
}

export const binaryTreePython = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForPython();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreeLevelorderTraversalPython = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.levelOrderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}


class BinaryTreeForJava {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'null', child: newNode.address }
            }));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;

            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'null'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'null'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));

                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'null', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };
  
    // Level Order Traversal
    levelOrderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {


        await highlightLine(10);
        await customSleep(10);
  
        await highlightLine(11);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep(11);
        setHighlightedRootVariables([]);

        await highlightLine(12);
        setHighlightedRootVariables(['current']);
        await customSleep(12);
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(13);
            await customSleep(13);
            return;
        }

        await highlightLine(16);
        let queue = [];
        let _queue = [];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        setHighlightedStackVariables(['queue']);
        await customSleep(16);
        setHighlightedStackVariables([]);

        await highlightLine(17);
        setHighlightedRootVariables(['current']);
        queue = [""];
        _queue = [""];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
        await customSleep(null, 1200);
        queue = [current];
        _queue = [current.address];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        await customSleep(17);
        setHighlightedStackIndex([]);
        setHighlightedRootVariables([]);
    
        while (queue.length > 0) {
            await highlightLine(18);
            setHighlightedStackVariables(['queue']);
            await customSleep(18);
            setHighlightedStackVariables([]);

            await highlightLine(19);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep();
            setHighlightedStackIndex([]);
            current = queue.shift();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            _queue.shift();
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(19);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            await highlightLine(20);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(20);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);
    
            await highlightLine(21);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep(21);
            setHighlightLeftPart([]);
            if (current.left !== null) {
                await highlightLine(22);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                _queue.push("");
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue.push(current.left);
                _queue[_queue.length - 1] = current.left ? current.left.address : 'None';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(22);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightLeftPart([]);
            }

            await highlightLine(24);
            setHighlightRightPart([`${current.address}`]);
            await customSleep(24);
            setHighlightRightPart([]);
            if (current.right !== null) {
                await highlightLine(25);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                _queue.push("");
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue.push(current.right);
                _queue[_queue.length - 1] = current.right ? current.right.address : 'None';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(25);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightRightPart([]);
            }

            if (!(queue.length > 0)) {
                await highlightLine(18);
                setHighlightedStackVariables(['queue']);
                await customSleep(18);
                setHighlightedStackVariables([]);
            }
        }
        setStackVariables({});
    };
}

export const binaryTreeJava = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForJava();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreeLevelorderTraversalJava = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.levelOrderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}

class BinaryTreeForCpp {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'nullptr' }
            }));
            
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'nullptr'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'nullptr', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

    // Level Order Traversal
    levelOrderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {


        await highlightLine(12);
        await customSleep(12);
  
        await highlightLine(13);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep(13);
        setHighlightedRootVariables([]);

        await highlightLine(14);
        setHighlightedRootVariables(['current']);
        await customSleep(14);
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(15);
            await customSleep(15);
            return;
        }

        await highlightLine(18);
        let queue = [];
        let _queue = [];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        setHighlightedStackVariables(['queue']);
        await customSleep(18);
        setHighlightedStackVariables([]);

        await highlightLine(19);
        setHighlightedRootVariables(['current']);
        queue = [""];
        _queue = [""];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
        await customSleep(null, 1200);
        queue = [current];
        _queue = [current.address];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        await customSleep(19);
        setHighlightedStackIndex([]);
        setHighlightedRootVariables([]);
    
        while (queue.length > 0) {
            await highlightLine(20);
            setHighlightedStackVariables(['queue']);
            await customSleep(20);
            setHighlightedStackVariables([]);

            await highlightLine(21);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep();
            setHighlightedStackIndex([]);
            current = queue[0];
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(21);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);
            
            await highlightLine(22);
            setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
            await customSleep();
            setHighlightedStackIndex([]);
            queue.shift();
            _queue.shift();
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            await customSleep(22);
            setHighlightedRootVariables([]);

            await highlightLine(23);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(23);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);
    
            await highlightLine(24);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep(24);
            setHighlightLeftPart([]);
            if (current.left !== null) {
                await highlightLine(25);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                _queue.push("");
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue.push(current.left);
                _queue[_queue.length - 1] = current.left ? current.left.address : 'nullptr';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(25);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightLeftPart([]);
            }

            await highlightLine(27);
            setHighlightRightPart([`${current.address}`]);
            await customSleep(27);
            setHighlightRightPart([]);
            if (current.right !== null) {
                await highlightLine(28);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                _queue.push("");
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue.push(current.right);
                _queue[_queue.length - 1] = current.right ? current.right.address : 'nullptr';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(28);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightRightPart([]);
            }

            if (!(queue.length > 0)) {
                await highlightLine(20);
                setHighlightedStackVariables(['queue']);
                await customSleep(20);
                setHighlightedStackVariables([]);
            }
        }
        setStackVariables({});
    };

}

export const binaryTreeCpp = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForCpp();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreeLevelorderTraversalCpp = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.levelOrderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}

class BinaryTreeForC {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'NULL' }
            }));
            
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'NULL'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'NULL', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

    // Levelorder Traversal
    levelorderTraversal = async (
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        logMessage,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    ) => {

        await highlightLine(9);
        await customSleep(9);

        await highlightLine(10);
        setHighlightedRootVariables(['root']);
        await customSleep(10);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(11);
            await customSleep(11);
            return;
        }

        await highlightLine(14);
        let queue = [];
        let _queue = [];
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        setHighlightedStackVariables(['queue']);
        await customSleep(14);
        setHighlightedStackVariables([]);

        await highlightLine(15);
        let front = 0;
        let rear = 0
        setVariables((vars) => ({ ...vars, 
            front: { variable_name: 'front', value: front },
            rear: { variable_name: 'rear', value: rear }
        }));
        setHighlightedVariables(['front', 'rear']);
        await customSleep(15);
        setHighlightedVariables([]);

        await highlightLine(17);
        setHighlightedRootVariables(['root']);
        setHighlightedVariables(['rear']);
        setStrikeThroughStackIndex([]);
        queue[rear] = this.root;
        _queue[rear] = this.root.address;
        setStackVariables({ queue: { variable_name: 'queue', value: [" "] } });
        setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
        await customSleep(null, 1200);
        setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
        await customSleep(null, 1200);
        setHighlightedRootVariables([]);
        rear += 1;
        setVariables((vars) => ({ ...vars, 
            rear: { variable_name: 'rear', value: rear }
        }));
        await customSleep(17);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);

        while (front < rear) {
            await highlightLine(19);
            setHighlightedVariables(['front', 'rear']);
            await customSleep(19);
            setHighlightedVariables([]);

            await highlightLine(20);
            let current = queue[front];
            setHighlightedVariables(['front']);
            setHighlightedStackIndex([{ stackName: 'queue', index: front }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep(null, 1200);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));
            setHighlightedStackIndex([]);
            let strikeIndex = front;
            setStrikeThroughStackIndex((prevState) => [
                ...prevState, 
                { stackName: 'queue', index: strikeIndex }
            ]);
            await customSleep(null, 1200);
            front += 1;
            setVariables((vars) => ({ ...vars, 
                front: { variable_name: 'front', value: front }
            }));
            await customSleep(20);
            setHighlightedVariables([]);
            setHighlightedRootVariables(['current']);

            await highlightLine(22);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(22);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            await highlightLine(24);
            setHighlightedRootVariables(['current']);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep(24);
            setHighlightedRootVariables([]);
            setHighlightLeftPart([]);
            if (current.left !== null) {
                await highlightLine(25);
                setHighlightedRootVariables(['current']);
                setHighlightedVariables(['rear']);
                setHighlightLeftPart([`${current.address}`]);
                _queue[rear] = " ";
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue[rear] = current.left;
                _queue[rear] = current.left ? current.left.address : 'NULL';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(null, 1200);
                setHighlightedRootVariables([]);
                rear += 1;
                setVariables((vars) => ({ ...vars, 
                    rear: { variable_name: 'rear', value: rear }
                }));
                await customSleep(25);
                setHighlightedVariables([]);
                setHighlightLeftPart([]);
                setHighlightedStackIndex([]);
            }

            await highlightLine(28);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            await customSleep(28);
            setHighlightedRootVariables([]);
            setHighlightRightPart([]);
            if (current.right !== null) {
                await highlightLine(29);
                setHighlightedRootVariables(['current']);
                setHighlightedVariables(['rear']);
                setHighlightRightPart([`${current.address}`]);
                _queue[rear] = " ";
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                await customSleep(null, 1200);
                queue[rear] = current.right;
                _queue[rear] = current.right ? current.right.address : 'NULL';
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(null, 1200);
                setHighlightedRootVariables([]);
                rear += 1;
                setVariables((vars) => ({ ...vars, 
                    rear: { variable_name: 'rear', value: rear }
                }));
                await customSleep(29);
                setHighlightedVariables([]);
                setHighlightRightPart([]);
                setHighlightedStackIndex([]);
            }

            if (!(front < rear)) {
                await highlightLine(19);
                setHighlightedVariables(['front', 'rear']);
                await customSleep(19);
                setHighlightedVariables([]);
            }
        }
        
        // Delete current from rootVariables
        setRootVariables(() => ({
            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
        }));
        // remove stack variable
        setStackVariables({});
        // remove variables
        setVariables({});
    };

}

export const binaryTreeC = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
  ) => {
    let binaryTree = new BinaryTreeForC();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    return binaryTree;
};

export const binaryTreeLevelorderTraversalC = async (
    globalBinaryTree,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    logMessage,
    highlightLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    await globalBinaryTree.levelorderTraversal(
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        logMessage,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
}
