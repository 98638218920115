import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useCharTypes } from './useCharTypes';

const CharTypes = ({ language, subTopicId }) => {
  // Define the char variables in the component
  const [modalVisible, setModalVisible] = useState(false);
  const [letter, setLetter] = useState("A");
  const [digit, setDigit] = useState("1");
  const [specialChar, setSpecialChar] = useState("@");

  const [inputLetter, setInputLetter] = useState(letter);
  const [inputDigit, setInputDigit] = useState(digit);
  const [inputSpecialChar, setInputSpecialChar] = useState(specialChar);

  const [breakpoints, setBreakpoints] = useState([]);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runCharTypes
  } = useCharTypes(
    language,
    letter,
    digit,
    specialChar,
    breakpoints
  );

  const handleUpdateVariables = () => {
    setLetter(inputLetter);
    setDigit(inputDigit);
    setSpecialChar(inputSpecialChar)
    updateVariables(inputLetter, inputDigit, inputSpecialChar);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Char Types Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputLetter(letter);
              setInputDigit(digit);
              setInputSpecialChar(specialChar);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runCharTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        letter={inputLetter}
        setLetter={setInputLetter}
        digit={inputDigit}
        setDigit={setInputDigit}
        specialChar={inputSpecialChar}
        setSpecialChar={setInputSpecialChar}
        handleUpdateVariables={handleUpdateVariables}
      />
    </div>
  );
};

export default CharTypes;
