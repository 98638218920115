import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useStringImmutable } from './useStringImmutable';

const StringImmutable = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [string1, setString1] = useState("Hello");
  const [string2, setString2] = useState("World");

  const [inputString1, setInputString1] = useState(string1);
  const [inputString2, setInputString2] = useState(string2);

  const [breakpoints, setBreakpoints] = useState([]);
  
  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    stringVariables,
    highlightedVariables,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runStringImmutable,
  } = useStringImmutable(language, string1, string2, breakpoints);


  const handleUpdateVariables = () => {
    const sanitizedStr1 = (inputString1 || string1).slice(0, 15);
    const sanitizedStr2 = (inputString2 || string2).slice(0, 15);
    
    setString1(sanitizedStr1);
    setString2(sanitizedStr2);

    updateVariables(sanitizedStr1, sanitizedStr2);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"String Immutability"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputString1(string1);
              setInputString2(string2);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runStringImmutable}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              stringVariables={stringVariables}
              highlightedVariables={highlightedVariables}
              highlightedStringVariables={highlightedStringVariables}
              highlightedStringIndices={highlightedStringIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        string1={inputString1}
        string2={inputString2}
        setString1={setInputString1}
        setString2={setInputString2}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default StringImmutable;
