import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useFloatTypes } from './useFloatTypes';

const FloatTypes = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [piValue, setPiValue] = useState(3.14159);
  const [averageBodyTemperature, setAverageBodyTemperature] = useState(36.6);
  const [gravitationalAcceleration, setGravitationalAcceleration] = useState(9.81);

  const [inputPiValue, setInputPiValue] = useState(piValue);
  const [inputAverageBodyTemperature, setInputAverageBodyTemperature] = useState(averageBodyTemperature);
  const [inputGravitationalAcceleration, setInputGravitationalAcceleration] = useState(gravitationalAcceleration);

  const [breakpoints, setBreakpoints] = useState([]);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runFloatTypes
  } = useFloatTypes(
    language,
    piValue,
    averageBodyTemperature,
    gravitationalAcceleration,
    breakpoints
  );

  const handleUpdateVariables = () => {
    setPiValue(inputPiValue);
    setAverageBodyTemperature(inputAverageBodyTemperature);
    setGravitationalAcceleration(inputGravitationalAcceleration);
    updateVariables(inputPiValue, inputAverageBodyTemperature, inputGravitationalAcceleration);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Float Types Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputPiValue(piValue);
              setInputAverageBodyTemperature(averageBodyTemperature);
              setInputGravitationalAcceleration(gravitationalAcceleration);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runFloatTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        piValue={inputPiValue}
        setPiValue={setInputPiValue}
        averageBodyTemperature={inputAverageBodyTemperature}
        setAverageBodyTemperature={setInputAverageBodyTemperature}
        gravitationalAcceleration={inputGravitationalAcceleration}
        setGravitationalAcceleration={setInputGravitationalAcceleration}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default FloatTypes;
