import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the use of <b>if else</b> conditional statements to categorize a number. 
        It checks if the number is positive, negative, or zero and prints the appropriate message based on the condition that evaluates to True.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Data Validation:</b> Check if numerical input is positive, negative, or zero (e.g., balance in a bank account).</li>
            <li><b>Statistical Analysis:</b> Classify numbers into categories like positive or negative for further processing.</li>
            <li><b>Game Logic:</b> Implement conditions for scores or thresholds (e.g., health points above or below zero).</li>
            <li><b>Educational Tools:</b> Teach basic conditional structures and decision-making in programming.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
