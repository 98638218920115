import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PaymentButton from './PaymentButton';
import PayPalCheckout from './PayPalCheckout';
import Testimonials from './Testimonials';


const PricingSection = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState('₹');
  const [currency, setCurrency] = useState('INR');
  const [sixMonthPrice, setSixMonthPrice] = useState(299);
  const [oneYearPrice, setOneYearPrice] = useState(499);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const sixMonthPaymentRef = useRef(null);
  const oneYearPaymentRef = useRef(null);

  useEffect(() => {
      const query = new URLSearchParams(location.search);
      const subscriptionPlan = query.get('subscription');

      if (subscriptionPlan === '6-month' && sixMonthPaymentRef.current) {
          sixMonthPaymentRef.current();
      } else if (subscriptionPlan === '1-year' && oneYearPaymentRef.current) {
          oneYearPaymentRef.current();
      }
  }, [location.search]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const calculateDaysRemaining = (expiresAt) => {
    if (!expiresAt) return null;

    const targetDate = new Date(expiresAt);
    const currentDate = new Date();
    
    const differenceInTime = targetDate - currentDate;

    const remainingDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    if (remainingDays > 1) {
      return `${remainingDays} days`;
    } else if(remainingDays === 1) {
      return `${remainingDays} day`;
    } else {
      return `0 days`;
    }
  };

  useEffect(() => {
    // Set an SEO-friendly page title
    document.title = `Pricing | Coding Canvas`;

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", `Explore interactive tutorials, code samples, and exercises to learn programming visually on Coding Canvas.`);
    }

    // Fetch user's region
    const fetchRegion = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userRegion = response.data.country_code;

        if (userRegion !== 'IN') {
          setCurrencySymbol('$');
          setCurrency('USD');
          setSixMonthPrice(6);
          setOneYearPrice(9);
        }
      } catch (error) {
        console.error('Error fetching region:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRegion();

    // Fetch subscription details
    const fetchSubscriptionDetails = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/api/subscription/`, {
          headers: { Authorization: `Token ${token}` },
        });
        setSubscriptionInfo(response.data);
      } catch (error) {
        console.error('Error fetching subscription details:', error);
      }
    };

    fetchSubscriptionDetails();
  }, [apiUrl]);

  const handleGetStartedClick = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn) {
      navigate('/');
    } else {
      // Dispatch an event or directly show the login modal
      const registerModalEvent = new Event('showRegisterModal');
      window.dispatchEvent(registerModalEvent);
    }
  };

  return (
    <main className="container-fluid padding-25px">
      {/* Pricing Section */}
      <section className="text-center">
        <h1 className="mb-3">Pricing Plans</h1>
        <p>Choose a plan that suits your learning goals. Start with our Free plan or subscribe for additional features and extended access.</p>
      </section>

      <div className="row text-center">
        {/* Free Plan */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-header bg-dark text-white opacity-95">Free</div>
            {!loading ? 
              <div className="card-body">
                <h2 className="card-title">{currencySymbol}0 <small className="text-muted">/ month</small></h2>
                <ul className="list-unstyled">
                  <li>Access to Basic Topics</li>
                  <li>Limited Visualizations</li>
                  <li>No Support</li>
                </ul>
                {subscriptionInfo?.plan === '6-month' || subscriptionInfo?.plan === '1-year' ?
                  <>
                    <p style={{fontSize: '12px', fontWeight: 'bold'}}>{calculateDaysRemaining(subscriptionInfo?.expires_at)} left on your current plan!</p>
                    <span className="fas fa-times-circle user-progress-tick-mark btn btn-dark"></span>
                  </>
                  :
                  <>
                  {currency === 'INR'
                    ? <button
                        className="btn btn-dark mt-3 py-2"
                        onClick={handleGetStartedClick}
                      >
                        Get Started
                      </button>
                    :
                      <>
                        <button
                          className="btn btn-dark py-2 w-100"
                          onClick={handleGetStartedClick}
                        >
                          Get Started
                        </button>
                      </>
                  }
                  </>
                }
              </div> :
              <div style={{alignItems: 'center' }}>
                Loading &nbsp;
                <span className="dot-ellipsis">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </div>
            }
          </div>
        </div>

        {/* 6 Months Plan */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-header bg-dark text-white opacity-95">6 Months</div>
            {!loading ? 
              <div className="card-body">
                <h2 className="card-title">{currencySymbol}{sixMonthPrice} <small className="text-muted">/ 6 months</small></h2>
                <ul className="list-unstyled">
                  <li>Access to All Topics</li>
                  <li>Unlimited Visualizations</li>
                  <li>Email Support</li>
                </ul>
                {subscriptionInfo?.plan === '6-month' 
                  ? <>
                      <p style={{color: 'green', fontSize: '12px', fontWeight: 'bold'}}>Expiring At {formatDate(subscriptionInfo?.expires_at)}</p>
                      <span className="fas fa-check-circle user-progress-tick-mark btn btn-success"></span>
                    </>
                  : subscriptionInfo?.plan === '1-year' ?
                    <>
                      <p style={{fontSize: '12px', fontWeight: 'bold'}}>{calculateDaysRemaining(subscriptionInfo?.expires_at)} left on your current plan!</p>
                      <span className="fas fa-times-circle user-progress-tick-mark btn btn-dark"></span>
                    </>
                    :
                    <>
                    {currency === 'INR'
                      ?
                        <PaymentButton amount={sixMonthPrice} currency={currency} currencySymbol={currencySymbol} plan={'6-month'} triggerPayment={sixMonthPaymentRef} />
                      :
                        <PayPalCheckout amount={sixMonthPrice} currency={currency} plan={'6-month'}  />
                    }
                    </>
                }
              </div> :
              <div style={{alignItems: 'center' }}>
                Loading &nbsp;
                <span className="dot-ellipsis">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </div>
            }
          </div>
        </div>

        {/* 1 Year Plan */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-header bg-dark text-white opacity-95">1 Year</div>
            {!loading ? 
              <div className="card-body">
                <h2 className="card-title">{currencySymbol}{oneYearPrice} <small className="text-muted">/ year</small></h2>
                <ul className="list-unstyled">
                  <li>Access to All Topics</li>
                  <li>Unlimited Visualizations</li>
                  <li>Email Support</li>
                </ul>
                {subscriptionInfo?.plan === '1-year' 
                  ? <>
                      <p style={{color: 'green', fontSize: '12px', fontWeight: 'bold'}}>
                        {/* Expiring At {formatDate(subscriptionInfo?.expires_at)} */}
                        Subscription active till {formatDate(subscriptionInfo?.expires_at)}!
                      </p>
                      <span className="fas fa-check-circle user-progress-tick-mark btn btn-success"></span>
                    </>
                  : subscriptionInfo?.plan === '6-month' ? 
                    <>
                      <p style={{fontSize: '12px', fontWeight: 'bold'}}>{calculateDaysRemaining(subscriptionInfo?.expires_at)} left on your current plan!</p>
                      <span className="fas fa-times-circle user-progress-tick-mark btn btn-dark"></span>
                    </>
                    :
                    <>
                    {currency === 'INR'
                      ?
                        <PaymentButton amount={oneYearPrice} currency={currency} currencySymbol={currencySymbol} plan={'1-year'} triggerPayment={oneYearPaymentRef}/>
                      :
                        <PayPalCheckout amount={oneYearPrice} currency={currency} plan={'1-year'}  />
                    }
                    </>
                }
              </div> :
              <div style={{alignItems: 'center' }}>
                Loading &nbsp;
                <span className="dot-ellipsis">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </div>
            }
          </div>
        </div>
      </div>
      
      <Testimonials></Testimonials>

        {/* Why Choose Us Section */}
        <section className="my-5 text-center">
        <h2 className="mb-4">Why Choose CodingCanvas?</h2>
        <p>At CodingCanvas, we prioritize interactive learning, visual understanding, and real-world coding applications. With access to a wide array of topics, personalized support, and community engagement, our platform is designed to make programming accessible and enjoyable for everyone.</p>
        <ul className="list-unstyled">
            <li>✔ Proven teaching methods used by thousands of students</li>
            <li>✔ Detailed visualizations for deeper understanding</li>
            <li>✔ Community-driven support and engagement</li>
            <li>✔ Affordable pricing with maximum value</li>
        </ul>
        </section>
    </main>
  );
};

export default PricingSection;
