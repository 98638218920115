import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the <b>Linear Search</b> algorithm, a simple technique to search for an 
        element in a list. It sequentially checks each element of the list until the target is found or 
        the end of the list is reached. If the target element is found, its index is returned; 
        otherwise, -1 indicates it was not found. This approach is commonly used for small or 
        unsorted data.
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Finding a Contact in a Phonebook:</b> When searching for a contact in a small, unsorted list, a linear search can quickly locate the contact by checking each entry one by one.</li>
            <li><b>Checking Attendance:</b> In schools or events, a linear search can be used to verify if a specific individual's name appears on a manually sorted or unsorted attendance list.</li>
            <li><b>Inventory Lookup:</b> In a small retail shop, linear search can be used to find a specific item in an unsorted inventory list. For instance, checking if a product with a specific ID or name is available.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
