import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the use of a <b>union</b>, which is a special data type where all 
        members share the same memory location. The MagicalBox union can hold a number, a letter, or a string (candy), 
        but only one value at a time.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Data Conversion:</b> Optimizing memory usage in resource-constrained environments by storing mutually exclusive data.</li>
            <li><b>Network Protocols:</b> Representing packets where different fields depend on the type of message.</li>
            <li><b>Embedded Systems:</b> Optimizing memory usage in resource-constrained environments by storing mutually exclusive data.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
