import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../style.css';

const EmailVerification = () => {
  const [status, setStatus] = useState('Verifying...');
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tokenFromQuery = query.get('token');

    if (tokenFromQuery) {
        setToken(tokenFromQuery);
    } else {
        setStatus('Invalid verification link.');
        setIsLoading(false);
    }
   }, [location.search]);
   
   useEffect(() => {
    if (token) {
        verifyEmailToken(token, navigate);
    }
   }, [token]);

  const verifyEmailToken = async (token, navigate) => {
    // const navigate = useNavigate();
    
    try {
      const response = await axios.get(`${apiUrl}/api/verify-email/`, {
        params: { token }
      });
  
      setIsLoading(false);
  
      if (response.status === 200) {
        setStatus('Email verified successfully! Redirecting to the pricing page...');
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userToken', response.data.token); // Save the token
  
        // Dispatch custom event to update login status across the app
        window.dispatchEvent(new Event('loginStatusChange'));
  
        const query = new URLSearchParams(location.search);
        const subscriptionPlan = query.get('subscription');
        const redirectUrl = subscriptionPlan ? `/pricing?subscription=${subscriptionPlan}` : '/pricing';

        setTimeout(() => navigate(redirectUrl), 3000);
      } else {
        setStatus('Verification failed. Please try again.');
      }
    } catch (error) {
      setIsLoading(false);
      setStatus('An error occurred. Please try again later.');
    }
  };


  return (
    <div className="email-verification-container">
      <div className="text-center">
        <h1 className="mb-4">Email Verification</h1>
        {isLoading ? (
          <div>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <p className="mt-3">Verifying your email, please wait...</p>
          </div>
        ) : (
          <p className={`status-message ${status.includes('successfully') ? 'text-success' : 'text-danger'}`}>
            {status}
          </p>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
