import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        <b>Structures</b> in <b>C</b> and <b>C++</b> are user-defined data types that allow grouping 
        of related variables under one name. These variables can be of different types, 
        making structures ideal for representing complex entities.
        <div><b>Key Features:</b></div>
        <ul style={{paddingLeft: '25px'}}>
          <li>
            <b>Grouping of Variables:</b> Structures group multiple attributes (e.g., name and age).
          </li>
          <li>
            <b>Custom Data Types:</b> Allow defining new data types tailored to specific needs
          </li>
          <li>
            <b>Methods in C++:</b> Unlike C, C++ structures can include member functions.
          </li>
        </ul>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <div><b>C:</b></div>
        <ul style={{paddingLeft: '25px'}}>
          <li>Representing <b>records</b> like students, employees, or books in libraries.</li>
          <li>Storing <b>hardware data</b> in embedded systems, e.g., sensor readings or device configurations.</li>
        </ul>
        <div><b>C++:</b></div>
        <ul style={{paddingLeft: '25px'}}>
          <li>Enhanced use in <b>object-oriented programming</b>, where structs can have methods and resemble lightweight classes.</li>
          <li>Used for <b>modeling real-world entities</b>, such as 3D points in graphics applications or nodes in linked lists.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
