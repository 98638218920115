import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { countries } from './countries';
import axios from 'axios';

const Navbar = () => {
  // State for controlling modals and loading spinners
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [getOtpLoading, setGetOtpLoading] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [loginResponseMessage, setLoginResponseMessage] = useState("");
  const [registerResponseMessage, setRegisterResponseMessage] = useState("");
  const [country, setCountry] = useState('');
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [registerEmail, setRegisterEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    let retries = 5;

    const fetchUserInfo = async () => {
      const token = localStorage.getItem('userToken');
      if (!token && retries > 0) {
        retries--;
        setTimeout(fetchUserInfo, 1000); // Retry after 1 second
        return;
      }

      try {
        await axios.get(`${apiUrl}/api/user-info/`, {
          headers: { Authorization: `Token ${token}` },
        });
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('userToken');
          updateLoginStatus();
        }
      }
    };

    const updateLoginStatus = () => {
      const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
      setIsLoggedIn(loggedInStatus);
    };

    const handleShowLoginModal = () => {
      setShowLogin(true);
    };

    const handleShowRegisterModal = (event) => {
      setShowRegister(true);
      setSubscriptionPlan(event.detail?.plan || null);
    };

    // Listen for custom event to update login status
    window.addEventListener('loginStatusChange', updateLoginStatus);

    window.addEventListener('showLoginModal', handleShowLoginModal);

    window.addEventListener('showRegisterModal', handleShowRegisterModal);


    // Initial check
    updateLoginStatus();
    fetchUserInfo();

    // Cleanup
    return () => {
      window.removeEventListener('loginStatusChange', updateLoginStatus);
      window.removeEventListener('showLoginModal', handleShowLoginModal);
      window.removeEventListener('showRegisterModal', handleShowRegisterModal);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('userToken');
      if (!token) {
          console.error('No token found for logout');
          return;
      }

      await axios.post(`${apiUrl}/api/logout/`, {}, {
          headers: {
              'Authorization': `Token ${token}`
          }
      });

      // Remove token and update login status
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userToken');
      setIsLoggedIn(false);

      if (location.pathname === '/profile') {
        navigate('/');
      }
    } catch (error) {
      console.error('Error during logout', error);
    }
  };

  const handleOtpInput = (e, index) => {
    const { value } = e.target;
  
    // Allow only digits
    if (!/^\d*$/.test(value)) {
      return;
    }
  
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value.slice(-1); // Keep only the last character
      return newOtp;
    });
  
    // Automatically focus the next input
    if (value.length >= 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleOtpKeyDown = (e) => {
    const { key, target } = e;
  
    if (key === 'Backspace' && target.value === '') {
      const prevInput = target.previousElementSibling;
      if (prevInput) {
        prevInput.focus();
      }
    } else if (key === 'Enter') {
      e.preventDefault();
      document.getElementById('verifyOtpButton').click();
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setGetOtpLoading(true);
    setRegisterResponseMessage("");
    try {
      const response = await axios.post(`${apiUrl}/api/register/`, {
        email: registerEmail,
        country_code: country,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        subscription_plan: subscriptionPlan,
      });
      setRegisterResponseMessage(response.data.message);
    } catch (error) {
      let errorMessage = "Registration Failed: ";

      if (error.response?.data?.email && Array.isArray(error.response.data.email) && error.response.data.email[0]) {
        errorMessage += error.response.data.email[0];
      } else if (error.response?.data?.detail) {
        errorMessage += error.response.data.detail;
      } else if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else {
        errorMessage += error.message;
      }

      setRegisterResponseMessage(errorMessage);
    } finally {
      setGetOtpLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginResponseMessage("");
    setOtp(Array(4).fill(""));
  
    setGetOtpLoading(true);
  
    try {
      const response = await axios.post(`${apiUrl}/api/request-otp/`, {
        email: registerEmail,
      });
  
      if (response.status === 200) {
        setLoginResponseMessage(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        let errorMessage = "Error: ";
        if (error.response?.data?.error) {
          errorMessage += error.response.data.error;
        } else if (error.response?.data?.detail) {
          errorMessage += error.response.data.detail;
        } else {
          errorMessage += error.message;
        }

        setLoginResponseMessage(errorMessage);
      } else {
        setLoginResponseMessage("Error: Failed to connect to the server. Please try again later.");
      }
    } finally {
      setGetOtpLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    setVerifyOtpLoading(true);
  
    try {
      const response = await axios.post(`${apiUrl}/api/verify-otp/`, {
        email: registerEmail,
        otp: otp.join(''), // Assuming `otp` is an array of digits
      });
  
      setLoginResponseMessage("OTP verified successfully");
      // Handle the token (e.g., store in localStorage or set it in the state)
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('userToken', response.data.token);

      window.location.href = '/profile';
    } catch (error) {
      if (error.response) {
        let errorMessage = "Error: ";
        if (error.response?.data?.error) {
          errorMessage += error.response.data.error;
        } else if (error.response?.data?.detail) {
          errorMessage += error.response.data.detail;
        } else {
          errorMessage += error.message;
        }

        setLoginResponseMessage(errorMessage);
      } else {
        setLoginResponseMessage("Error: Failed to connect to the server. Please try again later.");
      }
    } finally {
      setVerifyOtpLoading(false);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validation checks
  const isNameValid = (name) => {
    const trimmedName = name.trim();
    const nameRegex = /^[a-zA-Z\s'-]+$/; // Allow letters, spaces, hyphens, and apostrophes
    return nameRegex.test(trimmedName);
};

  const isEmailValid = validateEmail(registerEmail);
  const isOtpComplete = otp.every((digit) => digit !== "");
  const isRegisterFormValid = 
    country && 
    isEmailValid && 
    isNameValid(firstName) && 
    isNameValid(lastName) && 
    agreeToTerms;

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid d-flex align-items-center justify-content-between px-lg-4">
          <a className="navbar-brand" href="/">
            <img src='/brand-logo.png' alt="Brand Logo" /> {/* bg color #212529 */}
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${location.pathname === '/pricing' ? 'active' : ''}`} href="/pricing">Pricing</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`} href="/contact">Contact</a>
              </li>
              {isLoggedIn ? (
                <li className="nav-item dropdown">
                  <a className={`nav-link dropdown-toggle ${location.pathname === '/profile' ? 'active' : ''}`} href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Profile
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-dark" aria-labelledby="profileDropdown">
                    <li><a className="dropdown-item" href="/profile">Profile</a></li>
                    <li><a className="dropdown-item" href="#" onClick={handleLogout}>Logout</a></li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => { e.preventDefault(); setShowLogin(true); }}
                  >
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {/* Login Modal */}
      <div className={`modal fade custom-modal ${showLogin ? 'show d-block' : ''}`} tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden={!showLogin}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-glass-avatar">
            <div className="modal-header">
              <h5 className="modal-title-avatar" id="loginModalLabel">Login To Continue</h5>
              <button 
                type="button"
                className="btn-close animated-modal-close-button"
                onClick={() => setShowLogin(false)}
                style={{filter: 'none'}}
              ></button>
            </div>
            <div className="modal-body">
              <form id="otpForm" onSubmit={handleLogin}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="mb-3">
                      <label htmlFor="registerEmail" className="form-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control login-custom-input"
                        id="registerEmail"
                        placeholder="ex - john@gmail.com"
                        required
                        value={registerEmail}
                        onChange={(e) => setRegisterEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-end">
                    <div className="mb-3">
                      <button type="submit" className="btn w-100 btn-glass btn-dark" disabled={!isEmailValid} style={{minWidth: '120px'}}>
                        {getOtpLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Get OTP"}
                      </button>
                    </div>
                  </div>
                </div>
                {loginResponseMessage && (
                  <div
                    className="text-center animated-response"
                    style={{ color: loginResponseMessage.startsWith("Error:") ? '#f52f2f' : '#1a73e8' }}
                  >
                    {loginResponseMessage.replace("Error:", "").trim()}
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="otp-0" className="form-label">Enter OTP</label>
                  <div className="otp-inputs">
                    {[0, 1, 2, 3].map((_, index) => (
                      <input
                        key={index}
                        id={`otp-${index}`}
                        type="text"
                        className="otp-input"
                        maxLength="1"
                        value={otp[index] || ''}
                        onChange={(e) => handleOtpInput(e, index)}
                        onKeyDown={(e) => handleOtpKeyDown(e)}
                        inputMode="numeric"
                      />
                    ))}
                  </div>
                </div>
                <button 
                  type="button"
                  id="verifyOtpButton"
                  className="btn w-100 btn-glass btn-dark"
                  disabled={!isOtpComplete}
                  onClick={handleOtpVerification}
                >
                  {verifyOtpLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Verify OTP"}
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <p className="small">New here?&nbsp; &nbsp;
                <a
                  href="#"
                  className='anchor-tag'
                  style={{color: 'black', fontWeight: 'bold'}}
                  onClick={(e) => { e.preventDefault(); setShowLogin(false); setShowRegister(true); }}
                >
                  Create an Account
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Registration Modal */}
      <div className={`modal fade custom-modal ${showRegister ? 'show d-block' : ''}`} tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden={!showRegister}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-glass-avatar">
            <div className="modal-header">
              <h5 className="modal-title-avatar" id="registerModalLabel">Join CodingCanvas</h5>
              <button 
                type="button"
                className="btn-close"
                onClick={() => setShowRegister(false)}
                style={{filter: 'none'}}
              ></button>
            </div>
            <div className="modal-body">
              <form id="registerForm" onSubmit={handleRegister}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="registerEmail" className="form-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control login-custom-input"
                        id="registerEmail"
                        placeholder="ex - john@gmail.com"
                        required
                        value={registerEmail}
                        onChange={(e) => setRegisterEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="registerCountry" className="form-label">Country</label>
                    <select
                      id="registerCountry"
                      className="form-control login-custom-input custom-dropdown-profile"
                      required
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select your country</option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-6 pading-10">
                    <label htmlFor="firstName" className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control login-custom-input"
                      id="firstName"
                      required
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 pading-10">
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control login-custom-input"
                        id="lastName"
                        required
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    className="form-check-input"
                    style={{
                      backgroundColor: agreeToTerms ? 'black' : 'white',
                      border: '1px solid black',
                      accentColor: 'black',
                      outline: 'none',
                      boxShadow: '0 0 1px 1px black',
                    }}
                    required
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                  />
                  <label htmlFor="termsCheckbox" className="form-check-label styled-label">
                    &nbsp; I agree to the <a href="/terms-and-conditions" target="_blank" style={{color: 'black'}}>Terms and Conditions</a>
                  </label>
                </div>
                <button type="submit" className="btn w-100 btn-glass btn-dark" disabled={!isRegisterFormValid}>
                  {getOtpLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Sign Up"}
                </button>
              </form>
                {registerResponseMessage && (
                  <div
                    className="text-center animated-response"
                    style={{ color: registerResponseMessage.startsWith("Registration Failed") ? '#f52f2f' : '#1a73e8' }}
                  >
                    {registerResponseMessage}
                  </div>
                )}
            </div>
            <div className="modal-footer">
              <p className="small">Already a member?&nbsp;&nbsp;
                <a
                  href="#"
                  className='anchor-tag'
                  style={{color: 'black', fontWeight: 'bold'}}
                  onClick={(e) => { e.preventDefault(); setShowRegister(false); setShowLogin(true); }}
                  
                >
                  Log in here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
