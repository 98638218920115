const generateMemoryAddress = () => {
  return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
};

class Node {
  constructor(data, nodeAddress) {
    this.data = data;
    this.next = null;
    this.address = nodeAddress;
    this.parent = null;
  }
}

class LinkedListForJava {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'null'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
        
        while (temp.next !== null) {
            temp = temp.next;

            setHeadVariables((vars) => ({ ...vars, 
              temp: { variable_name: 'temp', value: temp.address, child: temp.address }
            }));
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to display the linked list
  display = async(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(13);
    await customSleep(13);

    await highlightLine(14);
    setHighlightedHeadVariables(['head']);
    setHighlightNodeAddress([`${this.head.address}`]);
    await customSleep(null, 1000);
    let temp = this.head;
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['head', 'temp']);
    await customSleep(14);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    await highlightLine(15);
    setHighlightedHeadVariables(['temp']);
    await customSleep(15);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(16);
      await logMessage("The list is empty.");
      await customSleep(16);
    } else {
      await highlightLine(17);
      await customSleep(17);

      while (temp !== null) {
        await highlightLine(18);
        setHighlightedHeadVariables(['temp']);
        await customSleep(18);

        await highlightLine(19);
        setHighlightDataPart([`${temp.address}`]);
        await logMessage(`${temp.data}`);
        await customSleep(19);
        setHighlightDataPart([]);

        await highlightLine(20);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        temp = temp.next;

        if (temp !== null) {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: temp.address, child: temp.address }
          }));
          setHighlightNodeAddress([`${temp.address}`]);
        } else {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: 'null'}
          }));
        }
        setHighlightedHeadVariables(['temp']);
        await customSleep(20);
        setHighlightedHeadVariables([]);
        setHighlightNextAddressPart([]);
        setHighlightNodeAddress([]);

        if (temp === null) {
          await highlightLine(18);
          setHighlightedHeadVariables(['temp']);
          await customSleep(18);
        }
      }
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
    }
  }
}


export const linkedListJava = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForJava();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListTraversalJava = async (
  globalList,
  highlightCallingLine,
  highlightLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(29);
  await customSleep(29);

  await highlightCallingLine(29);
  await globalList.display(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(29);
  await customSleep(29);
  
};


class LinkedListForPython {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'None'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
        let temp = this.head;

        while (temp.next !== null) {
            temp = temp.next;
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to display the linked list
  display = async(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(9);
    await customSleep(9);

    await highlightLine(10);
    setHighlightedHeadVariables(['head']);
    setHighlightNodeAddress([`${this.head.address}`]);
    await customSleep(null, 1000);
    let temp = this.head;
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['head', 'temp']);
    await customSleep(10);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    await highlightLine(11);
    setHighlightedHeadVariables(['temp']);
    await customSleep(11);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(12);
      await logMessage("The list is empty.");
      await customSleep(12);
    } else {
      await highlightLine(13);
      await customSleep(13);

      while (temp !== null) {
        await highlightLine(14);
        setHighlightedHeadVariables(['temp']);
        await customSleep(14);

        await highlightLine(15);
        setHighlightDataPart([`${temp.address}`]);
        await logMessage(`${temp.data}`);
        await customSleep(15);
        setHighlightDataPart([]);

        await highlightLine(16);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        temp = temp.next;

        if (temp !== null) {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: temp.address, child: temp.address }
          }));
          setHighlightNodeAddress([`${temp.address}`]);
        } else {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: 'None'}
          }));
        }
        setHighlightedHeadVariables(['temp']);
        await customSleep(16);
        setHighlightedHeadVariables([]);
        setHighlightNextAddressPart([]);
        setHighlightNodeAddress([]);

        if (temp === null) {
          await highlightLine(14);
          setHighlightedHeadVariables(['temp']);
          await customSleep(14);
        }
      }
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
    }
  }
}

export const linkedListPython = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForPython();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};


export const linkedListTraversalPython = async (
  globalList,
  highlightCallingLine,
  highlightLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(22);
  await customSleep(22);

  await highlightCallingLine(22);
  await globalList.display(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(22);
  await customSleep(22);
  
};

class LinkedListForCpp {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'nullptr'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
      this.head = newNode;

      setHeadVariables((vars) => ({ ...vars, 
        head: { variable_name: 'head', value: nodeAddress }
      }));

      newNode.parent = 'head';
      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: 'nullptr'},
          address: newNode.address,
          parent: newNode.parent
        }
      }));
    } else {
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));

        while (temp.next !== null) {
          temp = temp.next;

          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: temp.address, child: temp.address }
          }));
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to display the linked list
  display = async(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(22);
    await customSleep(22);

    await highlightLine(23);
    setHighlightedHeadVariables(['head']);
    setHighlightNodeAddress([`${this.head.address}`]);
    await customSleep(null, 1000);
    let temp = this.head;
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['head', 'temp']);
    await customSleep(23);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    await highlightLine(24);
    setHighlightedHeadVariables(['temp']);
    await customSleep(24);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(25);
      await logMessage("The list is empty.");
      await customSleep(25);
    } else {
      await highlightLine(26);
      await customSleep(26);

      while (temp !== null) {
        await highlightLine(27);
        setHighlightedHeadVariables(['temp']);
        await customSleep(27);

        await highlightLine(28);
        setHighlightDataPart([`${temp.address}`]);
        await logMessage(`${temp.data}`);
        await customSleep(28);
        setHighlightDataPart([]);

        await highlightLine(29);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        temp = temp.next;

        if (temp !== null) {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: temp.address, child: temp.address }
          }));
          setHighlightNodeAddress([`${temp.address}`]);
        } else {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: 'nullptr'}
          }));
        }
        setHighlightedHeadVariables(['temp']);
        await customSleep(29);
        setHighlightedHeadVariables([]);
        setHighlightNextAddressPart([]);
        setHighlightNodeAddress([]);

        if (temp === null) {
          await highlightLine(27);
          setHighlightedHeadVariables(['temp']);
          await customSleep(27);
        }
      }
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
    }
  }
}

export const linkedListCpp = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForCpp();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );
  return list;
};

export const linkedListTraversalCpp = async (
  globalList,
  highlightCallingLine,
  highlightLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(39);
  await customSleep(39);

  await highlightCallingLine(39);
  await globalList.display(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(39);
  await customSleep(39);

  await highlightLine(41);
  await customSleep(41);
};


class LinkedListForC {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'NULL'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
      let temp = this.head;
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));

      while (temp.next !== null) {
        temp = temp.next;
        
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
      }

      temp.next = newNode;
      newNode.parent = temp.address;

      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: 'NULL'},
          address: newNode.address,
          parent: newNode.parent
        }
      }));
      
      setNodeVariables((vars) => ({ ...vars, 
        [temp.address]: { 
          variable_name: temp.address,
          value: {data: temp.data, next: newNode.address},
          address: temp.address,
          parent: temp.parent
        }
      }));
      
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
    }
  }

  // Method to display the linked list
  display = async(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(10);
    await customSleep(10);

    await highlightLine(11);
    setHighlightedHeadVariables(['head']);
    setHighlightNodeAddress([`${this.head.address}`]);
    await customSleep(null, 1000);
    let temp = this.head;
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['head', 'temp']);
    await customSleep(11);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    await highlightLine(12);
    setHighlightedHeadVariables(['temp']);
    await customSleep(12);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(13);
      await logMessage("The list is empty.");
      await customSleep(13);
    } else {
      await highlightLine(14);
      await customSleep(14);

      while (temp !== null) {
        await highlightLine(15);
        setHighlightedHeadVariables(['temp']);
        await customSleep(15);

        await highlightLine(16);
        setHighlightDataPart([`${temp.address}`]);
        await logMessage(`${temp.data}`);
        await customSleep(16);
        setHighlightDataPart([]);

        await highlightLine(17);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        temp = temp.next;

        if (temp !== null) {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: temp.address, child: temp.address }
          }));
          setHighlightNodeAddress([`${temp.address}`]);
        } else {
          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: 'NULL'}
          }));
        }
        setHighlightedHeadVariables(['temp']);
        await customSleep(17);
        setHighlightedHeadVariables([]);
        setHighlightNextAddressPart([]);
        setHighlightNodeAddress([]);

        if (temp === null) {
          await highlightLine(15);
          setHighlightedHeadVariables(['temp']);
          await customSleep(15);
        }
      }
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
    }
  }
}

export const linkedListC = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForC();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListTraversalC = async (
  globalList,
  highlightCallingLine,
  highlightLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(24);
  await customSleep(24);

  await highlightCallingLine(24);
  await globalList.display(
    highlightLine,
    customSleep,
    logMessage,
    setHeadVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(24);
  await customSleep(24);

  await highlightLine(26);
  await customSleep(26);
};
