import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        A <b>dictionary (or map)</b> is a data structure that stores key-value pairs, allowing efficient retrieval, insertion, and deletion of values using unique keys. 
        Commonly used in applications like caching, indexing, and lookups, dictionaries enable fast access to data based on associated keys.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Data Storage:</b> Store and manage structured data (e.g., user profiles, configurations).</li>
            <li><b>Dynamic Updates:</b> Add, update, or remove information during runtime, like maintaining a student record or inventory.</li>
            <li><b>Data Manipulation:</b> Quickly retrieve and manipulate data using keys in applications like databases or APIs.</li>
            <li><b>Educational Tools:</b> Teach data structures and operations in programming.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
