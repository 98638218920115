import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useSetTypes = (language, fruits, initialFruits, addFruit, removeFruit, _berryFruits, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [highlightedMultipleLines, setHighlightedMultipleLines] = useState([]);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [setsVariables, setSetsVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedSetVariables, setHighlightedSetVariables] = useState([]);
  const [highlightedSetIndex, setHighlightedSetIndex] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newFruits, newAddFruit, newRemoveFruit, newBerryFruits) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /fruits\s*=\s*{\s*.*?\s*}/,
            `fruits = {${newFruits.map(item => `'${item}'`).join(', ')}}`
          )
          .replace(
            /berry_fruits\s*=\s*{\s*.*?\s*}/,
            `berry_fruits = {${newBerryFruits.map(item => `'${item}'`).join(', ')}}`
          )
          .replace(
            /fruits\.add\(\s*['"][^'"]*['"]\s*\)/,
            `fruits.add('${newAddFruit}')`
          )
          .replace(
            /fruits\.remove\(\s*['"][^'"]*['"]\s*\)/,
            `fruits.remove('${newRemoveFruit}')`
          )
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /fruits\s*=\s*new\s*HashSet<>\(\s*Arrays\.asList\(\s*.*?\s*\)\s*\)/,
            `fruits = new HashSet<>(Arrays.asList(${newFruits.map(item => `"${item}"`).join(', ')}))`
          )
          .replace(
            /berryFruits\s*=\s*new\s*HashSet<>\(\s*Arrays\.asList\(\s*.*?\s*\)\s*\)/,
            `berryFruits = new HashSet<>(Arrays.asList(${newBerryFruits.map(item => `"${item}"`).join(', ')}))`
          )
          .replace(
            /fruits\.add\(\s*['"][^'"]*['"]\s*\)/,
            `fruits.add('${newAddFruit}')`
          )
          .replace(
            /fruits\.remove\(\s*['"][^'"]*['"]\s*\)/,
            `fruits.remove('${newRemoveFruit}')`
          )
      );
    }
    
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setHighlightedMultipleLines([]);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setSetsVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedSetVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null, time=null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }

    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runSetTypesPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setSetsVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: initialFruits } }));
    setHighlightedSetVariables(['fruits']);
    await customSleep(2);
    setHighlightedSetVariables([]);

    await highlightLine(3);
    await logMessage(`${[...initialFruits].join(', ')}`);
    await customSleep(3);

    await highlightLine(5);
    initialFruits.add(addFruit);
    setSetsVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: initialFruits } }));
    setHighlightedSetIndex([{ setName: 'fruits', key: addFruit }]);
    await customSleep(5);
    setHighlightedSetIndex([]);

    await highlightLine(6);
    await logMessage(`${[...initialFruits].join(', ')}`);
    await customSleep(6);

    await highlightLine(8);
    setHighlightedSetIndex([{ setName: 'fruits', key: removeFruit }]);
    await customSleep(null, 2000);
    setHighlightedSetIndex([]);
    initialFruits.delete(removeFruit);
    setSetsVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: initialFruits } }));
    await customSleep(8);

    await highlightLine(9);
    await logMessage(`${[...initialFruits].join(', ')}`);
    await customSleep(9);

    await highlightLine(11);
    const hasApple = initialFruits.has("Orange");
    setHighlightedSetIndex([{ setName: 'fruits', key: 'Orange' }]);
    await logMessage(`${hasApple}`);
    await customSleep(11);
    setHighlightedSetIndex([]);

    await highlightLine(13);
    const berryFruits = new Set(_berryFruits);
    setSetsVariables((vars) => ({ ...vars, berry_fruits: { variable_name: 'berry_fruits', value: berryFruits } }));
    setHighlightedSetVariables(['berry_fruits']);
    await customSleep(13);
    setHighlightedSetVariables([]);

    await highlightLine(16);
    const unionSet = new Set([...initialFruits, ...berryFruits]);
    setSetsVariables((vars) => ({ ...vars, union_set: { variable_name: 'union_set', value: unionSet } }));
    setHighlightedSetVariables(['union_set']);
    await customSleep(16);
    setHighlightedSetVariables([]);

    await highlightLine(17);
    await logMessage(`${[...unionSet].join(', ')}`);
    await customSleep(17);

    await highlightLine(20);
    const intersectionSet = new Set([...initialFruits].filter(x => berryFruits.has(x)));
    setSetsVariables((vars) => ({ ...vars, intersection_set: { variable_name: 'intersection_set', value: intersectionSet } }));
    setHighlightedSetVariables(['intersection_set']);
    await customSleep(20);
    setHighlightedSetVariables([]);

    await highlightLine(21);
    await logMessage(`${[...intersectionSet].join(', ')}`);
    await customSleep(21);

    await highlightLine(24);
    const differenceSet = new Set([...initialFruits].filter(x => !berryFruits.has(x)));
    setSetsVariables((vars) => ({ ...vars, difference_set: { variable_name: 'difference_set', value: differenceSet } }));
    setHighlightedSetVariables(['difference_set']);
    await customSleep(24);
    setHighlightedSetVariables([]);

    await highlightLine(25);
    await logMessage(`${[...differenceSet].join(', ')}`);
    await customSleep(25);

    await focusEndLine(25);
    setIsRunning(false);
  };

  const runSetTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    // let _initialFruits = new Set([]);

    await highlightLine(5);
    setSetsVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: initialFruits } }));
    setHighlightedSetVariables(['fruits']);
    await customSleep(5);
    setHighlightedSetVariables([]);

    await highlightLine(6);
    await logMessage(`${[...initialFruits].join(', ')}`);
    await customSleep(6);

    await highlightLine(9);
    initialFruits.add(addFruit);
    setHighlightedSetIndex([{ setName: 'fruits', key: addFruit }]);
    await customSleep(9);
    setHighlightedSetIndex([]);

    await highlightLine(10);
    await logMessage(`${[...initialFruits].join(', ')}`);
    await customSleep(10);

    await highlightLine(13);
    setHighlightedSetIndex([{ setName: 'fruits', key: removeFruit }]);
    await customSleep(null, 2000);
    setHighlightedSetIndex([]);
    initialFruits.delete(removeFruit);
    await customSleep(13);

    await highlightLine(14);
    await logMessage(`${[...initialFruits].join(', ')}`);
    await customSleep(14);

    await highlightLine(17);
    const hasFruit = initialFruits.has("Orange");
    setHighlightedSetIndex([{ setName: 'fruits', key: 'Orange' }]);
    await logMessage(`${hasFruit}`);
    await customSleep(17);
    setHighlightedSetIndex([]);

    await highlightLine(20);
    const berryFruits = new Set(_berryFruits);
    setSetsVariables((vars) => ({ ...vars, berry_fruits: { variable_name: 'berry_fruits', value: berryFruits } }));
    setHighlightedSetVariables(['berry_fruits']);
    await customSleep(20);
    setHighlightedSetVariables([]);

    await highlightLine(23);
    let unionSet = new Set([...initialFruits]);
    setSetsVariables((vars) => ({ ...vars, union_set: { variable_name: 'union_set', value: unionSet } }));
    setHighlightedSetVariables(['union_set']);
    await customSleep(23);
    setHighlightedSetVariables([]);

    await highlightLine(24);
    unionSet = new Set([...initialFruits, ...berryFruits]);
    setSetsVariables((vars) => ({ ...vars, union_set: { variable_name: 'union_set', value: unionSet } }));
    setHighlightedSetVariables(['union_set']);
    await customSleep(24);
    setHighlightedSetVariables([]);

    await highlightLine(25);
    await logMessage(`${[...unionSet].join(', ')}`);
    await customSleep(25);

    await highlightLine(28);
    let intersectionSet = new Set([...initialFruits]);
    setSetsVariables((vars) => ({ ...vars, intersection_set: { variable_name: 'intersection_set', value: intersectionSet } }));
    setHighlightedSetVariables(['intersection_set']);
    await customSleep(28);
    setHighlightedSetVariables([]);

    await highlightLine(29);
    intersectionSet = new Set([...initialFruits].filter(x => berryFruits.has(x)));
    setSetsVariables((vars) => ({ ...vars, intersection_set: { variable_name: 'intersection_set', value: intersectionSet } }));
    setHighlightedSetVariables(['intersection_set']);
    await customSleep(29);
    setHighlightedSetVariables([]);

    await highlightLine(30);
    await logMessage(`${[...intersectionSet].join(', ')}`);
    await customSleep(30);

    await highlightLine(33);
    let differenceSet = new Set([...initialFruits]);
    setSetsVariables((vars) => ({ ...vars, difference_set: { variable_name: 'difference_set', value: differenceSet } }));
    setHighlightedSetVariables(['difference_set']);
    await customSleep(33);
    setHighlightedSetVariables([]);

    await highlightLine(34);
    differenceSet = new Set([...initialFruits].filter(x => !berryFruits.has(x)));
    setSetsVariables((vars) => ({ ...vars, difference_set: { variable_name: 'difference_set', value: differenceSet } }));
    setHighlightedSetVariables(['difference_set']);
    await customSleep(34);
    setHighlightedSetVariables([]);

    await highlightLine(35);
    await logMessage(`${[...differenceSet].join(', ')}`);
    await customSleep(35);

    await focusEndLine(37);
    setIsRunning(false);
  };

  const runSetTypes = async () => {
    if (language === 'Python') {
      await runSetTypesPython();
    } else if (language === 'Java') {
      await runSetTypesJava();
    }
  };

  return {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    setsVariables,
    highlightedVariables,
    highlightedSetVariables,
    highlightedSetIndex,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setHighlightedMultipleLines,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setSetsVariables,
    setHighlightedVariables,
    setHighlightedSetVariables,
    setHighlightedSetIndex,
    runSetTypes
  };
};
