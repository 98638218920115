import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program iterates through a list of numbers and applies the classic <b>FizzBuzz</b> logic. 
        It prints <b>FizzBuzz</b> for numbers divisible by both 3 and 5, <b>Fizz</b> for those divisible by 3, <b>Buzz</b> for those divisible by 5, and the number itself otherwise.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Game Design:</b> Basis for rules or logic in simple educational games.</li>
            <li><b>Programming Exercises:</b> A popular task for learning conditional logic and iteration in coding.</li>
            <li><b>Data Classification:</b> Classify data into categories based on divisibility or other conditions.</li>
            <li><b>Interview Questions:</b> Frequently used to test basic programming skills.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
