import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center p-3">
      <p>&copy; 2024 CodingCanvas.io | All Rights Reserved</p>
      <div style={{fontSize: '12px'}}>
        <Link to="/terms-and-conditions" className="text-white mx-2">
          Terms and Conditions
        </Link>
        |
        <Link to="/privacy-policy" className="text-white mx-2">
          Privacy Policy
        </Link>
        |
        <Link to="/refund-and-cancellation" className="text-white mx-2">
          Refund and Cancellation
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
