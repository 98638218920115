import React from 'react';

const UserProgressModal = ({
  showModal,
  loading,
  modalMessage,
  modalMessageType,
  onClose,
}) => {
  if (!showModal) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header"></div>
          <div className="modal-body">
            {loading ? (
              <div className="text-center my-2">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <div
                className={`user-progress-animation-container ${
                  modalMessageType === 'Fail'
                    ? 'user-progress-failure'
                    : 'user-progress-success'
                }`}
              >
                <div className="user-progress-circle">
                  {modalMessageType === 'Fail' ? (
                    <i className="fas fa-times-circle user-progress-cross-mark"></i>
                  ) : (
                    <i className="fas fa-check-circle user-progress-tick-mark"></i>
                  )}
                </div>
                <p
                  className={`${
                    modalMessageType === 'Fail'
                      ? 'user-progress-fail-message'
                      : 'user-progress-success-message'
                  }`}
                >
                  {modalMessage}
                </p>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn user-progress-close-btn"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProgressModal;
