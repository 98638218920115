import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useTypeDef } from './useTypeDef';

const TypeDef = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [cakesBakedToday, setCakesBakedToday] = useState(25);
  const [cakesBakedYesterday, setCakesBakedYesterday] = useState(30);

  const [inputCakesBakedToday, setInputCakesBakedToday] = useState(cakesBakedToday);
  const [inputCakesBakedYesterday, setInputCakesBakedYesterday] = useState(cakesBakedYesterday);

  const [breakpoints, setBreakpoints] = useState([]);

  const {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    highlightedVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runTypeDef
  } = useTypeDef(language, cakesBakedToday, cakesBakedYesterday, breakpoints);

  const handleUpdateVariables = () => {
    setCakesBakedToday(inputCakesBakedToday);
    setCakesBakedYesterday(inputCakesBakedYesterday);
    updateVariables(inputCakesBakedToday, inputCakesBakedYesterday);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            callingLine={callingLine}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={`Typedef Example`}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputCakesBakedToday(cakesBakedToday);
              setInputCakesBakedYesterday(cakesBakedYesterday);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runTypeDef}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              stringVariables={null}
              highlightedStringVariables={[]}
              highlightedStringIndices={[]}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        cakesBakedToday={inputCakesBakedToday}
        cakesBakedYesterday={inputCakesBakedYesterday}
        setCakesBakedToday={setInputCakesBakedToday}
        setCakesBakedYesterday={setInputCakesBakedYesterday}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default TypeDef;
