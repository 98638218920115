import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the <b>Depth-First Search (DFS)</b> algorithm for graph traversal 
        using an iterative approach. It utilizes a stack to explore as far as possible along 
        each branch before backtracking. However, the graph lacks defined connections, 
        which will cause an error when the program attempts to access neighbors for the given start node.
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>V + E</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Pathfinding:</b> Explore paths or states in games or simulations.</li>
            <li><b>Web Crawlers:</b> Crawl web pages deeply (e.g., links within links).</li>
            <li><b>Dependency Resolution:</b> Analyze dependencies in graphs, such as build systems or package managers.</li>
            <li><b>Maze Solving:</b> Implement backtracking solutions for mazes or puzzles.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
