import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program implements a <b>Stack data structure</b> using a fixed-size array. 
        It supports standard stack operations:
        <ul style={{paddingLeft: '15px'}}>
            <li><b>Push:</b> Adds an element to the top of the stack, displaying the updated stack. If the stack is full, an error message is shown.</li>
            <li><b>Pop:</b> Removes the top element from the stack, displaying the updated stack. If the stack is empty, an error message is shown.</li>
            <li><b>Display:</b> Shows the current elements in the stack from top to bottom.</li>
        </ul>
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <div style={{display: 'flex'}}>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Push:</b> &nbsp;&nbsp;
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>1</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Pop:</b> &nbsp;&nbsp;
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>1</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Display:</b> &nbsp;&nbsp;
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>n</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
          </div>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Undo Functionality:</b> In text editors, a stack stores the sequence of changes, allowing users to undo operations.</li>
            <li><b>Call Stack in Programming:</b> Used to manage function calls and return addresses in programming languages.</li>
            <li><b>Expression Evaluation:</b> Used to evaluate expressions in calculators or parsers by storing operators and operands temporarily.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
