import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the use of the <b>Integer</b> data type to represent integer values. 
        It declares variables for significant historical and scientific facts, such as the year of the 
        first moon landing, Olympic gold medals won, the height of Mount Everest, and the lowest 
        recorded temperature, and prints these values to the console.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Data Representation:</b> Stores and displays facts or metrics, useful for reports or logs..</li>
            <li><b>Historical Data:</b> Manage and display important numerical data in applications or educational content.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
