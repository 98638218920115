import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the use of a <b>switch-case</b> statement to determine the day of the 
        week based on the value of the variable day. Each case corresponds to a day, and the default 
        case handles invalid inputs.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Menu Selection:</b> Handling user input in applications like restaurant menus or option-based systems.</li>
            <li><b>Game Logic:</b> Executing actions based on game states or events.</li>
            <li><b>Device Settings:</b> Adjusting settings based on selected modes, such as brightness levels or sound profiles.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
