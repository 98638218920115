import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useSequenceTypes = (language, name, fruits, coordinates, numbers, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newName, newFruits, newCoordinates) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/name\s*=\s*(['"])[^'"]*\1/g, `name = '${newName}'`)
        .replace(/fruits = \[.*?\]/, `fruits = [${newFruits.map(item => `'${item}'`).join(', ')}]`)
        .replace(
          /coordinates\s*=\s*\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)/g,
          `coordinates = (${newCoordinates.join(", ")})`
        )
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations['Python'][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runSequenceTypesPython = async () => {
    fruits = fruits.slice(0, 3);
    // String example
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, name: { variable_name: 'name', value: name } }));
    setHighlightedVariables(['name']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(3);
    setHighlightedVariables(['name']);
    await logMessage(`String: ${name}`);
    await customSleep(3);
    setHighlightedVariables([]);

    // List example
    await highlightLine(6);
    setArrayVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: fruits, width: '80px' } }));
    setHighlightedArrayVariables(['fruits']);
    await customSleep(6);
    setHighlightedArrayVariables([]);

    await highlightLine(7);
    setHighlightedArrayVariables(['fruits']);
    fruits.push('date');
    setArrayVariables((vars) => ({ ...vars, fruits: { variable_name: 'fruits', value: fruits, width: '80px' } }));
    await customSleep(7);
    setHighlightedArrayVariables([]);

    await highlightLine(8);
    setHighlightedArrayVariables(['fruits']);
    await logMessage(`List: ${fruits}`);
    await customSleep(8);
    setHighlightedArrayVariables([]);

    await highlightLine(9);
    setHighlightedIndices([{ arrayName: 'fruits', index: 0 }]);
    await logMessage(`First fruit: ${fruits[0]}`);
    await customSleep(9);
    setHighlightedIndices([]);

    // Tuple example
    await highlightLine(12);
    setArrayVariables((vars) => ({ ...vars, coordinates: { variable_name: 'coordinates', value: coordinates } }));
    setHighlightedArrayVariables(['coordinates']);
    await customSleep(12);
    setHighlightedArrayVariables([]);

    await highlightLine(13);
    setHighlightedArrayVariables(['coordinates']);
    await logMessage(`Tuple: ${coordinates}`);
    await customSleep(13);
    setHighlightedArrayVariables([]);

    await highlightLine(14);
    setHighlightedIndices([{ arrayName: 'coordinates', index: 1 }]);
    await logMessage(`Second coordinate: ${coordinates[1]}`);
    await customSleep(14);
    setHighlightedIndices([]);

    // Range example
    await highlightLine(17);
    setArrayVariables((vars) => ({ ...vars, numbers: { variable_name: 'numbers', value: numbers } }));
    setHighlightedArrayVariables(['numbers']);
    await customSleep(17);
    setHighlightedArrayVariables([]);

    await highlightLine(18);
    setHighlightedArrayVariables(['numbers']);
    await logMessage(`Range as list: ${numbers}`);
    await customSleep(18);
    setHighlightedArrayVariables([]);

    await focusEndLine(19);
    setIsRunning(false);
  };

  const runSequenceTypes = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await runSequenceTypesPython();

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runSequenceTypes
  };
};
