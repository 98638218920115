import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        The binary search algorithm is a highly efficient method for finding the position of a target element in a sorted list. It works by repeatedly dividing the search interval in half:

        <ul style={{paddingLeft: '25px'}}>
            <li>
                <b>Initialization:</b> Start with the entire list (low = 0, high = length - 1).
            </li>
            <li>
                <b>Iteration:</b> Compute the middle index and compare its element with the target.
                <ul style={{paddingLeft: '10px'}}>
                    <li>
                        If the middle element matches the target, return its index.
                    </li>
                    <li>
                        If the middle element is smaller than the target, narrow the search to the right half.
                    </li>
                    <li>
                        If the middle element is larger, narrow the search to the left half.
                    </li>
                </ul>
            </li>
            <li>
                <b>Termination:</b> If the target is not found after all iterations, return -1.
            </li>
        </ul>
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>log n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Search Systems:</b> Used in databases, search engines, and library catalog systems to quickly locate items.</li>
            <li><b>Gaming:</b> Efficiently find leaderboard rankings or positions in sorted game data.</li>
            <li><b>Finance:</b> Search for specific transactions or stock prices in sorted datasets.</li>
            <li><b>Programming Libraries:</b> Often implemented in sorting and searching utility functions in software libraries.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
