import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useLongTypes } from './useLongTypes';

const LongTypes = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [minSignedLongValue, setMinSignedLongValue] = useState(-9223372036854775808n);
  const [maxSignedLongValue, setMaxSignedLongValue] = useState(9223372036854775807n);
  const [maxUnsignedLongValue, setMaxUnsignedLongValue] = useState(18446744073709551615n);

  const [inputMinSignedLongValue, setInputMinSignedLongValue] = useState(minSignedLongValue);
  const [inputMaxSignedLongValue, setInputMaxSignedLongValue] = useState(maxSignedLongValue);
  const [inputMaxUnsignedLongValue, setInputMaxUnsignedLongValue] = useState(maxUnsignedLongValue);

  const [breakpoints, setBreakpoints] = useState([]);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runLongTypes
  } = useLongTypes(
    language,
    minSignedLongValue,
    maxSignedLongValue,
    maxUnsignedLongValue,
    breakpoints
  );

  const handleUpdateVariables = () => {
    setMinSignedLongValue(inputMinSignedLongValue);
    setMaxSignedLongValue(inputMaxSignedLongValue);
    setMaxUnsignedLongValue(inputMaxUnsignedLongValue);

    updateVariables();
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Long Types Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputMinSignedLongValue(minSignedLongValue);
              setInputMaxSignedLongValue(maxSignedLongValue);
              setInputMaxUnsignedLongValue(maxUnsignedLongValue);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runLongTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        minSignedLongValue={inputMinSignedLongValue}
        setMinSignedLongValue={setInputMinSignedLongValue}
        maxSignedLongValue={inputMaxSignedLongValue}
        setMaxSignedLongValue={setInputMaxSignedLongValue}
        maxUnsignedLongValue={inputMaxUnsignedLongValue}
        setMaxUnsignedLongValue={setInputMaxUnsignedLongValue}
        language={language}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default LongTypes;
