import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useNumericTypes } from './useNumericTypes';

const NumericTypes = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [x, setX] = useState(10);
  const [y, setY] = useState(3.14);
  const [z, setZ] = useState('2 + 3j');

  const [inputX, setInputX] = useState(x);
  const [inputY, setInputY] = useState(y);
  const [inputZ, setInputZ] = useState(z);

  const [inputError, setInputError] = useState("");

  const [breakpoints, setBreakpoints] = useState([]);

  const {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runNumericTypes
  } = useNumericTypes(language, x, y, z, breakpoints);

  const handleUpdateVariables = () => {
    const complexNumberRegex = /^\s*[-+]?\d+(\.\d+)?\s*([-+]\s*\d+(\.\d+)?j)?\s*$/i;
    let complexValue = inputZ;
    if (complexValue === '' || complexNumberRegex.test(complexValue)) {
      setZ(complexValue);
      setInputError("");
    } else {
      complexValue = z;
      setInputError("Not a valid complex value.");
      return;
    }

    setX(inputX);
    setY(inputY);

    updateVariables(inputX, inputY, complexValue);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Numeric Types in Python"}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputX(x);
              setInputY(y);
              setInputZ(z);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runNumericTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        x={inputX}
        y={inputY}
        z={inputZ}
        setX={setInputX}
        setY={setInputY}
        setZ={setInputZ}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
        inputError={inputError}
        setInputError={setInputError}
      />
    </div>
  );
};

export default NumericTypes;
