// Python Queue Algorithm
export const queuePython = async (
    highlightLine,
    setVariables,
    setArrayVariables,
    setStackVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedStackVariables,
    setHighlightedIndices,
    setHighlightedStackIndex,
    highlightCallingLine,
    customSleep,
    logMessage
  ) => {
    let size;
    let front = -1;
    let rear = -1;
    let queue = [];
  
    const init = async (_size) => {
        await highlightLine(1);
        setVariables((vars) => ({ ...vars, _size: { variable_name: '_size', value: _size } }));
        setHighlightedVariables(['_size']);
        await customSleep(1);
        setHighlightedVariables([]);

        await highlightLine(2);
        size = _size;
        setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
        setHighlightedVariables(['size']);
        await customSleep(2);
        setHighlightedVariables([]);

        await highlightLine(3);
        queue = Array(size).fill(null);
        setStackVariables({ queue: { variable_name: 'queue', value: queue } });
        setHighlightedStackVariables(['queue']);
        await customSleep(3);
        setHighlightedStackVariables([]);

        await highlightLine(4);
        front = rear = -1;
        setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front }, rear: { variable_name: 'rear', value: rear } }));
        setHighlightedVariables(['front', 'rear']);
        await customSleep(4);
        setHighlightedVariables([]);
    };
  
    const enqueue = async (data) => {
        await highlightLine(6);
        setVariables((vars) => ({ ...vars, data: { variable_name: 'data', value: data } }));
        setHighlightedVariables(['data']);
        await customSleep(6);
        setHighlightedVariables([]);

        await highlightLine(7);
        setHighlightedVariables(['rear', 'size']);
        await customSleep(7);
        setHighlightedVariables([]);
        if (rear === (size - 1)) {
            await highlightLine(8);
            await logMessage("Queue is Full");
            await customSleep(8);
        } else {
            await highlightLine(9);
            await customSleep(9);

            await highlightLine(10);
            setHighlightedVariables(['front']);
            await customSleep(10);
            setHighlightedVariables([]);
            if (front === -1) {
                await highlightLine(11);
                setHighlightedVariables(['front']);
                front = 0;
                setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
                await customSleep(11);
                setHighlightedVariables([]);
            }
            await highlightLine(12);
            setHighlightedVariables(['rear']);
            rear += 1;
            setVariables((vars) => ({ ...vars, rear: { variable_name: 'rear', value: rear } }));
            await customSleep(12);
            setHighlightedVariables([]);

            await highlightLine(13);
            setHighlightedVariables(['rear', 'data']);
            setHighlightedStackIndex([{ stackName: 'queue', index: rear, iteratorName: 'rear' }]);
            queue[rear] = data;
            await customSleep(13);
            setHighlightedVariables([]);
            setHighlightedStackIndex([]);
        }
    };
  
    const dequeue = async () => {
        await highlightLine(15);
        await customSleep(15);

        await highlightLine(16);
        setHighlightedVariables(['rear', 'front']);
        await customSleep(16);
        setHighlightedVariables([]);
        if (front === -1 || front > rear) {
            await highlightLine(17);
            await logMessage("Queue is Empty");
            await customSleep(17);
        } else {
            await highlightLine(18);
            await customSleep(18);

            await highlightLine(19);
            setHighlightedStackIndex([{ stackName: 'queue', index: front, iteratorName: 'front' }]);
            queue[front] = null;
            await customSleep(19);
            setHighlightedStackIndex([]);

            await highlightLine(20);
            setHighlightedVariables(['front']);
            front += 1;
            setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
            await customSleep(20);
            setHighlightedVariables([]);
        }
    };

    const display = async () => {
        await highlightLine(22);
        await customSleep(22);
        
        await highlightLine(23);
        setHighlightedVariables(['front']);
        await customSleep(23);
        setHighlightedVariables([]);
        if (front === -1) {
            await highlightLine(24);
            await logMessage("Queue is Empty");
            await customSleep(24);
        } else {
            await highlightLine(25);
            await customSleep(25);

            await highlightLine(26);
            await logMessage("Queue elements are:");
            await customSleep(26);

            for (let i = front; i <= rear; i++) {
                await highlightLine(27);
                setHighlightedVariables(['i', 'front', 'rear']);
                setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
                await customSleep(27);
                setHighlightedVariables([]);

                await highlightLine(28);
                setHighlightedVariables(['i']);
                setHighlightedStackIndex([{ stackName: 'queue', index: i, iteratorName: 'i' }]);
                await logMessage(`${queue[i]}`);
                await customSleep(27);
                setHighlightedStackIndex([]);
                setHighlightedVariables([]);
            }

            await highlightLine(29);
            await logMessage("-----");
            await customSleep(29);
        }
    }
  
    return { init, enqueue, dequeue, display };
  };
  
  // Java Queue Algorithm
  export const queueJava = async (
    highlightLine,
    setVariables,
    setArrayVariables,
    setStackVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedStackVariables,
    setHighlightedIndices,
    setHighlightedStackIndex,
    highlightCallingLine,
    customSleep,
    logMessage
  ) => {
    let size;
    let front = -1;
    let rear = -1;
    let queue = [];
  
    const init = async (_size) => {
      await highlightLine(5);
      setVariables((vars) => ({ ...vars, _size: { variable_name: '_size', value: _size } }));
      setHighlightedVariables(['_size']);
      await customSleep(5);
      setHighlightedVariables([]);

      await highlightLine(6);
      size = _size;
      setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
      setHighlightedVariables(['size']);
      await customSleep(6);
      setHighlightedVariables([]);

      await highlightLine(7);
      queue = Array(size).fill(null);
      setStackVariables({ queue: { variable_name: 'queue', value: queue } });
      setHighlightedStackVariables(['queue']);
      await customSleep(7);
      setHighlightedStackVariables([]);

      await highlightLine(8);
      front = rear = -1;
      setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front }, rear: { variable_name: 'rear', value: rear } }));
      setHighlightedVariables(['front', 'rear']);
      await customSleep(8);
      setHighlightedVariables([]);
    };
  
    const enqueue = async (data) => {
      await highlightLine(11);
      setVariables((vars) => ({ ...vars, data: { variable_name: 'data', value: data } }));
      setHighlightedVariables(['data']);
      await customSleep(11);
      setHighlightedVariables([]);

      await highlightLine(12);
      setHighlightedVariables(['rear', 'size']);
      await customSleep(12);
      setHighlightedVariables([]);
      if (rear === (size - 1)) {
          await highlightLine(13);
          await logMessage("Queue is Full");
          await customSleep(13);
      } else {
          await highlightLine(14);
          await customSleep(14);

          await highlightLine(15);
          setHighlightedVariables(['front']);
          await customSleep(15);
          setHighlightedVariables([]);
          if (front === -1) {
              await highlightLine(16);
              setHighlightedVariables(['front']);
              front = 0;
              setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
              await customSleep(16);
              setHighlightedVariables([]);
          }
          await highlightLine(18);
          setHighlightedVariables(['rear']);
          rear += 1;
          setVariables((vars) => ({ ...vars, rear: { variable_name: 'rear', value: rear } }));
          await customSleep(18);
          setHighlightedVariables([]);

          await highlightLine(19);
          setHighlightedVariables(['rear', 'data']);
          setHighlightedStackIndex([{ stackName: 'queue', index: rear, iteratorName: 'rear' }]);
          queue[rear] = data;
          await customSleep(19);
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        }
    };
  
    const dequeue = async () => {
      await highlightLine(23);
      await customSleep(23);

      await highlightLine(24);
      setHighlightedVariables(['rear', 'front']);
      await customSleep(24);
      setHighlightedVariables([]);
      if (front === -1 || front > rear) {
        await highlightLine(25);
        await logMessage("Queue is Empty");
        await customSleep(25);
      } else {
        await highlightLine(26);
        await customSleep(26);

        await highlightLine(27);
        setHighlightedStackIndex([{ stackName: 'queue', index: front, iteratorName: 'front' }]);
        queue[front] = 0;
        await customSleep(27);
        setHighlightedStackIndex([]);

        await highlightLine(28);
        setHighlightedVariables(['front']);
        front += 1;
        setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
        await customSleep(28);
        setHighlightedVariables([]);
      }
    };

    const display = async () => {
      await highlightLine(32);
      await customSleep(32);
      
      await highlightLine(33);
      setHighlightedVariables(['front']);
      await customSleep(33);
      setHighlightedVariables([]);
      if (front === -1) {
          await highlightLine(34);
          await logMessage("Queue is Empty");
          await customSleep(34);
      } else {
          await highlightLine(35);
          await customSleep(35);

          await highlightLine(36);
          await logMessage("Queue elements are:");
          await customSleep(36);

          for (let i = front; i <= rear; i++) {
              await highlightLine(37);
              setHighlightedVariables(['i', 'front', 'rear']);
              setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
              await customSleep(37);
              setHighlightedVariables([]);

              await highlightLine(38);
              setHighlightedVariables(['i']);
              setHighlightedStackIndex([{ stackName: 'queue', index: i, iteratorName: 'i' }]);
              await logMessage(`${queue[i]}`);
              await customSleep(38);
              setHighlightedStackIndex([]);
              setHighlightedVariables([]);
          }

          await highlightLine(40);
          await logMessage("-----");
          await customSleep(40);
      }
    };
  
    return { init, enqueue, dequeue, display };
  };
  
  // C Queue Algorithm (No struct)
  export const queueC = async (
    highlightLine,
    setVariables,
    setArrayVariables,
    setStackVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedStackVariables,
    setHighlightedIndices,
    setHighlightedStackIndex,
    highlightCallingLine,
    customSleep,
    logMessage
  ) => {
    let MAX = 0;
    let size;
    let front = -1;
    let rear = -1;
    let queue = [];
  
    const init = async (_size) => {
      await highlightLine(2);
      MAX = _size;
      setVariables((vars) => ({ ...vars, MAX: { variable_name: 'MAX', value: MAX } }));
      setHighlightedVariables(['MAX']);
      await customSleep(2);
      setHighlightedVariables([]);

      await highlightLine(4);
      queue = Array(MAX).fill(null);
      setStackVariables({ queue: { variable_name: 'queue', value: queue } });
      setHighlightedStackVariables(['queue']);
      await customSleep(4);
      setHighlightedStackVariables([]);

      await highlightLine(5);
      front = rear = -1;
      size = MAX;
      setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front }, rear: { variable_name: 'rear', value: rear }, size: { variable_name: 'size', value: size } }));
      setHighlightedVariables(['front', 'rear', 'size']);
      await customSleep(5);
      setHighlightedVariables([]);
    };
  
    const enqueue = async (data) => {
      await highlightLine(7);
      setVariables((vars) => ({ ...vars, data: { variable_name: 'data', value: data } }));
      setHighlightedVariables(['data']);
      await customSleep(7);
      setHighlightedVariables([]);

      await highlightLine(8);
      setHighlightedVariables(['rear', 'size']);
      await customSleep(8);
      setHighlightedVariables([]);
      if (rear === (size - 1)) {
          await highlightLine(9);
          await logMessage("Queue is Full");
          await customSleep(9);
      } else {
          await highlightLine(10);
          await customSleep(10);

          await highlightLine(11);
          setHighlightedVariables(['front']);
          await customSleep(11);
          setHighlightedVariables([]);
          if (front === -1) {
              await highlightLine(12);
              setHighlightedVariables(['front']);
              front = 0;
              setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
              await customSleep(12);
              setHighlightedVariables([]);
          }
          await highlightLine(14);
          setHighlightedVariables(['rear']);
          rear += 1;
          setVariables((vars) => ({ ...vars, rear: { variable_name: 'rear', value: rear } }));
          await customSleep(14);
          setHighlightedVariables([]);

          await highlightLine(15);
          setHighlightedVariables(['rear', 'data']);
          setHighlightedStackIndex([{ stackName: 'queue', index: rear, iteratorName: 'rear' }]);
          queue[rear] = data;
          await customSleep(15);
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        }
    };
  
    const dequeue = async () => {
      await highlightLine(19);
      await customSleep(19);

      await highlightLine(20);
      setHighlightedVariables(['rear', 'front']);
      await customSleep(20);
      setHighlightedVariables([]);
      if (front === -1 || front > rear) {
        await highlightLine(21);
        await logMessage("Queue is Empty");
        await customSleep(21);
      } else {
        await highlightLine(22);
        await customSleep(22);

        await highlightLine(23);
        setHighlightedStackIndex([{ stackName: 'queue', index: front, iteratorName: 'front' }]);
        queue[front] = 0;
        await customSleep(23);
        setHighlightedStackIndex([]);

        await highlightLine(24);
        setHighlightedVariables(['front']);
        front += 1;
        setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
        await customSleep(24);
        setHighlightedVariables([]);
      }
    };

    const display = async () => {
      await highlightLine(28);
      await customSleep(28);
      
      await highlightLine(29);
      setHighlightedVariables(['front']);
      await customSleep(29);
      setHighlightedVariables([]);
      if (front === -1) {
          await highlightLine(30);
          await logMessage("Queue is Empty");
          await customSleep(30);
      } else {
          await highlightLine(31);
          await customSleep(31);

          await highlightLine(32);
          await logMessage("Queue elements are:");
          await customSleep(32);

          for (let i = front; i <= rear; i++) {
              await highlightLine(33);
              setHighlightedVariables(['i', 'front', 'rear']);
              setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
              await customSleep(33);
              setHighlightedVariables([]);

              await highlightLine(34);
              setHighlightedVariables(['i']);
              setHighlightedStackIndex([{ stackName: 'queue', index: i, iteratorName: 'i' }]);
              await logMessage(`${queue[i]}`);
              await customSleep(34);
              setHighlightedStackIndex([]);
              setHighlightedVariables([]);
          }

          await highlightLine(36);
          await logMessage("-----");
          await customSleep(36);
      }
    };
  
    return { init, enqueue, dequeue, display };
  };
  
  // C++ Queue Algorithm (No class)
  export const queueCpp = async (
    highlightLine,
    setVariables,
    setArrayVariables,
    setStackVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedStackVariables,
    setHighlightedIndices,
    setHighlightedStackIndex,
    highlightCallingLine,
    customSleep,
    logMessage
  ) => {
    let MAX = 0;
    let size;
    let front = -1;
    let rear = -1;
    let queue = [];
  
    const init = async (_size) => {
      await highlightLine(3);
      MAX = _size;
      setVariables((vars) => ({ ...vars, MAX: { variable_name: 'MAX', value: MAX } }));
      setHighlightedVariables(['MAX']);
      await customSleep(3);
      setHighlightedVariables([]);

      await highlightLine(5);
      queue = Array(MAX).fill(null);
      setStackVariables({ queue: { variable_name: 'queue', value: queue } });
      setHighlightedStackVariables(['queue']);
      await customSleep(5);
      setHighlightedStackVariables([]);

      await highlightLine(6);
      front = rear = -1;
      size = MAX;
      setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front }, rear: { variable_name: 'rear', value: rear }, size: { variable_name: 'size', value: size } }));
      setHighlightedVariables(['front', 'rear', 'size']);
      await customSleep(6);
      setHighlightedVariables([]);
    };
  
    const enqueue = async (data) => {
      await highlightLine(8);
      setVariables((vars) => ({ ...vars, data: { variable_name: 'data', value: data } }));
      setHighlightedVariables(['data']);
      await customSleep(8);
      setHighlightedVariables([]);

      await highlightLine(9);
      setHighlightedVariables(['rear', 'size']);
      await customSleep(9);
      setHighlightedVariables([]);
      if (rear === (size - 1)) {
          await highlightLine(10);
          await logMessage("Queue is Full");
          await customSleep(10);
      } else {
          await highlightLine(11);
          await customSleep(11);

          await highlightLine(12);
          setHighlightedVariables(['front']);
          await customSleep(12);
          setHighlightedVariables([]);
          if (front === -1) {
              await highlightLine(13);
              setHighlightedVariables(['front']);
              front = 0;
              setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
              await customSleep(13);
              setHighlightedVariables([]);
          }
          await highlightLine(15);
          setHighlightedVariables(['rear']);
          rear += 1;
          setVariables((vars) => ({ ...vars, rear: { variable_name: 'rear', value: rear } }));
          await customSleep(15);
          setHighlightedVariables([]);

          await highlightLine(16);
          setHighlightedVariables(['rear', 'data']);
          setHighlightedStackIndex([{ stackName: 'queue', index: rear, iteratorName: 'rear' }]);
          queue[rear] = data;
          await customSleep(16);
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        }
    };
  
    const dequeue = async () => {
      await highlightLine(20);
      await customSleep(20);

      await highlightLine(21);
      setHighlightedVariables(['rear', 'front']);
      await customSleep(21);
      setHighlightedVariables([]);
      if (front === -1 || front > rear) {
        await highlightLine(22);
        await logMessage("Queue is Empty");
        await customSleep(22);
      } else {
        await highlightLine(23);
        await customSleep(23);

        await highlightLine(24);
        setHighlightedStackIndex([{ stackName: 'queue', index: front, iteratorName: 'front' }]);
        queue[front] = 0;
        await customSleep(24);
        setHighlightedStackIndex([]);

        await highlightLine(25);
        setHighlightedVariables(['front']);
        front += 1;
        setVariables((vars) => ({ ...vars, front: { variable_name: 'front', value: front } }));
        await customSleep(25);
        setHighlightedVariables([]);
      }
    };

    const display = async () => {
      await highlightLine(29);
      await customSleep(29);
      
      await highlightLine(30);
      setHighlightedVariables(['front']);
      await customSleep(30);
      setHighlightedVariables([]);
      if (front === -1) {
          await highlightLine(31);
          await logMessage("Queue is Empty");
          await customSleep(31);
      } else {
          await highlightLine(32);
          await customSleep(32);

          await highlightLine(33);
          await logMessage("Queue elements are:");
          await customSleep(33);

          for (let i = front; i <= rear; i++) {
              await highlightLine(34);
              setHighlightedVariables(['i', 'front', 'rear']);
              setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
              await customSleep(34);
              setHighlightedVariables([]);

              await highlightLine(35);
              setHighlightedVariables(['i']);
              setHighlightedStackIndex([{ stackName: 'queue', index: i, iteratorName: 'i' }]);
              await logMessage(`${queue[i]}`);
              await customSleep(35);
              setHighlightedStackIndex([]);
              setHighlightedVariables([]);
          }

          await highlightLine(37);
          await logMessage("-----");
          await customSleep(37);
      }
    };
  
    return { init, enqueue, dequeue, display };
  };
  