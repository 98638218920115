const generateMemoryAddress = () => {
  return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
};

class Node {
  constructor(data, nodeAddress) {
    this.data = data;
    this.next = null;
    this.address = nodeAddress;
    this.parent = null;
  }
}

class LinkedListForJava {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    await highlightLine(13);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep(13);
    setHighlightedVariables([]);

    await highlightLine(14);
    await customSleep(14);

    await highlightSecondCallingLine(14);
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);
    await highlightLine(4);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    setHighlightedNodeVariables([`${nodeAddress}`]);
    await customSleep(4);
    setHighlightedNodeVariables([]);

    await highlightLine(5);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(5);
    setHighlightedVariables([]);
    setHighlightDataPart([]);

    await highlightLine(6);
    setHighlightNextAddressPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'null'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(6);
    setHighlightNextAddressPart([]);

    await highlightLine(14);
    await customSleep(14);
    setHighlightedNodeVariables([]);

    await highlightLine(15);
    setHighlightedHeadVariables(['head']);
    await customSleep(15);
    setHighlightedHeadVariables([]);
    if (this.head === null) {
        await highlightLine(16);
        this.head = newNode;
        setHighlightedHeadVariables(['head']);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${nodeAddress}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        await customSleep(16);
        setHighlightedHeadVariables([]);

        setVariables((vars) => ({}));
    } else {
        await highlightLine(17);
        await customSleep(17);

        await highlightLine(18);
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
        setHighlightedHeadVariables(['temp']);
        await customSleep(18);
        setHighlightedHeadVariables([]);

        if(!(temp.next !== null)) {
          await highlightLine(19);
          setHighlightNextAddressPart([`${temp.address}`]);
          await customSleep(19);
          setHighlightNextAddressPart([]);
        }
        while (temp.next !== null) {
            await highlightLine(19);
            setHighlightNextAddressPart([`${temp.address}`]);
            await customSleep(19);
            setHighlightNextAddressPart([]);

            await highlightLine(20);
            temp = temp.next;
            
            setHighlightNodeAddress([`${temp.address}`]);
            await customSleep(null, 1000);

            setHeadVariables((vars) => ({ ...vars, 
              temp: { variable_name: 'temp', value: temp.address, child: temp.address }
            }));
            setHighlightedHeadVariables(['temp']);
            await customSleep(20);
            setHighlightNodeAddress([]);
            setHighlightedHeadVariables([]);

            if (!(temp.next !== null)) {
              await highlightLine(19);
              setHighlightNextAddressPart([`${temp.address}`]);
              await customSleep(19);
              setHighlightNextAddressPart([]);
            }
        }

        await highlightLine(22);
        temp.next = newNode;
        newNode.parent = temp.address;

        setHighlightedNodeVariables([`${temp.address}`]);
        await customSleep(null, 1000);
        setHighlightedNodeVariables([]);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${newNode.address}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        await customSleep(22);
        setHighlightNextAddressPart([]);
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));
    }
  }
}


export const linkedListJava = async (
    data1,
    data2,
    data3,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
) => {
  let list = new LinkedListForJava();

  await highlightLine(30);
  await customSleep(30);

  await highlightCallingLine(30);
  await list.insert(
    data1,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(30);
  await customSleep(30);

  await highlightLine(31);
  await customSleep(31);

  await highlightCallingLine(31);
  await list.insert(
    data2,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(31);
  await customSleep(31);

  await highlightLine(32);
  await customSleep(32);

  await highlightCallingLine(32);
  await list.insert(
    data3,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(32);
  await customSleep(32);
};


class LinkedListForPython {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    await highlightLine(9);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep(9);
    setHighlightedVariables([]);

    await highlightLine(10);
    await customSleep(10);

    await highlightSecondCallingLine(10);
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);
    await highlightLine(1);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    setHighlightedNodeVariables([`${nodeAddress}`]);
    await customSleep(1);
    setHighlightedNodeVariables([]);

    await highlightLine(2);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(2);
    setHighlightedVariables([]);
    setHighlightDataPart([]);

    await highlightLine(3);
    setHighlightNextAddressPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'None'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(3);
    setHighlightNextAddressPart([]);

    await highlightLine(10);
    await customSleep(10);
    setHighlightedNodeVariables([]);

    await highlightLine(11);
    setHighlightedHeadVariables(['head']);
    await customSleep(11);
    setHighlightedHeadVariables([]);
    if (this.head === null) {
        await highlightLine(12);
        this.head = newNode;
        setHighlightedHeadVariables(['head']);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${nodeAddress}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        await customSleep(12);
        setHighlightedHeadVariables([]);

        setVariables((vars) => ({}));
    } else {
        await highlightLine(13);
        await customSleep(13);

        await highlightLine(14);
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
        setHighlightedHeadVariables(['temp']);
        await customSleep(14);
        setHighlightedHeadVariables([]);

        if(!(temp.next !== null)) {
          await highlightLine(15);
          setHighlightNextAddressPart([`${temp.address}`]);
          await customSleep(15);
          setHighlightNextAddressPart([]);
        }
        while (temp.next !== null) {
            await highlightLine(15);
            setHighlightNextAddressPart([`${temp.address}`]);
            await customSleep(15);
            setHighlightNextAddressPart([]);

            await highlightLine(16);
            temp = temp.next;

            setHighlightNodeAddress([`${temp.address}`]);
            await customSleep(null, 1000);
            
            setHeadVariables((vars) => ({ ...vars, 
              temp: { variable_name: 'temp', value: temp.address, child: temp.address }
            }));
            setHighlightedHeadVariables(['temp']);
            await customSleep(16);
            setHighlightNodeAddress([]);
            setHighlightedHeadVariables([]);

            if (!(temp.next !== null)) {
              await highlightLine(15);
              setHighlightNextAddressPart([`${temp.address}`]);
              await customSleep(15);
              setHighlightNextAddressPart([]);
            }
        }

        await highlightLine(17);
        temp.next = newNode;
        newNode.parent = temp.address;

        setHighlightedNodeVariables([`${temp.address}`]);
        await customSleep(null, 1000);
        setHighlightedNodeVariables([]);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${newNode.address}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        await customSleep(17);
        setHighlightNextAddressPart([]);
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));
    }
  }
}

export const linkedListPython = async (
  data1,
  data2,
  data3,
  setVariables,
  setHighlightedVariables,
  setNodeVariables,
  setHeadVariables,
  setHighlightedNodeVariables,
  setHighlightedHeadVariables,
  setHighlightDataPart,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  highlightLine,
  highlightCallingLine,
  highlightSecondCallingLine,
  customSleep
) => {
  let list = new LinkedListForPython();

  await highlightLine(22);
  await customSleep(22);

  await highlightCallingLine(22);
  await list.insert(
    data1,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(22);
  await customSleep(22);

  await highlightLine(23);
  await customSleep(23);

  await highlightCallingLine(23);
  await list.insert(
    data2,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(23);
  await customSleep(23);

  await highlightLine(24);
  await customSleep(24);

  await highlightCallingLine(24);
  await list.insert(
    data3,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(24);
  await customSleep(24);
};


class LinkedListForCpp {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    await highlightLine(22);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep(22);
    setHighlightedVariables([]);

    await highlightLine(23);
    await customSleep(23);

    await highlightSecondCallingLine(23);
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);
    await highlightLine(8);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    setHighlightedNodeVariables([`${nodeAddress}`]);
    await customSleep(8);
    setHighlightedNodeVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(9);
    setHighlightedVariables([]);
    setHighlightDataPart([]);

    await highlightLine(10);
    setHighlightNextAddressPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'nullptr'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(10);
    setHighlightNextAddressPart([]);

    await highlightLine(23);
    await customSleep(23);
    setHighlightedNodeVariables([]);

    await highlightLine(24);
    setHighlightedHeadVariables(['head']);
    await customSleep(24);
    setHighlightedHeadVariables([]);
    if (this.head === null) {
        await highlightLine(25);
        this.head = newNode;
        setHighlightedHeadVariables(['head']);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${nodeAddress}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        await customSleep(25);
        setHighlightedHeadVariables([]);

        setVariables((vars) => ({}));
    } else {
        await highlightLine(26);
        await customSleep(26);

        await highlightLine(27);
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
        setHighlightedHeadVariables(['temp']);
        await customSleep(27);
        setHighlightedHeadVariables([]);

        if(!(temp.next !== null)) {
          await highlightLine(28);
          setHighlightNextAddressPart([`${temp.address}`]);
          await customSleep(28);
          setHighlightNextAddressPart([]);
        }
        while (temp.next !== null) {
            await highlightLine(28);
            setHighlightNextAddressPart([`${temp.address}`]);
            await customSleep(28);
            setHighlightNextAddressPart([]);

            await highlightLine(29);
            temp = temp.next;

            setHighlightNodeAddress([`${temp.address}`]);
            await customSleep(null, 1000);
            
            setHeadVariables((vars) => ({ ...vars, 
              temp: { variable_name: 'temp', value: temp.address, child: temp.address }
            }));
            setHighlightedHeadVariables(['temp']);
            await customSleep(29);
            setHighlightNodeAddress([]);
            setHighlightedHeadVariables([]);

            if (!(temp.next !== null)) {
              await highlightLine(28);
              setHighlightNextAddressPart([`${temp.address}`]);
              await customSleep(28);
              setHighlightNextAddressPart([]);
            }
        }

        await highlightLine(31);
        temp.next = newNode;
        newNode.parent = temp.address;

        setHighlightedNodeVariables([`${temp.address}`]);
        await customSleep(null, 1000);
        setHighlightedNodeVariables([]);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${newNode.address}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        await customSleep(31);
        setHighlightNextAddressPart([]);
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));
    }
  }
}

export const linkedListCpp = async (
  data1,
  data2,
  data3,
  setVariables,
  setHighlightedVariables,
  setNodeVariables,
  setHeadVariables,
  setHighlightedNodeVariables,
  setHighlightedHeadVariables,
  setHighlightDataPart,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  highlightLine,
  highlightCallingLine,
  highlightSecondCallingLine,
  customSleep
) => {
  let list = new LinkedListForCpp();

  await highlightLine(39);
  await customSleep(39);

  await highlightCallingLine(39);
  await list.insert(
    data1,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(39);
  await customSleep(39);

  await highlightLine(40);
  await customSleep(40);

  await highlightCallingLine(40);
  await list.insert(
    data2,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(40);
  await customSleep(40);

  await highlightLine(41);
  await customSleep(41);

  await highlightCallingLine(41);
  await list.insert(
    data3,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(41);
  await customSleep(41);
};


class LinkedListForC {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightMultipleLines,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    await highlightLine(10);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep(10);
    setHighlightedVariables([]);

    await highlightLine(11);
    await customSleep(11);
    await highlightSecondCallingLine(11);
    await highlightMultipleLines([3, 4, 5, 6]);
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    setHighlightedNodeVariables([`${nodeAddress}`]);
    await customSleep(3);
    highlightMultipleLines([]);
    await highlightLine(11);
    await customSleep(11);
    setHighlightedNodeVariables([]);

    await highlightLine(12);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(12);
    setHighlightedVariables([]);
    setHighlightDataPart([]);

    await highlightLine(13);
    setHighlightNextAddressPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'NULL'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    await customSleep(13);
    setHighlightNextAddressPart([]);

    await highlightLine(15);
    setHighlightedHeadVariables(['head']);
    await customSleep(15);
    setHighlightedHeadVariables([]);
    if (this.head === null) {
        await highlightLine(16);
        this.head = newNode;
        setHighlightedHeadVariables(['head']);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${nodeAddress}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        await customSleep(16);
        setHighlightedHeadVariables([]);

        setVariables((vars) => ({}));
    } else {
        await highlightLine(17);
        await customSleep(17);

        await highlightLine(18);
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
        setHighlightedHeadVariables(['temp']);
        await customSleep(18);
        setHighlightedHeadVariables([]);

        if(!(temp.next !== null)) {
          await highlightLine(19);
          setHighlightNextAddressPart([`${temp.address}`]);
          await customSleep(19);
          setHighlightNextAddressPart([]);
        }
        while (temp.next !== null) {
            await highlightLine(19);
            setHighlightNextAddressPart([`${temp.address}`]);
            await customSleep(19);
            setHighlightNextAddressPart([]);

            await highlightLine(20);
            temp = temp.next;

            setHighlightNodeAddress([`${temp.address}`]);
            await customSleep(null, 1000);
            
            setHeadVariables((vars) => ({ ...vars, 
              temp: { variable_name: 'temp', value: temp.address, child: temp.address }
            }));
            setHighlightedHeadVariables(['temp']);
            await customSleep(20);
            setHighlightNodeAddress([]);
            setHighlightedHeadVariables([]);

            if (!(temp.next !== null)) {
              await highlightLine(19);
              setHighlightNextAddressPart([`${temp.address}`]);
              await customSleep(19);
              setHighlightNextAddressPart([]);
            }
        }

        await highlightLine(22);
        temp.next = newNode;
        newNode.parent = temp.address;

        setHighlightedNodeVariables([`${temp.address}`]);
        await customSleep(null, 1000);
        setHighlightedNodeVariables([]);

        setHighlightNextAddressPart([`${temp.address}`]);
        await customSleep(null, 1000);

        setHighlightNodeAddress([`${newNode.address}`]);
        await customSleep(null, 1000);
        setHighlightNodeAddress([]);

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        await customSleep(22);
        setHighlightNextAddressPart([]);
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));
    }
  }
}

export const linkedListC = async (
  data1,
  data2,
  data3,
  setVariables,
  setHighlightedVariables,
  setNodeVariables,
  setHeadVariables,
  setHighlightedNodeVariables,
  setHighlightedHeadVariables,
  setHighlightDataPart,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  highlightLine,
  highlightMultipleLines,
  highlightCallingLine,
  highlightSecondCallingLine,
  customSleep
) => {
  let list = new LinkedListForC();

  await highlightLine(27);
  await customSleep(27);

  await highlightCallingLine(27);
  await list.insert(
    data1,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightMultipleLines,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(27);
  await customSleep(27);

  await highlightLine(28);
  await customSleep(28);

  await highlightCallingLine(28);
  await list.insert(
    data2,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightMultipleLines,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(28);
  await customSleep(28);

  await highlightLine(29);
  await customSleep(29);

  await highlightCallingLine(29);
  await list.insert(
    data3,
    setVariables,
    setHighlightedVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightMultipleLines,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  );
  await highlightLine(29);
  await customSleep(29);
};
  