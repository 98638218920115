import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program defines a Dog class to represent a dog with specific actions (bark and sleep). It demonstrates object-oriented programming (OOP) concepts such as encapsulation, constructors, and methods.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>OOP Concepts:</b> Illustrates encapsulation, constructors, and member methods.</li>
            <li><b>Simulation Systems:</b> Models real-world entities (e.g., pets) and their behaviors.</li>
            <li><b>Game Development:</b> Represents characters or entities with specific actions.</li>
            <li><b>Learning Tool:</b> Demonstrates class syntax and basic object manipulation.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
