import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates <b>sequence data types</b> including <b>strings</b>, <b>lists</b>, <b>tuples</b>, and <b>ranges</b>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>String:</b> Storing and processing names, messages, or text data.</li>
            <li><b>List:</b> Managing dynamic collections like shopping carts in e-commerce platforms.</li>
            <li><b>Tuple:</b> Representing fixed sets of data like GPS coordinates.</li>
            <li><b>Range:</b> Efficiently generating sequences, such as for pagination or numbering.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
