import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FloatingDrawer = () => {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();

    const languages = [
        { name: 'C', icon: '/c-logo.svg' },
        { name: 'C++', icon: '/cpp-logo.svg' },
        { name: 'Java', icon: '/java-logo.svg' },
        { name: 'Python', icon: '/python-logo.svg' },
    ];

    const handleNavigation = (lang) => {
      navigate(`/topics/${lang.toLowerCase()}`);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

  return (
    <div className={`floating-drawer ${isOpen ? 'open' : 'closed'}`}>
      <button
        className={`toggle-button`}
        onClick={() => setIsOpen(!isOpen)}
        aria-label={isOpen ? 'Close Drawer' : 'Open Drawer'}
      >
        {isOpen ? 
          <i 
            class="fa fa-angle-double-right"
            style={{color: '#343a40', fontSize: '15px'}}
          ></i> 
          : <i 
              class="fa fa-light fa-bars"
              style={{color: '#343a40', fontSize: '15px'}}
            ></i>
        }
      </button>
      {isOpen &&
        languages.map((lang) => (
          <div
            key={lang.name}
            className="drawer-icon"
            onClick={() => handleNavigation(lang.name)}
            style={{ cursor: 'pointer' }}
          >
            <img src={lang.icon} alt={`${lang.name} icon`} />
            <div className="tooltip">{lang.name}</div>
          </div>
        ))}
    </div>
  );
};

export default FloatingDrawer;
