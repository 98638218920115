import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useWhileLoop = (language, count, maxCount, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newCount, newMaxCount) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === "Python") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/count = \d+/, `count = ${newCount}`)
          .replace(/max_count = \d+/, `max_count = ${newMaxCount}`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/count = \d+/, `count = ${newCount}`)
          .replace(/maxCount = \d+/, `maxCount = ${newMaxCount}`)
      );
    }
     
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber, endLine = null) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    setFocusedEndLine(endLine);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runWhileLoopPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    setVariables({ count: { variable_name: 'count', value: count } });
    setHighlightedVariables(['count']);
    await customSleep(1);
    setHighlightedVariables([]);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, maxCount: { variable_name: 'max_count', value: maxCount } }));
    setHighlightedVariables(['max_count']);
    await customSleep(2);
    setHighlightedVariables([]);

    if (!(count < maxCount)) {
      await highlightLine(4);
      setHighlightedVariables(['count', 'max_count']);
      await customSleep(4);
    }
    while (count < maxCount) {
      await highlightLine(4);
      setHighlightedVariables(['count', 'max_count']);
      await customSleep(4);

      await highlightLine(5);
      setHighlightedVariables(['count']);
      await logMessage(`Count is: ${count}`);
      await customSleep(5);
      setHighlightedVariables([]);
      
      await highlightLine(6);
      setHighlightedVariables(['count']);
      count += 1;
      setVariables((vars) => ({ ...vars, count: { variable_name: 'count', value: count } }));
      await customSleep(6);
      setHighlightedVariables([]);
    }

    await focusEndLine(6);
    setIsRunning(false);
  };

  const runWhileLoopJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables({ count: { variable_name: 'count', value: count } });
    setHighlightedVariables(['count']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, maxCount: { variable_name: 'maxCount', value: maxCount } }));
    setHighlightedVariables(['maxCount']);
    await customSleep(3);
    setHighlightedVariables([]);

    if (!(count < maxCount)) {
      await highlightLine(5);
      setHighlightedVariables(['count', 'maxCount']);
      await customSleep(5);
    }
    while (count < maxCount) {
      await highlightLine(5);
      setHighlightedVariables(['count', 'maxCount']);
      await customSleep(5);

      await highlightLine(6);
      setHighlightedVariables(['count']);
      await logMessage(`Count is: ${count}`);
      await customSleep(6);
      setHighlightedVariables([]);
      
      await highlightLine(7);
      setHighlightedVariables(['count']);
      count += 1;
      setVariables((vars) => ({ ...vars, count: { variable_name: 'count', value: count } }));
      await customSleep(7);
      setHighlightedVariables([]);
    }

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runWhileLoopC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables({ count: { variable_name: 'count', value: count } });
    setHighlightedVariables(['count']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, maxCount: { variable_name: 'maxCount', value: maxCount } }));
    setHighlightedVariables(['maxCount']);
    await customSleep(3);
    setHighlightedVariables([]);

    if (!(count < maxCount)) {
      await highlightLine(5);
      setHighlightedVariables(['count', 'maxCount']);
      await customSleep(5);
    }
    while (count < maxCount) {
      await highlightLine(5);
      setHighlightedVariables(['count', 'maxCount']);
      await customSleep(5);

      await highlightLine(6);
      setHighlightedVariables(['count']);
      await logMessage(`Count is: ${count}`);
      await customSleep(6);
      setHighlightedVariables([]);
      
      await highlightLine(7);
      setHighlightedVariables(['count']);
      count += 1;
      setVariables((vars) => ({ ...vars, count: { variable_name: 'count', value: count } }));
      await customSleep(7);
      setHighlightedVariables([]);
    }

    await highlightLine(9);
    await customSleep(9);

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runWhileLoopCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setVariables({ count: { variable_name: 'count', value: count } });
    setHighlightedVariables(['count']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, maxCount: { variable_name: 'maxCount', value: maxCount } }));
    setHighlightedVariables(['maxCount']);
    await customSleep(4);
    setHighlightedVariables([]);

    if (!(count < maxCount)) {
      await highlightLine(6);
      setHighlightedVariables(['count', 'maxCount']);
      await customSleep(6);
    }
    while (count < maxCount) {
      await highlightLine(6);
      setHighlightedVariables(['count', 'maxCount']);
      await customSleep(6);

      await highlightLine(7);
      setHighlightedVariables(['count']);
      await logMessage(`Count is: ${count}`);
      await customSleep(7);
      setHighlightedVariables([]);
      
      await highlightLine(8);
      setHighlightedVariables(['count']);
      count += 1;
      setVariables((vars) => ({ ...vars, count: { variable_name: 'count', value: count } }));
      await customSleep(8);
      setHighlightedVariables([]);
    }

    await highlightLine(10);
    await customSleep(10);

    await focusEndLine(11);
    setIsRunning(false);
  };

  const runWhileLoop = async () => {
    if (language === 'Python') {
      await runWhileLoopPython();
    } else if (language === 'Java') {
      await runWhileLoopJava();
    } else if (language === 'C') {
      await runWhileLoopC();
    } else if (language === 'C++') {
      await runWhileLoopCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    runWhileLoop
  };
};
