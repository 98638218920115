import React, { useState } from 'react';
import axios from 'axios';

const PaymentButton = ({ amount, currency, currencySymbol, plan, triggerPayment }) => {
    const [status, setStatus] = useState('default'); // default, loading, verified

    const handlePayment = async () => {
        try {
            setStatus('loading');

            const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
            if (!isLoggedIn) {
                const registerModalEvent = new CustomEvent('showRegisterModal', { detail: { plan } });
                window.dispatchEvent(registerModalEvent);
                setStatus('default');
                return;
            }

            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('userToken');
            const header = {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const data = { amount, plan, currency };
            const orderResponse = await axios.post(`${apiUrl}/api/create-order/`, data, header);
            const order = orderResponse.data;

            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                const options = {
                    key: order.key,
                    amount: order.amount,
                    currency: order.currency,
                    name: 'CodingCanvas',
                    description: 'Subscription Payment',
                    order_id: order.order_id,
                    handler: async (response) => {
                        try {
                            const verifyResponse = await axios.post(`${apiUrl}/api/verify-payment/`, response, header);
                            if (verifyResponse.status === 200) {
                                setStatus('verified');
                                setTimeout(() => {
                                    window.location.href = '/pricing';
                                }, 1000);
                            } else {
                                setStatus('failed');
                            }
                        } catch (error) {
                            console.error('Verification Error:', error);
                            setStatus('failed');
                        }
                    },
                    prefill: {
                        email: order.email,
                    },
                    theme: {
                        color: '#212529',
                    },
                    modal: {
                        ondismiss: () => {
                            setStatus('default');
                        },
                    },
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            };
        } catch (error) {
            console.error('Payment Error:', error);
            if (error?.status === 429) {
                alert("Too many request");
            } else {
                alert('An error occurred while initiating the payment.');
            }
            setStatus('default');
        }
    };

    if (triggerPayment) {
        triggerPayment.current = handlePayment;
    }

    const getButtonStyle = () => {
        switch (status) {
            case 'loading':
                return 'btn btn-dark mt-3 py-2 btn-loading';
            case 'verified':
                return 'btn btn-success mt-3 py-2 btn-verified';
            case 'failed':
                return 'btn btn-danger mt-3 py-2 btn-verified';
            default:
                return 'btn btn-dark mt-3 py-2';
        }
    };

    return (
        <>
            <button 
                className={getButtonStyle()} 
                onClick={status !== 'verified' ? handlePayment : undefined}
                disabled={status === 'loading'}
            >
                {status === 'loading' ? (
                    'Processing...'
                ) : status === 'verified' ? (
                    <>
                        Payment Success <span className="fas fa-check-circle user-progress-tick-mark"></span>
                    </>
                ) : status === 'failed' ? (
                    <>
                        Payment Failed
                    </>
                ) : (
                    `Pay ${currencySymbol}${amount}`
                )}
            </button>
            <div 
                style={{fontSize: '13px', fontWeight: 'bold', paddingTop: '10px'}}
            >
                {status === 'verified' && (
                    <div style={{alignItems: 'center' }}>
                        Wait &nbsp;
                        <span className="dot-ellipsis">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};

export default PaymentButton;
