import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForInterface';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useInterfaceType } from './useInterfaceType';

const InterfaceType = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [initialInterface, setInitialInterface] = useState({});
  const [initialClass, setInitialClass] = useState({favoriteToy: "Teddy"});

  const [inputInitialClass, setInputInitialClass] = useState(initialClass);

  const [breakpoints, setBreakpoints] = useState([]);

  const {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    highlightedVariables,
    classVariables,
    highlightedClassVariables,
    highlightedClassMethods,
    interfaceVariables,
    highlightedInterfaceVariables,
    highlightedInterfaceMethods,
    stringVariables,
    highlightedStringVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runInterfaceType
  } = useInterfaceType(language, initialInterface, initialClass, breakpoints);

  const handleUpdateVariables = () => {
    setInitialClass(inputInitialClass);
    updateVariables(inputInitialClass);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            callingLine={callingLine}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={`Interface Example`}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputInitialClass(initialClass);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runInterfaceType}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              classVariables={classVariables}
              highlightedClassVariables={highlightedClassVariables}
              highlightedClassMethods={highlightedClassMethods}
              interfaceVariables={interfaceVariables}
              highlightedInterfaceVariables={highlightedInterfaceVariables}
              highlightedInterfaceMethods={highlightedInterfaceMethods}
              stringVariables={stringVariables}
              highlightedStringVariables={highlightedStringVariables}
              highlightedStringIndices={[]}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        favoriteToy={inputInitialClass.favoriteToy}
        setFavoriteToy={(value) => setInputInitialClass({ favoriteToy: value })}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default InterfaceType;
