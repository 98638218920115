import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates how to update a node's value in a <b>Singly Linked List</b>. 
        The update method traverses the list, searching for a node containing the specified oldData. 
        If found, it updates the node's data to newData and displays a success message. 
        If the list is empty or the value is not found, it informs the user.
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Inventory Systems:</b> Updating product details (like price or quantity) in an inventory management system where each product is stored as a node.</li>
            <li><b>Student Records Management:</b> Modifying a student's details, such as grades or attendance, stored in a linked list.</li>
            <li><b>Dynamic Configuration Systems:</b> In systems storing configuration settings, linked lists can allow dynamic updates to settings while maintaining efficient memory usage.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
