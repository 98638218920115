import React, { useEffect } from 'react';

const TermsAndConditions = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);

    // Set an SEO-friendly page title
    document.title = 'Terms and Conditions | Coding Canvas';

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Read the terms and conditions of using CodingCanvas, including subscription details, user rights, and policies.'
      );
    }
  }, []);

  return (
    <main className="container">
      <section className="text-center mb-5">
        <h1 className="mb-3">Terms and Conditions</h1>
        <h6>Effective Date: 01-December-2024</h6>
        <span>Welcome to CodingCanvas.io! By accessing or using our platform, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use the platform.</span>
      </section>

      <section className="mb-4">
        <h3>1. Acceptance of Terms</h3>
        <p style={{paddingLeft: '15px'}}>
            By using CodingCanvas.io, you agree to these Terms and Conditions, as well as our <a href="/privacy-policy">Privacy Policy</a>. We may modify these terms from time to time without notice, and it is your responsibility to review them periodically.
        </p>
      </section>

      <section className="mb-4">
        <h3>2. Use of the Platform</h3>
        <ul style={{paddingLeft: '30px', lineHeight: '1.8'}}>
          <li><span style={{fontWeight: 'bold'}}>Eligibility:</span> CodingCanvas.io is a learning platform open to users of all ages, with no specific age restrictions.</li>
          <li><span style={{fontWeight: 'bold'}}>License:</span> We grant you a non-exclusive, non-transferable, and revocable license to use the platform for personal, non-commercial purposes.</li>
          <li>
            <span style={{fontWeight: 'bold'}}>Prohibited Activities:</span>
            <ul style={{paddingLeft: '30px'}}>
                <li>Using the platform for unlawful purposes.</li>
                <li>Sharing or distributing copyrighted content without authorization.</li>
                <li>Attempting to hack, disrupt, or compromise the platform’s security.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>3. User Accounts</h3>
        <ul style={{paddingLeft: '30px', lineHeight: '1.8'}}>
          <li><span style={{fontWeight: 'bold'}}>Account Creation:</span> To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information.</li>
          <li><span style={{fontWeight: 'bold'}}>Accuracy of Information:</span> You agree to provide accurate and up-to-date information during registration.</li>
          <li><span style={{fontWeight: 'bold'}}>Account Termination:</span> We reserve the right to suspend or terminate accounts for any violation of these terms.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>4. Subscription and Payments</h3>
        <ul style={{paddingLeft: '30px', lineHeight: '1.8'}}>
          <li><span style={{fontWeight: 'bold'}}>Plans:</span> CodingCanvas offers Free, 6-Month, and 1-Year subscription plans. Subscription fees are non-refundable.</li>
          <li><span style={{fontWeight: 'bold'}}>Payment:</span> All payments must be made through the platform's authorized payment methods.</li>
          <li><span style={{fontWeight: 'bold'}}>Renewals:</span> Subscriptions will not renew automatically; users must manually renew them through their account.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>5. Content and Intellectual Property</h3>
        <ul style={{paddingLeft: '30px', lineHeight: '1.8'}}>
          <li><span style={{fontWeight: 'bold'}}>Ownership:</span> All content, code, and material on CodingCanvas.io are the intellectual property of Coding Canvas and are protected under copyright laws.</li>
          <li><span style={{fontWeight: 'bold'}}>User-Generated Content:</span> By submitting content (e.g., forum posts, feedback), you grant us a perpetual, worldwide, royalty-free license to use, display, and distribute your content.</li>
          <li><span style={{fontWeight: 'bold'}}>Copyright Complaints:</span> If you believe your copyright-protected work has been infringed, please contact us at <a href="mailto:support@codingcanvas.io">support@codingcanvas.io</a></li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>6. Privacy Policy</h3>
        <p style={{paddingLeft: '15px'}}>
          Please review our <a href="/privacy-policy">Privacy Policy</a> for details on how we collect and
          manage your personal data.
        </p>
      </section>

      <section className="mb-4">
        <h3>7. Disclaimer of Warranties</h3>
        <span>The platform and its content are provided on an "as-is" and "as-available" basis. <span style={{fontWeight: 'bold'}}>CodingCanvas.io</span> makes no warranties regarding:</span>
        <ul style={{paddingLeft: '30px', lineHeight: '1.8'}}>
          <li>The accuracy or reliability of the platform content.</li>
          <li>The uninterrupted or error-free operation of the platform.</li>
          <li>The suitability of the platform for your needs.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>8. Limitation of Liability</h3>
        <span>To the fullest extent permitted by law:</span>
        <ul style={{paddingLeft: '30px', lineHeight: '1.8'}}>
          <li>Coding Canvas shall not be liable for any indirect, incidental, or consequential damages arising out of your use of the platform.</li>
          <li>Our total liability to you shall not exceed the amount you paid for subscription services, if any.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>9. Governing Law</h3>
        <p style={{paddingLeft: '15px'}}>These terms are governed by the laws of India.</p>
      </section>

      <section className="mb-4">
        <h3>10. Termination</h3>
        <p style={{paddingLeft: '15px'}}>
            We reserve the right to suspend or terminate access to the platform at our sole discretion, with or without notice.
        </p>
      </section>

      <section className="mb-4">
        <h3>11. Contact Us</h3>
        <p style={{paddingLeft: '15px'}}>
          For any questions regarding these Terms and Conditions, please contact us at{' '}
          <a href="mailto:support@codingcanvas.io">support@codingcanvas.io</a>.
        </p>
      </section>
    </main>
  );
};

export default TermsAndConditions;
