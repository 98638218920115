import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the use of a <b>while loop</b>, which repeatedly executes a block 
        of code as long as the specified condition is true. The program prints a counter value, 
        incrementing it until it reaches a specified maximum.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Input Validation:</b> Continuously prompting the user for valid input until the criteria are met.</li>
            <li><b>Game Development:</b> Running the game loop until a "game over" condition is met.</li>
            <li><b>File Reading:</b> Reading a file line by line until the end of the file is reached.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
