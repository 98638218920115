import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Testimonials = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [testimonials] = useState([
        {
            quote: "CodingCanvas has transformed my learning journey. The visualizations make even the hardest concepts easy to understand!",
            author: "Rohit",
            designation: "Computer Science Student",
            avatar: "/avatars/avataaars_5.png",
            rating: 5,
        },
        {
            quote: "Thanks to CodingCanvas, I landed my dream job as a developer! The interactive approach is a game-changer.",
            author: "Priya",
            designation: "Software Developer",
            avatar: "/avatars/avataaars_0.png",
            rating: 5,
        },
        {
            quote: "The affordable pricing and quality content make CodingCanvas a must-have for anyone learning programming.",
            author: "Arjun",
            designation: "Coding Enthusiast",
            avatar: "/avatars/avataaars_4.png",
            rating: 5,
        },
        {
            quote: "I love the interactive tutorials and real-world examples. It feels like having a personal coding mentor.",
            author: "Divya",
            designation: "Web Developer",
            avatar: "/avatars/avataaars_7.png",
            rating: 5,
        }
    ]);

    const [totalUsers, setTotalUsers] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTotalUsers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/testimonial/`);
                setTotalUsers(response.data.total_users);
            } catch (error) {
                console.error('Failed to fetch total users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTotalUsers();
    }, []);

    const groupedTestimonials = [];
    const slideSize = 3;
    testimonials.forEach((testimonial, index) => {
        const currentSlideIndex = Math.floor(index / slideSize);

        if (!groupedTestimonials[currentSlideIndex]) {
            groupedTestimonials[currentSlideIndex] = [];
        }

        groupedTestimonials[currentSlideIndex].push(testimonial);

        if (
            index === testimonials.length - 1 &&
            groupedTestimonials[currentSlideIndex].length < slideSize
        ) {
            const previousSlide = groupedTestimonials[currentSlideIndex - 1] || [];
            const neededTestimonials = slideSize - groupedTestimonials[currentSlideIndex].length;

            groupedTestimonials[currentSlideIndex].unshift(
                ...previousSlide.slice(-neededTestimonials)
            );
        }
    });

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const stars = [];
        for (let i = 0; i < fullStars; i++) {
            stars.push(<i key={`star-${i}`} className="fas fa-star text-dark"></i>);
        }
        while (stars.length < 5) {
            stars.push(<i key={`star-empty-${stars.length}`} className="far fa-star text-dark"></i>);
        }
        return stars;
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid">
            <h2 className="text-center mb-4">What Our Users Are Saying</h2>
            <div className="text-center">
                <p className="text-muted">
                    Trusted by <strong>{totalUsers || 0}</strong> users worldwide!
                </p>
            </div>
            <div id="testimonialsCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {groupedTestimonials.map((group, index) => (
                        <div
                            key={index}
                            className={`carousel-item ${index === 0 ? 'active' : ''}`}
                        >
                            <div className="row justify-content-center">
                                {group.map((testimonial, idx) => (
                                    <div
                                        key={idx}
                                        className="col-md-4 d-flex align-items-stretch mb-4"
                                    >
                                        <div className="card text-center p-3" style={{ borderRadius: '10px', height: '280px' }}>
                                            <div className="mx-auto mb-2">
                                                <img
                                                    src={testimonial.avatar}
                                                    alt={`${testimonial.author}'s avatar`}
                                                    className="rounded-circle"
                                                    style={{
                                                        width: '70px',
                                                        height: '70px',
                                                        border: '2px solid #6c757d',
                                                    }}
                                                />
                                            </div>
                                            <div className="mb-2">{renderStars(testimonial.rating)}</div>
                                            <div
                                                className="text-muted mb-6"
                                                style={{ fontSize: '14px' }}
                                            >
                                                "{testimonial.quote}"
                                            </div>
                                            <footer
                                                className="blockquote-footer mt-auto"
                                                style={{ position: 'absolute', bottom: '0', left: '0', right: '0' }}
                                            >
                                                <strong>{testimonial.author}</strong>, {testimonial.designation}
                                            </footer>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="carousel-indicators justify-content-center">
                    {groupedTestimonials.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#testimonialsCarousel"
                            data-bs-slide-to={index}
                            className={`dot ${index === 0 ? 'active' : ''}`}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
