import { useState, useEffect, useRef } from 'react';
import { quickSortPython, quickSortJava, quickSortC, quickSortCpp } from './quickSortAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useQuickSort = (language, data, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newData) => {
    resetState();

    let lastElement = newData.pop();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/data = \[.*?\]/, `data = [${newData.join(", ")}, sys.maxsize]`)
      );
    } else if (language === 'Java') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int data\[\] = \{.*?\};/,
            `int data[] = {${newData.join(", ")}, Integer.MAX_VALUE};`
          )
      );
    } else if (language === 'C++' || language === 'C') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int data\[\] = \{.*?\};/,
            `int data[] = {${newData.join(", ")}, INT_MAX};`
          )
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int data\[\] = \{.*?\};/,
            `int data[] = {${newData.join(", ")}};`
          )
      );
    }

    newData.push(lastElement);
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
    await customSleep(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runQuickSortPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(27);
    setArrayVariables({ data: { variable_name: 'data', value: data } });
    setHighlightedArrayVariables(['data']);
    await customSleep(27);
    setHighlightedArrayVariables([]);

    await highlightLine(28);
    let low = 0;
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
    setHighlightedVariables(['low']);
    await customSleep(28);
    setHighlightedVariables([]);

    await highlightLine(29);
    let high = data.length - 1;
    setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['high']);
    await customSleep(29);
    setHighlightedVariables([]);

    await highlightLine(30);
    await customSleep(30);

    await highlightCallingLine(30);
    let sorted_data = await quickSortPython(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    setArrayVariables((vars) => ({ ...vars, sorted_data: { variable_name: 'sorted_data', value: sorted_data } }));
    setHighlightedArrayVariables(['sorted_data']);
    await highlightLine(30);
    await customSleep(30);
    setHighlightedArrayVariables([]);

    await highlightLine(15);
    await logMessage(`Sorted Data: ${sorted_data}`);
    await customSleep(15);

    await focusEndLine(15);
    setIsRunning(false);
  };

  const runQuickSortJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(34);
    setArrayVariables({ data: { variable_name: 'data', value: data } });
    setHighlightedArrayVariables(['data']);
    await customSleep(34);
    setHighlightedArrayVariables([]);

    await highlightLine(35);
    let low = 0;
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
    setHighlightedVariables(['low']);
    await customSleep(35);
    setHighlightedVariables([]);

    await highlightLine(36);
    let high = data.length - 1;
    setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['high']);
    await customSleep(36);
    setHighlightedVariables([]);

    await highlightLine(37);
    await customSleep(37);

    await highlightLine(38);
    await customSleep(38);

    await highlightCallingLine(38);
    let sortedData = await quickSortJava(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedData: { variable_name: 'sortedData', value: sortedData } }));
    setHighlightedArrayVariables(['sortedData']);
    await highlightLine(38);
    await customSleep(38);
    setHighlightedArrayVariables([]);

    await highlightLine(39);
    await logMessage(`Sorted list: ${sortedData}`);
    await customSleep(39);

    await focusEndLine(41);
    setIsRunning(false);
  };

  const runQuickSortC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(34);
    setArrayVariables({ data: { variable_name: 'data', value: data } });
    setHighlightedArrayVariables(['data']);
    await customSleep(34);
    setHighlightedArrayVariables([]);

    await highlightLine(35);
    let low = 0;
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
    setHighlightedVariables(['low']);
    await customSleep(35);
    setHighlightedVariables([]);

    await highlightLine(36);
    let high = data.length - 1;
    setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['high']);
    await customSleep(36);
    setHighlightedVariables([]);


    await highlightLine(37);
    await customSleep(37);

    await highlightCallingLine(37);
    let sortedData = await quickSortC(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedData: { variable_name: 'sortedData', value: sortedData } }));
    setHighlightedArrayVariables(['sortedData']);
    await highlightLine(37);
    await customSleep(37);
    setHighlightedArrayVariables([]);

    await highlightLine(38);
    await logMessage(`Sorted list: `);
    await customSleep(38);

    for(let itr = 0; itr < sortedData.length; itr++) {
      await highlightLine(39);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr']);
      await customSleep(39);
      setHighlightedVariables([]);

      await highlightLine(40);
      setHighlightedIndices([{ arrayName: 'sortedData', index: itr, iteratorName: 'itr' }]);
      await logMessage(sortedData[itr]);
      await customSleep(40);
      setHighlightedIndices([]);
    }

    await highlightLine(42);
    await customSleep(42);

    await focusEndLine(43);
    setIsRunning(false);
  };

  const runQuickSortCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(35);
    setArrayVariables({ data: { variable_name: 'data', value: data } });
    setHighlightedArrayVariables(['data']);
    await customSleep(35);
    setHighlightedArrayVariables([]);

    await highlightLine(36);
    let low = 0;
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
    setHighlightedVariables(['low']);
    await customSleep(36);
    setHighlightedVariables([]);

    await highlightLine(37);
    let high = data.length - 1;
    setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['high']);
    await customSleep(37);
    setHighlightedVariables([]);


    await highlightLine(38);
    await customSleep(38);

    await highlightCallingLine(38);
    let sortedData = await quickSortCpp(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedData: { variable_name: 'sortedData', value: sortedData } }));
    setHighlightedArrayVariables(['sortedData']);
    await highlightLine(38);
    await customSleep(38);
    setHighlightedArrayVariables([]);

    await highlightLine(39);
    await logMessage(`Sorted list: `);
    await customSleep(39);

    for(let itr = 0; itr < sortedData.length; itr++) {
      await highlightLine(40);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr']);
      await customSleep(40);
      setHighlightedVariables([]);

      await highlightLine(41);
      setHighlightedIndices([{ arrayName: 'sortedData', index: itr, iteratorName: 'itr' }]);
      await logMessage(sortedData[itr]);
      await customSleep(41);
      setHighlightedIndices([]);
    }

    await highlightLine(43);
    await customSleep(43);

    await focusEndLine(44);
    setIsRunning(false);
  };

  const runQuickSort = async () => {
    if (language === 'Python') {
      await runQuickSortPython();
    } else if (language === 'Java') {
      await runQuickSortJava();
    } else if (language === 'C') {
      await runQuickSortC();
    } else if (language === 'C++') {
      await runQuickSortCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runQuickSort
  };
};
