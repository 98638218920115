import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program calculates the <b>sum of all elements</b> in an array. It iterates through the 
        array using a for loop, adding each element to a variable total, which stores the cumulative 
        sum. Finally, the total is printed.
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>E-commerce Applications:</b> Calculating the total cost of items in a shopping cart.</li>
            <li><b>Statistics:</b> Summing data points for calculating averages or totals.</li>
            <li><b>Inventory Management:</b> Determining the total stock of items in a warehouse.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
