import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useNumericTypes = (language, x, y, z, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newX, newY, newZ) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/x = \d+/, `x = ${newX}`)
        .replace(/y\s*=\s*[\d.]+/g, `y = ${newY}`)
        .replace(/z\s*=\s*\d+\s*\+\s*\d+j/g, `z = ${newZ}`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations['Python'][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runNumericTypesPython = async () => {
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, x: { variable_name: 'x', value: x } }));
    setHighlightedVariables(['x']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, y: { variable_name: 'y', value: y } }));
    setHighlightedVariables(['y']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, z: { variable_name: 'z', value: z } }));
    setHighlightedVariables(['z']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(7);
    setHighlightedVariables(['x']);
    await logMessage(`x (Integer): ${x} | Type: <class 'int'>`);
    await customSleep(7);
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['y']);
    await logMessage(`y (Float): ${y} | Type: <class 'float'>`);
    await customSleep(8);
    setHighlightedVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['z']);
    await logMessage(`z (Complex): (${z}) | Type: <class 'complex'>`);
    await customSleep(9);
    setHighlightedVariables([]);

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runNumericTypes = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await runNumericTypesPython();

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    runNumericTypes
  };
};
