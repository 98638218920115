import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates how to display the contents of a <b>Singly Linked List</b>. 
        The display method traverses the list, printing the data of each node until the 
        end of the list. If the list is empty, it outputs a message indicating that.
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Debugging Data Structures:</b> Displaying a linked list helps in debugging by visually inspecting the sequence and structure of the data stored.</li>
            <li><b>Real-Time Data Visualizations:</b> Linked lists are used to display real-time data, such as active users in a chat application or open tabs in a browser, showing them in sequential order.</li>
            <li><b>Print Queue Management:</b> Linked lists in a print queue can display pending jobs, showing the order in which documents will be printed.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
