import React, { useState, useEffect } from 'react';

const RotateScreenModal = () => {
  const [showRotateModal, setShowRotateModal] = useState(false);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.innerHeight > window.innerWidth) {
        // Portrait mode on a mobile device
        setShowRotateModal(true);
      } else {
        setShowRotateModal(false);
      }
    };

    // Check on load
    handleOrientationChange();

    // Add event listener
    window.addEventListener('resize', handleOrientationChange);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleOrientationChange);
  }, []);

  if (!showRotateModal) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-avatar">Rotate Your Screen</h5>
          </div>
          <div className="modal-body">
            <p>
              Please rotate your device to landscape mode for the best experience.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn user-progress-close-btn"
              onClick={() => setShowRotateModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RotateScreenModal;
