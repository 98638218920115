import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useForLoop = (language, numbers, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  
  
  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newNumbers) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/numbers = \[.*?\]/, `numbers = [${newNumbers.join(", ")}]`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int numbers\[\] = \{.*?\};/,
            `int numbers[] = {${newNumbers.join(", ")}};`
          )
      );
    }
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setArrayVariables({});
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runForLoopPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    setArrayVariables({ numbers: { variable_name: 'numbers', value: numbers } });
    setHighlightedArrayVariables(['numbers']);
    await customSleep(1);
    setHighlightedArrayVariables([]);

    if(!(numbers.length > 0)) {
      await highlightLine(3);
      await customSleep(3);
    }
    
    let i = 0;
    for (let itr = 0; itr < numbers.length; itr++) {
      await highlightLine(3);
      setHighlightedIndices([{ arrayName: 'numbers', index: itr}]);
      i = numbers[itr];
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(3);
      setHighlightedVariables([]);

      await highlightLine(4);
      setHighlightedVariables(['i']);
      await customSleep(4);
      if (i % 3 === 0 && i % 5 === 0) {
        await highlightLine(5);
        await logMessage(`FizzBuzz`);
        await customSleep(5);
      } else if (i % 3 === 0) {
        await highlightLine(6);
        await customSleep(6);

        await highlightLine(7);
        await logMessage(`Fizz`);
        await customSleep(7);
      } else if (i % 5 === 0) {
        await highlightLine(6);
        await customSleep(6);

        await highlightLine(8);
        await customSleep(8);

        await highlightLine(9);
        await logMessage(`Buzz`);
        await customSleep(9);
      } else {
        await highlightLine(6);
        await customSleep(6);

        await highlightLine(8);
        await customSleep(8);

        await highlightLine(10);
        await customSleep(10);

        await highlightLine(11);
        await logMessage(`${i}`);
        await customSleep(11);
      }
      setHighlightedVariables([]);
    }

    setHighlightedIndices([]);

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runForLoopJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setArrayVariables({ numbers: { variable_name: 'numbers', value: numbers } });
    setHighlightedArrayVariables(['numbers']);
    await customSleep(2);
    setHighlightedArrayVariables([]);

    if(!(numbers.length > 0)) {
      await highlightLine(4);
      await customSleep(4);
    }
    
    let i = 0;
    for (let itr = 0; itr < numbers.length; itr++) {
      await highlightLine(4);
      setHighlightedIndices([{ arrayName: 'numbers', index: itr}]);
      i = numbers[itr];
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(4);
      setHighlightedVariables([]);

      await highlightLine(5);
      setHighlightedVariables(['i']);
      await customSleep(5);
      if (i % 3 === 0 && i % 5 === 0) {
        await highlightLine(6);
        await logMessage(`FizzBuzz`);
        await customSleep(6);
      } else if (i % 3 === 0) {
        await highlightLine(7);
        await customSleep(7);

        await highlightLine(8);
        await logMessage(`Fizz`);
        await customSleep(8);
      } else if (i % 5 === 0) {
        await highlightLine(7);
        await customSleep(7);

        await highlightLine(9);
        await customSleep(9);

        await highlightLine(10);
        await logMessage(`Buzz`);
        await customSleep(10);
      } else {
        await highlightLine(7);
        await customSleep(7);

        await highlightLine(9);
        await customSleep(9);

        await highlightLine(11);
        await customSleep(11);

        await highlightLine(12);
        await logMessage(`${i}`);
        await customSleep(12);
      }
      setHighlightedVariables([]);
    }

    await focusEndLine(16);
    setIsRunning(false);
  };

  const runForLoopC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setArrayVariables({ numbers: { variable_name: 'numbers', value: numbers } });
    setHighlightedArrayVariables(['numbers']);
    await customSleep(2);
    setHighlightedArrayVariables([]);

    await highlightLine(3);
    let size = numbers.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep(3);
    setHighlightedVariables([]);

    for (let i = 0; i < numbers.length; i++) {
      await highlightLine(5);
      setHighlightedIndices([{ arrayName: 'numbers', index: i, iteratorName: 'i'}]);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(5);
      setHighlightedVariables([]);

      await highlightLine(6);
      setHighlightedVariables(['i']);
      await customSleep(6);
      if (numbers[i] % 3 === 0 && numbers[i] % 5 === 0) {
        await highlightLine(7);
        await logMessage(`FizzBuzz`);
        await customSleep(7);
      } else if (numbers[i] % 3 === 0) {
        await highlightLine(8);
        await customSleep(8);

        await highlightLine(9);
        await logMessage(`Fizz`);
        await customSleep(9);
      } else if (numbers[i] % 5 === 0) {
        await highlightLine(8);
        await customSleep(8);

        await highlightLine(10);
        await customSleep(10);

        await highlightLine(11);
        await logMessage(`Buzz`);
        await customSleep(11);
      } else {
        await highlightLine(8);
        await customSleep(8);

        await highlightLine(10);
        await customSleep(10);

        await highlightLine(12);
        await customSleep(12);

        await highlightLine(13);
        await logMessage(`${numbers[i]}`);
        await customSleep(13);
      }
      setHighlightedVariables([]);
    }
    setHighlightedIndices([]);

    await highlightLine(16);
    await customSleep(16);

    await focusEndLine(17);
    setIsRunning(false);
  };

  const runForLoopCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setArrayVariables({ numbers: { variable_name: 'numbers', value: numbers } });
    setHighlightedArrayVariables(['numbers']);
    await customSleep(3);
    setHighlightedArrayVariables([]);

    await highlightLine(4);
    let size = numbers.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep(4);
    setHighlightedVariables([]);

    for (let i = 0; i < numbers.length; i++) {
      await highlightLine(6);
      setHighlightedIndices([{ arrayName: 'numbers', index: i, iteratorName: 'i'}]);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(6);
      setHighlightedVariables([]);

      await highlightLine(7);
      setHighlightedVariables(['i']);
      await customSleep(7);
      if (numbers[i] % 3 === 0 && numbers[i] % 5 === 0) {
        await highlightLine(8);
        await logMessage(`FizzBuzz`);
        await customSleep(8);
      } else if (numbers[i] % 3 === 0) {
        await highlightLine(9);
        await customSleep(9);

        await highlightLine(10);
        await logMessage(`Fizz`);
        await customSleep(10);
      } else if (numbers[i] % 5 === 0) {
        await highlightLine(9);
        await customSleep(9);

        await highlightLine(11);
        await customSleep(11);

        await highlightLine(12);
        await logMessage(`Buzz`);
        await customSleep(12);
      } else {
        await highlightLine(9);
        await customSleep(9);

        await highlightLine(11);
        await customSleep(11);

        await highlightLine(13);
        await customSleep(13);

        await highlightLine(14);
        await logMessage(`${numbers[i]}`);
        await customSleep(14);
      }
      setHighlightedVariables([]);
    }
    setHighlightedIndices([]);

    await highlightLine(17);
    await customSleep(17);

    await focusEndLine(18);
    setIsRunning(false);
  };

  const runForLoop = async () => {
    if (language === 'Python') {
      await runForLoopPython();
    } else if (language === 'Java') {
      await runForLoopJava();
    } else if (language === 'C') {
      await runForLoopC();
    } else if (language === 'C++') {
      await runForLoopCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runForLoop
  };
};
