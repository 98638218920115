// Helper function to generate memory addresses (for visualization)
function generateMemoryAddress() {
    return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
}

class BinaryTreeNode {
    constructor(data, address) {
      this.data = data;
      this.left = null;
      this.right = null;
      this.address = address;
    }
}
  
class BinaryTreeForPython {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'None', child: newNode.address }
            }));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;

            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'None'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));

                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'None', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };
  
    // Inorder Traversal
    inorderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {
        await highlightLine(4);
        await customSleep(4);
  
        await highlightLine(5);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep(5);
        setHighlightedRootVariables([]);

        await highlightLine(6);
        setHighlightedRootVariables(['current']);
        await customSleep(6);
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(7);
            await customSleep(7);
            return;
        }

        await highlightLine(9);
        let stack = [];
        let _stack = [];
        setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
        setHighlightedStackVariables(['stack']);
        await customSleep(9);
        setHighlightedStackVariables([]);

        while (current !== null || stack.length > 0) {
            await highlightLine(10);
            setHighlightedRootVariables(['current']);
            setHighlightedStackVariables(['stack']);
            await customSleep(10);
            setHighlightedRootVariables([]);
            setHighlightedStackVariables([]);

            if (!(current !== null)) {
                await highlightLine(11);
                setHighlightedRootVariables(['current']);
                await customSleep(11);
                setHighlightedRootVariables([]);
            }
            while (current !== null) {
                await highlightLine(11);
                setHighlightedRootVariables(['current']);
                await customSleep(11);
                setHighlightedRootVariables([]);

                await highlightLine(12);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                _stack.push("");
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                setHighlightedStackIndex([{ stackName: 'stack', index: _stack.length - 1 }]);
                await customSleep(null, 1200);
                stack.push(current);
                _stack[_stack.length - 1] = current.address;
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                await customSleep(12);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightNodeAddress([]);
                
                await highlightLine(13);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(null, 1200);
                setHighlightedRootVariables(['current']);
                await customSleep(null, 1200);
                current = current.left;
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'None'}
                }));
                await customSleep(13);
                setHighlightedRootVariables([]);
                setHighlightLeftPart([]);

                if (!(current !== null)) {
                    await highlightLine(11);
                    setHighlightedRootVariables(['current']);
                    await customSleep(11);
                    setHighlightedRootVariables([]);
                }
            }
    
            await highlightLine(14);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack', index: _stack.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(null, 1200);
            current = stack.pop();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            _stack.pop();
            setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(14);
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(15);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(15);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            await highlightLine(16);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            current = current.right;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            await customSleep(16);
            setHighlightedRootVariables([]);
            setHighlightRightPart([]);

            if (!((current !== null || stack.length > 0))) {
                await highlightLine(10);
                await customSleep(10);
            }
        }

        // Delete current from rootVariables
        setRootVariables(() => ({
            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
        }));
        // Delete stack variable
        setStackVariables({});
    };
}

export const binaryTreePython = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForPython();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreeInorderTraversalPython = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.inorderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}


class BinaryTreeForJava {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'null', child: newNode.address }
            }));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;

            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'null'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'null'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));

                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'null', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };
  
    // Inorder Traversal
    inorderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {
        await highlightLine(9);
        await customSleep(9);
  
        await highlightLine(10);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep(10);
        setHighlightedRootVariables([]);

        await highlightLine(11);
        setHighlightedRootVariables(['current']);
        await customSleep(11);
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(12);
            await customSleep(12);
            return;
        }

        await highlightLine(15);
        let stack = [];
        let _stack = [];
        setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
        setHighlightedStackVariables(['stack']);
        await customSleep(15);
        setHighlightedStackVariables([]);

        while (current !== null || stack.length > 0) {
            await highlightLine(16);
            setHighlightedRootVariables(['current']);
            setHighlightedStackVariables(['stack']);
            await customSleep(16);
            setHighlightedRootVariables([]);
            setHighlightedStackVariables([]);

            if (!(current !== null)) {
                await highlightLine(17);
                setHighlightedRootVariables(['current']);
                await customSleep(17);
                setHighlightedRootVariables([]);
            }
            while (current !== null) {
                await highlightLine(17);
                setHighlightedRootVariables(['current']);
                await customSleep(17);
                setHighlightedRootVariables([]);

                await highlightLine(18);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                _stack.push("");
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                setHighlightedStackIndex([{ stackName: 'stack', index: _stack.length - 1 }]);
                await customSleep(null, 1200);
                stack.push(current);
                _stack[_stack.length - 1] = current.address;
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                await customSleep(18);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightNodeAddress([]);
                
                await highlightLine(19);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(null, 1200);
                setHighlightedRootVariables(['current']);
                await customSleep(null, 1200);
                current = current.left;
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'null'}
                }));
                await customSleep(19);
                setHighlightedRootVariables([]);
                setHighlightLeftPart([]);

                if (!(current !== null)) {
                    await highlightLine(17);
                    setHighlightedRootVariables(['current']);
                    await customSleep(17);
                    setHighlightedRootVariables([]);
                }
            }
    
            await highlightLine(21);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack', index: _stack.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(null, 1200);
            current = stack.pop();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'null'}
            }));
            _stack.pop();
            setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(21);
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(22);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(22);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            await highlightLine(23);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            current = current.right;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'null'}
            }));
            await customSleep(23);
            setHighlightedRootVariables([]);
            setHighlightRightPart([]);

            if (!((current !== null || stack.length > 0))) {
                await highlightLine(16);
                await customSleep(16);
            }
        }

        // Delete current from rootVariables
        setRootVariables(() => ({
            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
        }));
        // Delete stack variable
        setStackVariables({});
    };
}

export const binaryTreeJava = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForJava();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreeInorderTraversalJava = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.inorderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}


class BinaryTreeForCpp {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'nullptr' }
            }));
            
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'nullptr'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'nullptr', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };


    // Inorder Traversal
    inorderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {
        await highlightLine(12);
        await customSleep(12);
  
        await highlightLine(13);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep(13);
        setHighlightedRootVariables([]);

        await highlightLine(14);
        setHighlightedRootVariables(['current']);
        await customSleep(14);
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(15);
            await customSleep(15);
            return;
        }

        await highlightLine(18);
        let stack = [];
        let _stack = [];
        setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
        setHighlightedStackVariables(['stack']);
        await customSleep(18);
        setHighlightedStackVariables([]);

        while (current !== null || stack.length > 0) {
            await highlightLine(19);
            setHighlightedRootVariables(['current']);
            setHighlightedStackVariables(['stack']);
            await customSleep(19);
            setHighlightedRootVariables([]);
            setHighlightedStackVariables([]);

            if (!(current !== null)) {
                await highlightLine(20);
                setHighlightedRootVariables(['current']);
                await customSleep(20);
                setHighlightedRootVariables([]);
            }
            while (current !== null) {
                await highlightLine(20);
                setHighlightedRootVariables(['current']);
                await customSleep(20);
                setHighlightedRootVariables([]);

                await highlightLine(21);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                _stack.push("");
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                setHighlightedStackIndex([{ stackName: 'stack', index: _stack.length - 1 }]);
                await customSleep(null, 1200);
                stack.push(current);
                _stack[_stack.length - 1] = current.address;
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                await customSleep(21);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightNodeAddress([]);
                
                await highlightLine(22);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(null, 1200);
                setHighlightedRootVariables(['current']);
                await customSleep(null, 1200);
                current = current.left;
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
                }));
                await customSleep(22);
                setHighlightedRootVariables([]);
                setHighlightLeftPart([]);

                if (!(current !== null)) {
                    await highlightLine(20);
                    setHighlightedRootVariables(['current']);
                    await customSleep(20);
                    setHighlightedRootVariables([]);
                }
            }
    
            await highlightLine(24);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack', index: _stack.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(null, 1200);
            current = stack[stack.length - 1];
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(24);
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(25);
            setHighlightedStackIndex([{ stackName: 'stack', index: _stack.length - 1 }]);
            await customSleep(null, 1200);
            stack.pop();
            _stack.pop();
            setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(25);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(26);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(26);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            await highlightLine(27);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            current = current.right;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));
            await customSleep(27);
            setHighlightedRootVariables([]);
            setHighlightRightPart([]);

            if (!((current !== null || stack.length > 0))) {
                await highlightLine(19);
                await customSleep(19);
            }
        }

        // Delete current from rootVariables
        setRootVariables(() => ({
            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
        }));
        // Delete stack variable
        setStackVariables({});
    };

}

export const binaryTreeCpp = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForCpp();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreeInorderTraversalCpp = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.inorderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}

class BinaryTreeForC {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'NULL' }
            }));
            
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'NULL'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'NULL', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

    // Inorder Traversal
    inorderTraversal = async (
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        logMessage,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    ) => {
        await highlightLine(9);
        await customSleep(9);

        await highlightLine(10);
        let stack = [];
        let _stack = [];
        setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
        setHighlightedStackVariables(['stack']);
        await customSleep(10);
        setHighlightedStackVariables([]);

        await highlightLine(11);
        let top = -1;
        setVariables((vars) => ({ ...vars, 
            top: { variable_name: 'top', value: top }
        }));
        setHighlightedVariables(['top']);
        await customSleep(11);
        setHighlightedVariables([]);
  
        await highlightLine(12);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep(12);
        setHighlightedRootVariables([]);

        while (current !== null || top >= 0) {
            await highlightLine(14);
            setHighlightedRootVariables(['current']);
            setHighlightedStackVariables(['top']);
            await customSleep(14);
            setHighlightedRootVariables([]);
            setHighlightedStackVariables([]);

            if (!(current !== null)) {
                await highlightLine(15);
                setHighlightedRootVariables(['current']);
                await customSleep(15);
                setHighlightedRootVariables([]);
            }
            while (current !== null) {
                await highlightLine(15);
                setHighlightedRootVariables(['current']);
                await customSleep(15);
                setHighlightedRootVariables([]);

                await highlightLine(16);
                setHighlightedVariables(['top']);
                await customSleep(null, 1200);
                top += 1;
                setVariables((vars) => ({ ...vars, 
                    top: { variable_name: 'top', value: top }
                }));
                await customSleep(null, 1200);
                setHighlightedVariables([]);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                _stack[top] = " ";
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                setStrikeThroughStackIndex((prevState) =>
                    prevState.filter(item => item.stackName !== 'stack' || item.index !== top)
                );
                setHighlightedStackIndex([{ stackName: 'stack', index: top }]);
                await customSleep(null, 1200);
                stack[top] = current;
                _stack[top] = current.address;
                setStackVariables({ stack: { variable_name: 'stack', value: _stack } });
                await customSleep(16);
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightNodeAddress([]);
                
                await highlightLine(17);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(null, 1200);
                setHighlightedRootVariables(['current']);
                await customSleep(null, 1200);
                current = current.left;
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'NULL'}
                }));
                await customSleep(17);
                setHighlightedRootVariables([]);
                setHighlightLeftPart([]);

                if (!(current !== null)) {
                    await highlightLine(15);
                    setHighlightedRootVariables(['current']);
                    await customSleep(15);
                    setHighlightedRootVariables([]);
                }
            }

            await highlightLine(19);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack', index: top }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(null, 1200);
            current = stack[top];
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            setHighlightedVariables(['top']);
            await customSleep(null, 1200);
            setHighlightedStackIndex([]);
            let strikeIndex = top;
            setStrikeThroughStackIndex((prevState) => [
                ...prevState, 
                { stackName: 'stack', index: strikeIndex }
            ]);
            top -= 1;
            setVariables((vars) => ({ ...vars, 
                top: { variable_name: 'top', value: top }
            }));
            await customSleep(19);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);
            setHighlightedVariables([]);

            await highlightLine(20);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep(20);
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            await highlightLine(21);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            current = current.right;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));
            await customSleep(21);
            setHighlightedRootVariables([]);
            setHighlightRightPart([]);

            if (!((current !== null || top >= 0))) {
                await highlightLine(14);
                setHighlightedRootVariables(['current']);
                setHighlightedStackVariables(['top']);
                await customSleep(14);
                setHighlightedRootVariables([]);
                setHighlightedStackVariables([]);
            }
        }

        // Delete current from rootVariables
        setRootVariables(() => ({
            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
        }));
        // remove stack variable
        setStackVariables({});
        // remove variables
        setVariables({});
    };

}

export const binaryTreeC = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
  ) => {
    let binaryTree = new BinaryTreeForC();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    return binaryTree;
};

export const binaryTreeInorderTraversalC = async (
    globalBinaryTree,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    logMessage,
    highlightLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    await globalBinaryTree.inorderTraversal(
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        logMessage,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
}
