import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);

    // Set an SEO-friendly page title
    document.title = 'Privacy Policy | Coding Canvas';

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Learn about how CodingCanvas collects, uses, and protects your data through our comprehensive privacy policy.'
      );
    }
  }, []);

  return (
    <main className="container">
      <section className="text-center mb-5">
        <h1 className="mb-3">Privacy Policy</h1>
        <p>Your privacy is important to us. Please review this policy to understand how we handle your data.</p>
      </section>

      <section className="mb-4">
        <h3>1. Information We Collect</h3>
        <ul style={{ paddingLeft: '30px' }}>
          <li>
            <strong>Personal Information:</strong> Includes name, email, and payment details provided during registration or subscription.
          </li>
          <li>
            <strong>Usage Data:</strong> Includes IP address, browser type, and interactions with the platform.
          </li>
          <li>
            <strong>Cookies:</strong> We use cookies to enhance your experience and analyze platform usage.
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>2. How We Use Your Information</h3>
        <ul style={{ paddingLeft: '30px' }}>
          <li>
            <strong>Providing Services:</strong> To create and manage your account, provide course access, and process transactions.
          </li>
          <li>
            <strong>Improving the Platform:</strong> To analyze usage patterns and improve our offerings.
          </li>
          <li>
            <strong>Customer Support:</strong> To respond to your inquiries and support requests.
          </li>
          <li>
            <strong>Marketing and Communication:</strong> To send newsletters, promotions, and updates.
          </li>
          <li>
            <strong>Legal Compliance:</strong> To comply with applicable laws and enforce our Terms and Conditions.
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>3. Data Sharing</h3>
        <p style={{paddingLeft: '15px'}}>
          We do not sell or share your personal data.
        </p>
      </section>

      <section className="mb-4">
        <h3>4. Cookies and Tracking Technologies</h3>
        <p style={{paddingLeft: '15px'}}>
          We use cookies to personalize your experience and analyze usage. You can manage your cookie preferences through your browser settings.
        </p>
      </section>

      <section className="mb-4">
        <h3>5. Your Rights</h3>
        <ul style={{ paddingLeft: '30px' }}>
          <li>Access your personal data.</li>
          <li>Request corrections to your data.</li>
          <li>Request deletion of your data, subject to legal requirements.</li>
          <li>Opt-out of marketing communications.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>6. Data Security</h3>
        <p style={{paddingLeft: '15px'}}>
          We implement industry-standard security measures to protect your data. However, no system is entirely secure, and you use the platform at your own risk.
        </p>
      </section>

      <section className="mb-4">
        <h3>7. Data Retention</h3>
        <p style={{paddingLeft: '15px'}}>
          Your data is retained as long as necessary to provide services or comply with legal obligations.
        </p>
      </section>

      <section className="mb-4">
        <h3>8. Third-Party Links</h3>
        <p style={{paddingLeft: '15px'}}>
          Our platform may contain links to third-party websites. We are not responsible for their content or privacy practices.
        </p>
      </section>

      <section className="mb-4">
        <h3>9. Changes to This Policy</h3>
        <p style={{paddingLeft: '15px'}}>
          We may update this policy from time to time. Significant changes will be notified through the platform or via email.
        </p>
      </section>

      <section className="mb-4">
        <h3>10. Contact Us</h3>
        <p style={{paddingLeft: '15px'}}>
          If you have any questions or concerns about this Privacy Policy, please contact us at{' '}
          <a href="mailto:support@codingcanvas.io">support@codingcanvas.io</a>.
        </p>
      </section>
    </main>
  );
};

export default PrivacyPolicy;
