import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        Demonstrates the use of the <b>float data type</b> to represent and display fractional numbers. 
        It declares variables for Pi, average human body temperature, and gravitational acceleration, 
        then prints their values to illustrate real-world usage of floating-point numbers.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Scientific Computations:</b> Represent physical constants and scientific measurements.</li>
            <li><b>Educational Tools:</b> Teach floating-point data types and their precision limitations.</li>
            <li><b>Simulations:</b> Model real-world systems like physics simulations or engineering calculations.</li>
            <li><b>User Interfaces:</b> Display numeric values with real-world relevance in applications.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
