export const quickSortPython = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep) => {
  await highlightLine(20);
  setHighlightedVariables(['low', 'high']);
  await customSleep(20);
  setHighlightedVariables([]);
  if (low < high) {
    await highlightLine(21);
    await customSleep(21);
    await highlightCallingLine(21);
    let j = await partitionPython(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
    await highlightLine(21);
    await customSleep(21);

    await highlightLine(22);
    await customSleep(22);
    await highlightCallingLine(22);
    await quickSortPython(data, low, j, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(22);
    await customSleep(22);

    await highlightLine(23);
    await customSleep(23);
    await highlightCallingLine(23);
    await quickSortPython(data, j + 1, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(23);
    await customSleep(23);
  }

  await highlightLine(24);
  await customSleep(24);
  return data;
};

const partitionPython = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  await highlightLine(2);
  let pivot = data[low];
  setVariables((vars) => ({ ...vars, pivot: { variable_name: 'pivot', value: pivot } }));
  setHighlightedVariables(['pivot']);
  await customSleep(2);
  setHighlightedVariables([]);

  await highlightLine(3);
  let i = low;
  setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
  setHighlightedVariables(['i', 'low']);
  await customSleep(3);
  setHighlightedVariables([]);

  await highlightLine(4);
  let j = high;
  setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
  setHighlightedVariables(['j', 'high']);
  await customSleep(4);
  setHighlightedVariables([]);

  while (i < j) {
    await highlightLine(5);
    setHighlightedVariables(['i', 'j']);
    await customSleep(5);
    setHighlightedVariables([]);

    while (pivot >= data[i]) {
      await highlightLine(6);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      await customSleep(6);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(7);
      i += 1;
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(7);
      setHighlightedVariables([]);
    }
    while (pivot < data[j]) {
      await highlightLine(8);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      await customSleep(8);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(9);
      j -= 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
      setHighlightedVariables(['j']);
      await customSleep(9);
      setHighlightedVariables([]);
    }

    await highlightLine(10);
    setHighlightedVariables(['i', 'j']);
    await customSleep(10);
    setHighlightedVariables([]);
    if (i < j) {
      await highlightLine(11);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      let temp = data[i];
      setHighlightedVariables(['temp']);
      setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
      await customSleep(11);
      setHighlightedIndices([]);
      setHighlightedVariables([]);

      await highlightLine(12);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
      data[i] = data[j];
      await customSleep(12);
      setHighlightedIndices([]);

      await highlightLine(13);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      setHighlightedVariables(['temp']);
      data[j] = temp;
      await customSleep(13);
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  }
  
  await highlightLine(14);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }]);
  let temp = data[low];
  setHighlightedVariables(['temp']);
  setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
  await customSleep(14);
  setHighlightedIndices([]);
  setHighlightedVariables([]);

  await highlightLine(15);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
  data[low] = data[j];
  await customSleep(15);
  setHighlightedIndices([]);

  await highlightLine(16);
  setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
  setHighlightedVariables(['temp']);
  data[j] = temp;
  await customSleep(16);
  setHighlightedIndices([]);
  setHighlightedVariables([]);
  

  await highlightLine(16);
  setHighlightedVariables(['j']);
  await customSleep(16);
  setHighlightedVariables([]);
  return j;
};
  
export const quickSortJava = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep) => {
  await highlightLine(25);
  setHighlightedVariables(['low', 'high']);
  await customSleep(25);
  setHighlightedVariables([]);
  if (low < high) {
    await highlightLine(26);
    await customSleep(26);
    await highlightCallingLine(26);
    let j = await partitionJava(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
    await highlightLine(26);
    await customSleep(26);

    await highlightLine(27);
    await customSleep(27);
    await highlightCallingLine(27);
    await quickSortJava(data, low, j, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(27);
    await customSleep(27);

    await highlightLine(28);
    await customSleep(28);
    await highlightCallingLine(28);
    await quickSortJava(data, j + 1, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(28);
    await customSleep(28);
  }

  await highlightLine(30);
  await customSleep(30);
  return data;
};

const partitionJava = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  await highlightLine(2);
  let pivot = data[low];
  setVariables((vars) => ({ ...vars, pivot: { variable_name: 'pivot', value: pivot } }));
  setHighlightedVariables(['pivot']);
  await customSleep(2);
  setHighlightedVariables([]);

  await highlightLine(3);
  let i = low;
  setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
  setHighlightedVariables(['i', 'low']);
  await customSleep(3);
  setHighlightedVariables([]);

  await highlightLine(4);
  let j = high;
  setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
  setHighlightedVariables(['j', 'high']);
  await customSleep(4);
  setHighlightedVariables([]);

  while (i < j) {
    await highlightLine(5);
    setHighlightedVariables(['i', 'j']);
    await customSleep(5);
    setHighlightedVariables([]);

    while (pivot >= data[i]) {
      await highlightLine(6);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      await customSleep(6);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(7);
      i += 1;
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(7);
      setHighlightedVariables([]);
    }
    while (pivot < data[j]) {
      await highlightLine(9);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      await customSleep(9);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(10);
      j -= 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
      setHighlightedVariables(['j']);
      await customSleep(10);
      setHighlightedVariables([]);
    }

    await highlightLine(12);
    setHighlightedVariables(['i', 'j']);
    await customSleep(12);
    setHighlightedVariables([]);
    if (i < j) {
      await highlightLine(13);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      let temp = data[i];
      setHighlightedVariables(['temp']);
      setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
      await customSleep(13);
      setHighlightedIndices([]);
      setHighlightedVariables([]);

      await highlightLine(14);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
      data[i] = data[j];
      await customSleep(14);
      setHighlightedIndices([]);

      await highlightLine(15);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      setHighlightedVariables(['temp']);
      data[j] = temp;
      await customSleep(15);
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  }

  await highlightLine(18);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }]);
  let temp = data[low];
  setHighlightedVariables(['temp']);
  setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
  await customSleep(18);
  setHighlightedIndices([]);
  setHighlightedVariables([]);

  await highlightLine(19);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
  data[low] = data[j];
  await customSleep(19);
  setHighlightedIndices([]);

  await highlightLine(20);
  setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
  setHighlightedVariables(['temp']);
  data[j] = temp;
  await customSleep(20);
  setHighlightedIndices([]);
  setHighlightedVariables([]);
  

  await highlightLine(21);
  setHighlightedVariables(['j']);
  await customSleep(21);
  setHighlightedVariables([]);
  return j;
};

export const quickSortC = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep) => {
  await highlightLine(25);
  setHighlightedVariables(['low', 'high']);
  await customSleep(25);
  setHighlightedVariables([]);
  if (low < high) {
    await highlightLine(26);
    await customSleep(26);
    await highlightCallingLine(26);
    let j = await partitionC(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
    await highlightLine(26);
    await customSleep(26);

    await highlightLine(27);
    await customSleep(27);
    await highlightCallingLine(27);
    await quickSortCpp(data, low, j, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(27);
    await customSleep(27);

    await highlightLine(28);
    await customSleep(28);
    await highlightCallingLine(28);
    await quickSortCpp(data, j + 1, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(28);
    await customSleep(28);
  }

  await highlightLine(30);
  await customSleep(30);
  return data;
};

const partitionC = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  await highlightLine(2);
  let pivot = data[low];
  setVariables((vars) => ({ ...vars, pivot: { variable_name: 'pivot', value: pivot } }));
  setHighlightedVariables(['pivot']);
  await customSleep(2);
  setHighlightedVariables([]);

  await highlightLine(3);
  let i = low;
  setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
  setHighlightedVariables(['i', 'low']);
  await customSleep(3);
  setHighlightedVariables([]);

  await highlightLine(4);
  let j = high;
  setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
  setHighlightedVariables(['j', 'high']);
  await customSleep(4);
  setHighlightedVariables([]);

  while (i < j) {
    await highlightLine(5);
    setHighlightedVariables(['i', 'j']);
    await customSleep(5);
    setHighlightedVariables([]);

    while (pivot >= data[i]) {
      await highlightLine(6);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      await customSleep(6);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(7);
      i += 1;
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(7);
      setHighlightedVariables([]);
    }
    while (pivot < data[j]) {
      await highlightLine(9);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      await customSleep(9);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(10);
      j -= 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
      setHighlightedVariables(['j']);
      await customSleep(10);
      setHighlightedVariables([]);
    }

    await highlightLine(12);
    setHighlightedVariables(['i', 'j']);
    await customSleep(12);
    setHighlightedVariables([]);
    if (i < j) {
      await highlightLine(13);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      let temp = data[i];
      setHighlightedVariables(['temp']);
      setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
      await customSleep(13);
      setHighlightedIndices([]);
      setHighlightedVariables([]);

      await highlightLine(14);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
      data[i] = data[j];
      await customSleep(14);
      setHighlightedIndices([]);

      await highlightLine(15);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      setHighlightedVariables(['temp']);
      data[j] = temp;
      await customSleep(15);
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  }
  
  await highlightLine(18);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }]);
  let temp = data[low];
  setHighlightedVariables(['temp']);
  setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
  await customSleep(18);
  setHighlightedIndices([]);
  setHighlightedVariables([]);

  await highlightLine(19);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
  data[low] = data[j];
  await customSleep(19);
  setHighlightedIndices([]);

  await highlightLine(20);
  setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
  setHighlightedVariables(['temp']);
  data[j] = temp;
  await customSleep(20);
  setHighlightedIndices([]);
  setHighlightedVariables([]);
  

  await highlightLine(21);
  setHighlightedVariables(['j']);
  await customSleep(21);
  setHighlightedVariables([]);
  return j;
};

export const quickSortCpp = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep) => {
  await highlightLine(26);
  setHighlightedVariables(['low', 'high']);
  await customSleep(26);
  setHighlightedVariables([]);
  if (low < high) {
    await highlightLine(27);
    await customSleep(27);
    await highlightCallingLine(27);
    let j = await partitionCpp(data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
    await highlightLine(27);
    await customSleep(27);

    await highlightLine(28);
    await customSleep(28);
    await highlightCallingLine(28);
    await quickSortCpp(data, low, j, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(28);
    await customSleep(28);

    await highlightLine(29);
    await customSleep(29);
    await highlightCallingLine(29);
    await quickSortCpp(data, j + 1, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(29);
    await customSleep(29);
  }

  await highlightLine(31);
  await customSleep(31);
  return data;
};

const partitionCpp = async (data, low, high, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  await highlightLine(3);
  let pivot = data[low];
  setVariables((vars) => ({ ...vars, pivot: { variable_name: 'pivot', value: pivot } }));
  setHighlightedVariables(['pivot']);
  await customSleep(3);
  setHighlightedVariables([]);

  await highlightLine(4);
  let i = low;
  setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
  setHighlightedVariables(['i', 'low']);
  await customSleep(4);
  setHighlightedVariables([]);

  await highlightLine(5);
  let j = high;
  setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
  setHighlightedVariables(['j', 'high']);
  await customSleep(5);
  setHighlightedVariables([]);

  while (i < j) {
    await highlightLine(6);
    setHighlightedVariables(['i', 'j']);
    await customSleep(6);
    setHighlightedVariables([]);

    while (pivot >= data[i]) {
      await highlightLine(7);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      await customSleep(7);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(8);
      i += 1;
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(8);
      setHighlightedVariables([]);
    }
    while (pivot < data[j]) {
      await highlightLine(10);
      setHighlightedVariables(['pivot']);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      await customSleep(10);
      setHighlightedVariables([]);
      setHighlightedIndices([]);

      await highlightLine(11);
      j -= 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
      setHighlightedVariables(['j']);
      await customSleep(11);
      setHighlightedVariables([]);
    }

    await highlightLine(13);
    setHighlightedVariables(['i', 'j']);
    await customSleep(13);
    setHighlightedVariables([]);
    if (i < j) {
      await highlightLine(14);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }]);
      let temp = data[i];
      setHighlightedVariables(['temp']);
      setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
      await customSleep(14);
      setHighlightedIndices([]);
      setHighlightedVariables([]);

      await highlightLine(15);
      setHighlightedIndices([{ arrayName: 'data', index: i, iteratorName: 'i' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
      data[i] = data[j];
      await customSleep(15);
      setHighlightedIndices([]);

      await highlightLine(16);
      setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
      setHighlightedVariables(['temp']);
      data[j] = temp;
      await customSleep(16);
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  }
  
  await highlightLine(19);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }]);
  let temp = data[low];
  setHighlightedVariables(['temp']);
  setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
  await customSleep(19);
  setHighlightedIndices([]);
  setHighlightedVariables([]);

  await highlightLine(20);
  setHighlightedIndices([{ arrayName: 'data', index: low, iteratorName: 'low' }, { arrayName: 'data', index: j, iteratorName: 'j' }]);
  data[low] = data[j];
  await customSleep(20);
  setHighlightedIndices([]);

  await highlightLine(21);
  setHighlightedIndices([{ arrayName: 'data', index: j, iteratorName: 'j' }]);
  setHighlightedVariables(['temp']);
  data[j] = temp;
  await customSleep(21);
  setHighlightedIndices([]);
  setHighlightedVariables([]);
  

  await highlightLine(22);
  setHighlightedVariables(['j']);
  await customSleep(22);
  setHighlightedVariables([]);
  return j;
};
  