import React, { useState } from 'react';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalCheckout = ({ amount, plan, currency }) => {
    const [status, setStatus] = useState('default'); // default, loading, verified, failed
    const handleApprove = async (data, actions) => {
        setStatus('loading');
        // Capture the payment
        await actions.order.capture();

        // Send the order ID along with other data to the backend for further processing
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('userToken');
        const header = {
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
            },
        };
        const payload = {
            orderID: data.orderID,
            amount,
            plan,
            currency
        };
        try {
          const verifyResponse = await axios.post(`${apiUrl}/api/verify-paypal-payment/`, payload, header);
          if (verifyResponse.status === 200) {
            setStatus('verified');
            setTimeout(() => {
              window.location.href = '/pricing';
            }, 1000);
          } else {
            setStatus('failed');
          }
        } catch (error) {
          console.error('Verification Error:', error);
          setStatus('failed');
        }
    };

    const getButtonStyle = () => {
      switch (status) {
        case 'loading':
          return 'alert alert-default';
        case 'verified':
          return 'alert alert-success';
        case 'failed':
          return 'alert alert-danger';
        default:
          return 'alert alert-default';
      }
    };

  return (
    <PayPalScriptProvider 
      options={{ 
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
      }}>
      <PayPalButtons
        style={{
          shape: "rect",
          layout: "vertical",
          color: "black",
          label: "paypal",
        }} 
        fundingSource="paypal" 
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                },
                description: "CodingCanvas",
              },
            ],
          });
        }}
        onApprove={handleApprove}
        onError={(err) => console.error("Payment Error:", err)}
      />
      <div 
        style={{fontSize: '13px', fontWeight: 'bold', paddingTop: '10px'}}
      >
        {status === 'loading' && (
          <div style={{alignItems: 'center' }}>
              Processing &nbsp;
              <span className="dot-ellipsis">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
              </span>
          </div>
        )}

        {status === 'verified' && (
          <div style={{alignItems: 'center', color: 'green' }}>
              Payment Success, Wait&nbsp;
              <span className="dot-ellipsis">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
              </span>
          </div>
        )}

        {status === 'failed' && (
          <div style={{alignItems: 'center', color: 'red' }}>
              Payment Failed &nbsp;
              <span className="dot-ellipsis">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
              </span>
          </div>
        )}
      </div>
    </PayPalScriptProvider>
  );
};

export default PayPalCheckout;
