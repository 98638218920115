import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        Demonstrates the usage of a <b>do-while loop</b>, ensuring the loop body executes at least once. 
        It iterates through a counting process, printing the current count value until the count reaches the specified maximum.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>User Input Validation:</b> Ensure a block of code runs at least once (e.g., prompt for input until valid data is entered).</li>
            <li><b>Simulations:</b> Repeat actions with a guaranteed initial execution (e.g., game loops, retries).</li>
            <li><b>Educational Tools:</b> Demonstrate looping constructs where the condition is checked after the first execution.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
