export const personSetDetailsForCpp = async (
    initialStructure,
    highlightLine,
    setStringVariables,
    setHighlightedStringVariables,
    setVariables,
    setHighlightedVariables,
    customSleep
) => {
    await highlightLine(12);
    await customSleep(12);

    await highlightLine(13);
    setStringVariables((vars) => ({ ...vars, name: { variable_name: 'name', value: initialStructure.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep(13);
    setHighlightedStringVariables([]);

    await highlightLine(14);
    setVariables((vars) => ({ ...vars, age: { variable_name: 'age', value: initialStructure.age } }));
    setHighlightedVariables(['age']);
    await customSleep(14);
    setHighlightedVariables([]);
};

export const personDisplayInfoForCpp = async (
    initialStructure,
    highlightLine,
    setStringVariables,
    setHighlightedStringVariables,
    setVariables,
    setHighlightedVariables,
    customSleep,
    logMessage
) => {
    await highlightLine(7);
    await customSleep(7);

    await highlightLine(8);
    setHighlightedStringVariables(['name']);
    await logMessage(`Name: ${initialStructure.name}`);
    await customSleep(8);
    setHighlightedStringVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['age']);
    await logMessage(`Age: ${initialStructure.age}`);
    await customSleep(9);
    setHighlightedVariables([]);
};
