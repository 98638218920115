export const selectionSortPython = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep) => {
    for (let i = 0; i < list.length - 1; i++) {
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      
      await highlightLine(1);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(1);
      setHighlightedVariables([]);
      
      await highlightLine(2);
      let minIndex = i;
      setMinIndex(minIndex);
      setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'min_index', value: minIndex } }));
      setHighlightedVariables(['min_index']);
      await customSleep(2);
      setHighlightedVariables([]);
      
      for (let j = i + 1; j < list.length; j++) {
        setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        
        await highlightLine(3);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep(3);
        setHighlightedVariables([]);
        
        await highlightLine(4);
        await customSleep(4);
        if (list[j] < list[minIndex]) {
          await highlightLine(5);
          minIndex = j;
          setMinIndex(minIndex);
          setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'min_index', value: minIndex } }));
          setHighlightedVariables(['min_index']);
          await customSleep(5);
          setHighlightedVariables([]);
        }
      }

      await highlightLine(6);
      [list[i], list[minIndex]] = [list[minIndex], list[i]];
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: minIndex, iteratorName: 'minIndex' }]);
      await customSleep(6);
      setHighlightedIndices([]);
    }

    await highlightLine(7);
    await customSleep(7);
    setHighlightedIndices([]);
    setMinIndex(null);
    return list;
  };

  export const selectionSortJava = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep) => {
    await highlightLine(2);
    let n = list.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep(2);
    setHighlightedVariables([]);
    
    for (let i = 0; i < list.length - 1; i++) {
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(3);
      setHighlightedVariables([]);
      
      await highlightLine(4);
      let minIndex = i;
      setMinIndex(minIndex);
      setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
      setHighlightedVariables(['minIndex']);
      await customSleep(4);
      setHighlightedVariables([]);
      
      for (let j = i + 1; j < list.length; j++) {
        setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        
        await highlightLine(5);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep(5);
        setHighlightedVariables([]);
        
        await highlightLine(6);
        await customSleep(6);
        if (list[j] < list[minIndex]) {
          await highlightLine(7);
          minIndex = j;
          setMinIndex(minIndex);
          setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
          setHighlightedVariables(['minIndex']);
          await customSleep(7);
          setHighlightedVariables([]);
        }
      }

      await highlightLine(8);
      let temp = list[minIndex];
      setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
      setHighlightedVariables(['temp']);
      await customSleep(8);
      setHighlightedVariables([]);
      
      await highlightLine(9);
      list[minIndex] = list[i];
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: minIndex, iteratorName: 'minIndex' }]);
      await customSleep(9);
      setHighlightedIndices([]);
      
      await highlightLine(10);
      list[i] = temp;
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      setHighlightedVariables(['temp']);
      await customSleep(10);
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }

    await highlightLine(12);
    await customSleep(12);
    setHighlightedIndices([]);
    setMinIndex(null);
    return list;
  };

  export const selectionSortC = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep) => {
    await highlightLine(2);
    let i, j, minIndex, temp;
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
    setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
    setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
    setHighlightedVariables(['i', 'j', 'minIndex', 'temp']);
    await customSleep(2);
    setHighlightedVariables([]);
    
    for (let i = 0; i < list.length - 1; i++) {
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(3);
      setHighlightedVariables([]);
      
      await highlightLine(4);
      let minIndex = i;
      setMinIndex(minIndex);
      setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
      setHighlightedVariables(['minIndex']);
      await customSleep(4);
      setHighlightedVariables([]);
      
      for (let j = i + 1; j < list.length; j++) {
        setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        
        await highlightLine(5);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep(5);
        setHighlightedVariables([]);
        
        await highlightLine(6);
        await customSleep(6);
        if (list[j] < list[minIndex]) {
          await highlightLine(7);
          minIndex = j;
          setMinIndex(minIndex);
          setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
          setHighlightedVariables(['minIndex']);
          await customSleep(7);
          setHighlightedVariables([]);
        }
      }

      await highlightLine(8);
      let temp = list[minIndex];
      setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
      setHighlightedVariables(['temp']);
      await customSleep(8);
      setHighlightedVariables([]);
      
      await highlightLine(9);
      list[minIndex] = list[i];
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: minIndex, iteratorName: 'minIndex' }]);
      await customSleep(9);
      setHighlightedIndices([]);
      
      await highlightLine(10);
      list[i] = temp;
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      setHighlightedVariables(['temp']);
      await customSleep(10);
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }

    await highlightLine(12);
    await customSleep(12);
    setHighlightedIndices([]);
    setMinIndex(null);
    return list;
  };

  export const selectionSortCpp = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep) => {
    await highlightLine(3);
    let i, j, minIndex, temp;
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
    setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
    setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
    setHighlightedVariables(['i', 'j', 'minIndex', 'temp']);
    await customSleep(3);
    setHighlightedVariables([]);
    
    for (let i = 0; i < list.length - 1; i++) {
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      
      await highlightLine(4);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep(4);
      setHighlightedVariables([]);
      
      await highlightLine(5);
      let minIndex = i;
      setMinIndex(minIndex);
      setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
      setHighlightedVariables(['minIndex']);
      await customSleep(5);
      setHighlightedVariables([]);
      
      for (let j = i + 1; j < list.length; j++) {
        setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        
        await highlightLine(6);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep(6);
        setHighlightedVariables([]);
        
        await highlightLine(7);
        await customSleep(7);
        if (list[j] < list[minIndex]) {
          await highlightLine(8);
          minIndex = j;
          setMinIndex(minIndex);
          setVariables((vars) => ({ ...vars, minIndex: { variable_name: 'minIndex', value: minIndex } }));
          setHighlightedVariables(['minIndex']);
          await customSleep(8);
          setHighlightedVariables([]);
        }
      }

      await highlightLine(9);
      let temp = list[minIndex];
      setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
      setHighlightedVariables(['temp']);
      await customSleep(9);
      setHighlightedVariables([]);
      
      await highlightLine(10);
      list[minIndex] = list[i];
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }, { arrayName: 'list', index: minIndex, iteratorName: 'minIndex' }]);
      await customSleep(10);
      setHighlightedIndices([]);
      
      await highlightLine(11);
      list[i] = temp;
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      setHighlightedVariables(['temp']);
      await customSleep(11);
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }

    await highlightLine(13);
    await customSleep(13);
    setHighlightedIndices([]);
    setMinIndex(null);
    return list;
  };
