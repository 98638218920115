import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates a function to check whether two strings are equal by comparing 
        their lengths and characters at each position.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Password Verification:</b> Comparing user-entered passwords with stored passwords during login.</li>
            <li><b>File Validation:</b> Verifying file names or extensions to check for correct file formats.</li>
            <li><b>Search Engines:</b> Comparing search queries with stored keywords or database entries to find exact matches.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
