import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This implementation constructs a Binary Search Tree (BST) by adding nodes in a hierarchical structure:
        <ul style={{paddingLeft: '15px'}}>
            <li><b>Node Class:</b> Each Node contains data, a left child, and a right child.</li>
            <li>
                <b>BinarySearchTree Class:</b>
                <ul style={{paddingLeft: '15px'}}>
                    <li>
                        Maintains the root of the tree.
                    </li>
                    <li>
                        The add method adds a new Node to the tree:
                        <ul style={{paddingLeft: '15px'}}>
                            <li>
                                If the tree is empty, the new node becomes the root.
                            </li>
                            <li>
                                Otherwise, traverse the tree:
                                <ul style={{paddingLeft: '15px'}}>
                                    <li>If the data is less than the current node, move to the left child.</li>
                                    <li>If the data is greater, move to the right child.</li>
                                    <li>Insert the new node at the appropriate position once a None is reached.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>log n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Data Storage:</b> Organize and retrieve hierarchical data efficiently, such as file systems or organizational charts.</li>
            <li><b>Search Optimization:</b> Quickly search for elements in sorted datasets, such as contact lists or product inventories.</li>
            <li><b>Range Queries:</b> Find elements within a specific range (e.g., filter data for reports or visualizations).</li>
            <li><b>Gaming and AI:</b> Use trees for decision-making, pathfinding, or move evaluation in games and AI applications.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
