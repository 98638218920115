import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program implements an <b>undirected and directed graph</b> using an adjacency list representation. The Graph class manages the vertices and their edges, allowing dynamic addition of edges.
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complexity for E edges:</b>&nbsp;&nbsp;
          <div style={{display: 'flex'}}>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Directed: </b>
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>E</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Undirected: </b>
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>2E</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
          </div>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Network Representation:</b> Model systems like social networks, computer networks, or road maps.</li>
            <li><b>Pathfinding Algorithms:</b> Use the graph for algorithms like BFS, DFS, Dijkstra, or A*.</li>
            <li><b>Simulation Systems:</b> Simulate interactions or dependencies, such as workflow systems.</li>
            <li><b>Educational Tools:</b> Demonstrate graph data structures and operations in programming courses.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
