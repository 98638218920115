import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
          This program implements the <b>Insertion Sort</b> algorithm, which sorts a list by 
          building a sorted portion one element at a time. It iteratively compares the current 
          element to its predecessors, shifting elements as needed to insert the current 
          element into its correct position. The sorted list is printed as the output.
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}><span>N<sup>2</sup></span></span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Small Data Sets:</b> Efficient for small or nearly sorted datasets.</li>
            <li><b>Educational Tools:</b> Demonstrates basic sorting techniques.</li>
            <li><b>Manual Sorting:</b> Mimics how humans sort items, like organizing playing cards.</li>
            <li><b>Data Cleaning:</b> Sort data when computational complexity isn't a concern.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
