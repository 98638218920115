import React, { useEffect } from 'react';

const RefundAndCancellation = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
    
    // Set an SEO-friendly page title
    document.title = 'Refund and Cancellation Policy | Coding Canvas';

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Understand the refund and cancellation policy of CodingCanvas.io, including eligibility, process, and terms.'
      );
    }
  }, []);

  return (
    <main className="container">
      <section className="text-center mb-5">
        <h1 className="mb-3">Refund and Cancellation Policy</h1>
        <p>
          We value your trust and satisfaction. Please review our refund and cancellation policy carefully to
          understand your rights.
        </p>
      </section>

      <section className="mb-4">
        <h3>1. Subscription Cancellation</h3>
        <ul style={{ paddingLeft: '30px' }}>
          <li>Free Plan: You can cancel your free plan at any time without any action required.</li>
          <li>
            Paid Plans: Subscriptions (6-Month and 1-Year plans) can be canceled at any time, but no refunds will
            be issued for the remaining subscription period.
          </li>
          <li>
            After cancellation, you will retain access to premium features until the subscription period ends.
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>2. Refund Policy</h3>
        <p style={{paddingLeft: '5px'}}>
          Refunds are issued only under specific circumstances. Please review the conditions below to check if
          you are eligible for a refund.
        </p>
        <ul style={{ paddingLeft: '30px' }}>
          <li>
            <strong>Technical Issues:</strong> If technical problems prevent you from using the platform and we
            cannot resolve them within 7 business days, you may request a refund.
          </li>
          <li>
            <strong>Duplicate Payments:</strong> Refunds will be issued for duplicate charges made for the same
            subscription.
          </li>
          <li>
            <strong>Unauthorized Transactions:</strong> If your account is charged without your consent, contact
            us within 15 days of the transaction to request a refund.
          </li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>3. Non-Refundable Situations</h3>
        <ul style={{ paddingLeft: '30px' }}>
          <li>Change of mind or no longer needing the subscription.</li>
          <li>Partial usage of a subscription plan.</li>
          <li>Failure to cancel a subscription before the renewal date.</li>
        </ul>
      </section>

      <section className="mb-4">
        <h3>4. Refund Request Process</h3>
        <p style={{paddingLeft: '5px'}}>To request a refund, please follow these steps:</p>
        <ul style={{ paddingLeft: '30px' }}>
          <li>
            Email us at <a href="mailto:support@codingcanvas.io">support@codingcanvas.io</a> with the subject line
            "Refund Request".
          </li>
          <li>Provide your full name, email associated with your account, and the transaction ID or receipt.</li>
          <li>State the reason for your refund request clearly.</li>
        </ul>
        <p>
          We will review your request and notify you of the outcome within <strong>7 business days</strong>.
        </p>
      </section>

      <section className="mb-4">
        <h3>5. Processing Refunds</h3>
        <p style={{paddingLeft: '15px'}}>
          Approved refunds will be processed to the original payment method within <strong>10 business days</strong>.
          Transaction fees charged by the payment gateway may be deducted from the refund amount.
        </p>
      </section>

      <section className="mb-4">
        <h3>6. Cancellation by CodingCanvas</h3>
        <p style={{paddingLeft: '15px'}}>
          We reserve the right to suspend or terminate your access to the platform for any violation of our{' '}
          <a href="/terms-and-conditions">Terms and Conditions</a>. Refunds will not be issued in such cases.
        </p>
      </section>

      <section className="mb-4">
        <h3>7. Contact Us</h3>
        <p style={{paddingLeft: '15px'}}>
          For any questions or concerns about this Refund and Cancellation Policy, please contact us at{' '}
          <a href="mailto:support@codingcanvas.io">support@codingcanvas.io</a>.
        </p>
      </section>
    </main>
  );
};

export default RefundAndCancellation;
