const generateMemoryAddress = () => {
  return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
};

class Node {
  constructor(data, nodeAddress) {
    this.data = data;
    this.next = null;
    this.address = nodeAddress;
    this.parent = null;
  }
}

class LinkedListForJava {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'null'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
        
        while (temp.next !== null) {
            temp = temp.next;

            setHeadVariables((vars) => ({ ...vars, 
              temp: { variable_name: 'temp', value: temp.address, child: temp.address }
            }));
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to update a node's data in the list
  update = async(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  ) => {
    await highlightLine(13);
    setVariables((vars) => ({ ...vars, 
      oldData: { variable_name: 'oldData', value: oldData },
      newData: { variable_name: 'newData', value: newData }
    }));
    setHighlightedVariables(['oldData', 'newData']);
    await customSleep(13);
    setHighlightedVariables([]);
    
    let temp = this.head;

    await highlightLine(14);
    setHighlightedHeadVariables(['head']);
    await customSleep(14);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(15);
      await logMessage("The list is empty.");
      await customSleep(15);

      await highlightLine(16);
      await customSleep(16);

      // Emptying vaiables
      setVariables(() => ({}));

      return;
    }

    await highlightLine(19);
    setHighlightNodeAddress([`${temp.address}`]);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep(19);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    while (temp !== null) {
      await highlightLine(20);
      setHighlightedHeadVariables(['temp']);
      await customSleep(20);
      setHighlightedHeadVariables([]);

      await highlightLine(21);
      setHighlightDataPart([`${temp.address}`]);
      setHighlightedVariables(['oldData']);
      await customSleep(21);
      setHighlightedVariables([]);
      if (temp.data === oldData) {
        await highlightLine(22);
        setHighlightedVariables(['newData']);
        await customSleep(null, 1000);
        setHighlightedVariables([]);
        temp.data = newData;

        setHighlightDataPart([]);
        await customSleep(null, 100);
        setNodeVariables((vars) => ({
          ...vars,
          [temp.address]: {
            variable_name: temp.address,
            value: { data: newData, next: temp.next ? temp.next.address : 'null' },
            address: temp.address,
            parent: temp.parent,
          },
        }));
        await customSleep(22);

        await highlightLine(23);
        setHighlightedVariables(['oldData', 'newData']);
        await logMessage(`${oldData} updated to ${newData}`);
        await customSleep(23);
        setHighlightedVariables([]);

        await highlightLine(24);
        await customSleep(24);

        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));

        return;
      }
      setHighlightDataPart([]);

      await highlightLine(26);
      setHighlightedHeadVariables(['temp']);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(null, 1000);
      temp = temp.next;
      if (temp !== null) {
        await customSleep(null, 1000);
        setHighlightNodeAddress([`${temp.address}`]);
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
      } else {
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: 'null'}
        }));
      }
      setHighlightNextAddressPart([]);
      await customSleep(26);
      setHighlightedHeadVariables([]);
      setHighlightNodeAddress([]);

      if (!(temp !== null)) {
        await highlightLine(20);
        setHighlightedHeadVariables(['temp']);
        await customSleep(20);
        setHighlightedHeadVariables([]);
      }
    }

    await highlightLine(28);
    await logMessage(`${oldData} not found in the list.`);
    await customSleep(28);

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
  }

}


export const linkedListJava = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForJava();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListUpdateJava = async (
  oldData,
  newData,
  globalList,
  setVariables,
  setNodeVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(34);
  await customSleep(34);

  await highlightCallingLine(34);
  await globalList.update(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  );
  await highlightCallingLine(null);
  await highlightLine(34);
  await customSleep(34);
  
};


class LinkedListForPython {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'None'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
        let temp = this.head;

        while (temp.next !== null) {
            temp = temp.next;
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to update a node's data in the list
  update = async(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  ) => {
    await highlightLine(9);
    setVariables((vars) => ({ ...vars, 
      oldData: { variable_name: 'oldData', value: oldData },
      newData: { variable_name: 'newData', value: newData }
    }));
    setHighlightedVariables(['oldData', 'newData']);
    await customSleep(9);
    setHighlightedVariables([]);
    
    let temp = this.head;

    await highlightLine(10);
    setHighlightedHeadVariables(['head']);
    await customSleep(10);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(11);
      await logMessage("The list is empty.");
      await customSleep(11);

      await highlightLine(12);
      await customSleep(12);

      // Emptying vaiables
      setVariables(() => ({}));

      return;
    }

    await highlightLine(14);
    setHighlightNodeAddress([`${temp.address}`]);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep(14);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    while (temp !== null) {
      await highlightLine(15);
      setHighlightedHeadVariables(['temp']);
      await customSleep(15);
      setHighlightedHeadVariables([]);

      await highlightLine(16);
      setHighlightDataPart([`${temp.address}`]);
      setHighlightedVariables(['oldData']);
      await customSleep(16);
      setHighlightedVariables([]);
      if (temp.data === oldData) {
        await highlightLine(17);
        setHighlightedVariables(['newData']);
        await customSleep(null, 1000);
        setHighlightedVariables([]);
        temp.data = newData;

        setHighlightDataPart([]);
        await customSleep(null, 100);
        setNodeVariables((vars) => ({
          ...vars,
          [temp.address]: {
            variable_name: temp.address,
            value: { data: newData, next: temp.next ? temp.next.address : 'null' },
            address: temp.address,
            parent: temp.parent,
          },
        }));
        await customSleep(17);

        await highlightLine(18);
        setHighlightedVariables(['oldData', 'newData']);
        await logMessage(`${oldData} updated to ${newData}`);
        await customSleep(18);
        setHighlightedVariables([]);

        await highlightLine(19);
        await customSleep(19);

        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));

        return;
      }
      setHighlightDataPart([]);

      await highlightLine(20);
      setHighlightedHeadVariables(['temp']);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(null, 1000);
      temp = temp.next;
      if (temp !== null) {
        await customSleep(null, 1000);
        setHighlightNodeAddress([`${temp.address}`]);
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
      } else {
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: 'None'}
        }));
      }
      setHighlightNextAddressPart([]);
      await customSleep(20);
      setHighlightedHeadVariables([]);
      setHighlightNodeAddress([]);

      if (!(temp !== null)) {
        await highlightLine(15);
        setHighlightedHeadVariables(['temp']);
        await customSleep(15);
        setHighlightedHeadVariables([]);
      }
    }

    await highlightLine(21);
    await logMessage(`${oldData} not found in the list.`);
    await customSleep(21);

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
  }
}

export const linkedListPython = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForPython();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListUpdatePython = async (
  oldData,
  newData,
  globalList,
  setVariables,
  setNodeVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(26);
  await customSleep(26);

  await highlightCallingLine(26);
  await globalList.update(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  );
  await highlightCallingLine(null);
  await highlightLine(26);
  await customSleep(26);
  
};

class LinkedListForCpp {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'nullptr'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
      this.head = newNode;

      setHeadVariables((vars) => ({ ...vars, 
        head: { variable_name: 'head', value: nodeAddress }
      }));

      newNode.parent = 'head';
      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: 'nullptr'},
          address: newNode.address,
          parent: newNode.parent
        }
      }));
    } else {
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));

        while (temp.next !== null) {
          temp = temp.next;

          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: temp.address, child: temp.address }
          }));
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to update a node's data in the list
  update = async(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  ) => {
    await highlightLine(22);
    setVariables((vars) => ({ ...vars, 
      oldData: { variable_name: 'oldData', value: oldData },
      newData: { variable_name: 'newData', value: newData }
    }));
    setHighlightedVariables(['oldData', 'newData']);
    await customSleep(22);
    setHighlightedVariables([]);
    
    let temp = this.head;

    await highlightLine(23);
    setHighlightedHeadVariables(['head']);
    await customSleep(23);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(24);
      await logMessage("The list is empty.");
      await customSleep(24);

      await highlightLine(25);
      await customSleep(25);

      // Emptying vaiables
      setVariables(() => ({}));

      return;
    }

    await highlightLine(28);
    setHighlightNodeAddress([`${temp.address}`]);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep(28);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    while (temp !== null) {
      await highlightLine(29);
      setHighlightedHeadVariables(['temp']);
      await customSleep(29);
      setHighlightedHeadVariables([]);

      await highlightLine(30);
      setHighlightDataPart([`${temp.address}`]);
      setHighlightedVariables(['oldData']);
      await customSleep(30);
      setHighlightedVariables([]);
      if (temp.data === oldData) {
        await highlightLine(31);
        setHighlightedVariables(['newData']);
        await customSleep(null, 1000);
        setHighlightedVariables([]);
        temp.data = newData;

        setHighlightDataPart([]);
        await customSleep(null, 100);
        setNodeVariables((vars) => ({
          ...vars,
          [temp.address]: {
            variable_name: temp.address,
            value: { data: newData, next: temp.next ? temp.next.address : 'null' },
            address: temp.address,
            parent: temp.parent,
          },
        }));
        await customSleep(31);

        await highlightLine(32);
        setHighlightedVariables(['oldData', 'newData']);
        await logMessage(`${oldData} updated to ${newData}`);
        await customSleep(32);
        setHighlightedVariables([]);

        await highlightLine(33);
        await customSleep(33);

        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));

        return;
      }
      setHighlightDataPart([]);

      await highlightLine(35);
      setHighlightedHeadVariables(['temp']);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(null, 1000);
      temp = temp.next;
      if (temp !== null) {
        await customSleep(null, 1000);
        setHighlightNodeAddress([`${temp.address}`]);
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
      } else {
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: 'nullptr'}
        }));
      }
      setHighlightNextAddressPart([]);
      await customSleep(35);
      setHighlightedHeadVariables([]);
      setHighlightNodeAddress([]);

      if (!(temp !== null)) {
        await highlightLine(29);
        setHighlightedHeadVariables(['temp']);
        await customSleep(29);
        setHighlightedHeadVariables([]);
      }
    }

    await highlightLine(37);
    await logMessage(`${oldData} not found in the list.`);
    await customSleep(37);

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
  }
}

export const linkedListCpp = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForCpp();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );
  return list;
};

export const linkedListUpdateCpp = async (
  oldData,
  newData,
  globalList,
  setVariables,
  setNodeVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(44);
  await customSleep(44);

  await highlightCallingLine(44);
  await globalList.update(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  );
  await highlightCallingLine(null);
  await highlightLine(44);
  await customSleep(44);

  await highlightLine(46);
  await customSleep(46);
};


class LinkedListForC {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'NULL'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
      let temp = this.head;
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));

      while (temp.next !== null) {
        temp = temp.next;
        
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
      }

      temp.next = newNode;
      newNode.parent = temp.address;

      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: 'NULL'},
          address: newNode.address,
          parent: newNode.parent
        }
      }));
      
      setNodeVariables((vars) => ({ ...vars, 
        [temp.address]: { 
          variable_name: temp.address,
          value: {data: temp.data, next: newNode.address},
          address: temp.address,
          parent: temp.parent
        }
      }));
      
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
    }
  }

  // Method to update a node's data in the list
  update = async(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  ) => {
    await highlightLine(10);
    setVariables((vars) => ({ ...vars, 
      oldData: { variable_name: 'oldData', value: oldData },
      newData: { variable_name: 'newData', value: newData }
    }));
    setHighlightedVariables(['oldData', 'newData']);
    await customSleep(10);
    setHighlightedVariables([]);
    
    let temp = this.head;

    await highlightLine(11);
    setHighlightedHeadVariables(['head']);
    await customSleep(11);
    setHighlightedHeadVariables([]);
    if (temp === null) {
      await highlightLine(12);
      await logMessage("The list is empty.");
      await customSleep(12);

      await highlightLine(13);
      await customSleep(13);

      // Emptying vaiables
      setVariables(() => ({}));

      return;
    }

    await highlightLine(16);
    setHighlightNodeAddress([`${temp.address}`]);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep(16);
    setHighlightedHeadVariables([]);
    setHighlightNodeAddress([]);

    while (temp !== null) {
      await highlightLine(17);
      setHighlightedHeadVariables(['temp']);
      await customSleep(17);
      setHighlightedHeadVariables([]);

      await highlightLine(18);
      setHighlightDataPart([`${temp.address}`]);
      setHighlightedVariables(['oldData']);
      await customSleep(18);
      setHighlightedVariables([]);
      if (temp.data === oldData) {
        await highlightLine(19);
        setHighlightedVariables(['newData']);
        await customSleep(null, 1000);
        setHighlightedVariables([]);
        temp.data = newData;

        setHighlightDataPart([]);
        await customSleep(null, 100);
        setNodeVariables((vars) => ({
          ...vars,
          [temp.address]: {
            variable_name: temp.address,
            value: { data: newData, next: temp.next ? temp.next.address : 'null' },
            address: temp.address,
            parent: temp.parent,
          },
        }));
        await customSleep(19);

        await highlightLine(20);
        setHighlightedVariables(['oldData', 'newData']);
        await logMessage(`${oldData} updated to ${newData}`);
        await customSleep(20);
        setHighlightedVariables([]);

        await highlightLine(21);
        await customSleep(21);

        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));

        // Emptying vaiables
        setVariables(() => ({}));

        return;
      }
      setHighlightDataPart([]);

      await highlightLine(23);
      setHighlightedHeadVariables(['temp']);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(null, 1000);
      temp = temp.next;
      if (temp !== null) {
        await customSleep(null, 1000);
        setHighlightNodeAddress([`${temp.address}`]);
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
      } else {
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: 'NULL'}
        }));
      }
      setHighlightNextAddressPart([]);
      await customSleep(23);
      setHighlightedHeadVariables([]);
      setHighlightNodeAddress([]);

      if (!(temp !== null)) {
        await highlightLine(17);
        setHighlightedHeadVariables(['temp']);
        await customSleep(17);
        setHighlightedHeadVariables([]);
      }
    }

    await highlightLine(25);
    await logMessage(`${oldData} not found in the list.`);
    await customSleep(25);

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
  }
}

export const linkedListC = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForC();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListUpdateC = async (
  oldData,
  newData,
  globalList,
  setVariables,
  setNodeVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHeadVariables,
  setHighlightedVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(29);
  await customSleep(29);

  await highlightCallingLine(29);
  await globalList.update(
    oldData,
    newData,
    highlightLine,
    customSleep,
    logMessage,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress
  );
  await highlightCallingLine(null);
  await highlightLine(29);
  await customSleep(29);

  await highlightLine(31);
  await customSleep(31);
};
