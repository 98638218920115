import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program checks whether a given string is a <b>palindrome</b>. A palindrome is a word, 
        phrase, or sequence that reads the same backward as forward (ignoring case). 
        This palindrome check function uses a two-pointer approach to compare characters from the start and end of the string.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Text Validation:</b> Verifying palindrome sequences in cryptography or coding challenges.</li>
            <li><b>Fun Features:</b> Building applications like palindrome word games or name finders.</li>
            <li><b>Data Normalization:</b> Checking symmetry in DNA sequences or data patterns for biological studies.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
