import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useSumOfAllElements = (language, array, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [arrayVariables, setArrayVariables] = useState({});
  const [variables, setVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newArray) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/arr = \[.*?\]/, `arr = [${newArray.join(", ")}]`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int arr\[\] = \{.*?\};/,
            `int arr[] = {${newArray.join(", ")}};`
          )
      );
    }
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setLogs([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
    setVariables({});
    setArrayVariables({});
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runSumOfAllElementsPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep(1);
    setHighlightedArrayVariables([]);

    await highlightLine(2);
    let n = array.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(3);
    let total = 0;
    setVariables((vars) => ({ ...vars, total: { variable_name: 'total', value: total } }));
    setHighlightedVariables(['total']);
    await customSleep(3);
    setHighlightedVariables([]);

    for (let i = 0; i < n; i++) {
      await highlightLine(4);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'n']);
      await customSleep(4);
      setHighlightedVariables([]);

      await highlightLine(5);
      setHighlightedVariables(['total']);
      setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
      total = total + array[i]
      setVariables((vars) => ({
        ...vars,
        total: { variable_name: 'total', value: total }
      }));
      await customSleep(5);
      setHighlightedVariables([]);
      setHighlightedIndices([]);
    }

    await highlightLine(6);
    setHighlightedVariables(['total']);
    await logMessage(`Total = ${total}`);
    await customSleep(6);
    setHighlightedVariables([]);

    await focusEndLine(6);
    setIsRunning(false);
  };

  const runSumOfAllElementsJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep(2);
    setHighlightedArrayVariables([]);

    await highlightLine(3);
    let n = array.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(4);
    let total = 0;
    setVariables((vars) => ({ ...vars, total: { variable_name: 'total', value: total } }));
    setHighlightedVariables(['total']);
    await customSleep(4);
    setHighlightedVariables([]);

    for (let i = 0; i < n; i++) {
      await highlightLine(5);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'n']);
      await customSleep(5);
      setHighlightedVariables([]);

      await highlightLine(6);
      setHighlightedVariables(['total']);
      setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
      total = total + array[i]
      setVariables((vars) => ({
        ...vars,
        total: { variable_name: 'total', value: total }
      }));
      await customSleep(6);
      setHighlightedVariables([]);
      setHighlightedIndices([]);
    }

    await highlightLine(8);
    setHighlightedVariables(['total']);
    await logMessage(`Total = ${total}`);
    await customSleep(8);
    setHighlightedVariables([]);

    await focusEndLine(7);
    setIsRunning(false);
  };

  const runSumOfAllElementsC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep(3);
    setHighlightedArrayVariables([]);

    await highlightLine(4);
    let n = array.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(5);
    let total = 0;
    setVariables((vars) => ({ ...vars, total: { variable_name: 'total', value: total } }));
    setHighlightedVariables(['total']);
    await customSleep(5);
    setHighlightedVariables([]);

    for (let i = 0; i < n; i++) {
      await highlightLine(6);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'n']);
      await customSleep(6);
      setHighlightedVariables([]);

      await highlightLine(7);
      setHighlightedVariables(['total']);
      setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
      total = total + array[i]
      setVariables((vars) => ({
        ...vars,
        total: { variable_name: 'total', value: total }
      }));
      await customSleep(7);
      setHighlightedVariables([]);
      setHighlightedIndices([]);
    }

    await highlightLine(9);
    setHighlightedVariables(['total']);
    await logMessage(`Total = ${total}`);
    await customSleep(9);
    setHighlightedVariables([]);

    await highlightLine(10);
    await customSleep(10);

    await focusEndLine(11);
    setIsRunning(false);
  };

  const runSumOfAllElementsCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(4);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep(4);
    setHighlightedArrayVariables([]);

    await highlightLine(5);
    let n = array.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep(5);
    setHighlightedVariables([]);

    await highlightLine(6);
    let total = 0;
    setVariables((vars) => ({ ...vars, total: { variable_name: 'total', value: total } }));
    setHighlightedVariables(['total']);
    await customSleep(6);
    setHighlightedVariables([]);

    for (let i = 0; i < n; i++) {
      await highlightLine(7);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'n']);
      await customSleep(7);
      setHighlightedVariables([]);

      await highlightLine(8);
      setHighlightedVariables(['total']);
      setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
      total = total + array[i]
      setVariables((vars) => ({
        ...vars,
        total: { variable_name: 'total', value: total }
      }));
      await customSleep(8);
      setHighlightedVariables([]);
      setHighlightedIndices([]);
    }

    await highlightLine(10);
    setHighlightedVariables(['total']);
    await logMessage(`Total = ${total}`);
    await customSleep(10);
    setHighlightedVariables([]);

    await highlightLine(11);
    await customSleep(11);

    await focusEndLine(12);
    setIsRunning(false);
  };

  const runSumOfAllElements = async () => {
    if (language === 'Python') {
      await runSumOfAllElementsPython();
    } else if (language === 'Java') {
      await runSumOfAllElementsJava();
    } else if (language === 'C') {
      await runSumOfAllElementsC();
    } else if (language === 'C++') {
      await runSumOfAllElementsCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    arrayVariables,
    variables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setArrayVariables,
    setVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runSumOfAllElements
  };
};
