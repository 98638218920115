import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the use of an <b>interface</b> to define a contract for <b>classes</b>. 
        The Animal interface declares a method sound(), which is implemented by the Dog class. 
        The Dog class provides a specific implementation of the sound() method, printing a bark sound 
        along with the dog's favorite toy. This highlights how interfaces enforce method implementation 
        in classes.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Payment Systems:</b> An interface like PaymentProcessor can define methods like processPayment(). Classes such as CreditCardProcessor or PayPalProcessor implement the interface to handle different payment methods.</li>
            <li><b>Appliance Control:</b> An Appliance interface might declare methods like turnOn() and turnOff(). Classes like WashingMachine, AirConditioner, and Refrigerator implement these methods, ensuring they all respond to standard commands while retaining their specific behaviors.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
