import { useState, useEffect, useRef } from 'react';
import { 
  linkedListJava,
  linkedListPython,
  linkedListUpdatePython,
  linkedListUpdateJava,
  linkedListUpdateCpp,
  linkedListUpdateC,
  linkedListCpp,
  linkedListC
} from './linkedListAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useLinkedListUpdate = (language, data, oldData, newData, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [nodeVariables, setNodeVariables] = useState({});
  const [headVariables, setHeadVariables] = useState({});
  const [highlightedHeadVariables, setHighlightedHeadVariables] = useState([]);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedNodeVariables, setHighlightedNodeVariables] = useState([]);
  const [highlightDataPart, setHighlightDataPart] = useState([]);
  const [highlightNextAddressPart, setHighlightNextAddressPart] = useState([]);
  const [highlightNodeAddress, setHighlightNodeAddress] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  const hasRun = useRef(false);
  const [globalList, setGlobalList] = useState(null);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = (newOldData, newNewData) => {
    resetState();
    createLinkedListVisual(language);

    let updatedCodeSamples = "";

    // Dynamically update the code samples
    updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/update\s*\(\s*\d+\s*,\s*\d+\s*\)/g, `update(${newOldData}, ${newNewData})`)
    );

    setCodeSamples(updatedCodeSamples);
  };

  const createLinkedListVisual = (language) => {
    if (language === 'Python') {
      createLinkedListPython();
    } else if (language === 'Java') {
      createLinkedListJava();
    } else if (language === 'C') {
      createLinkedListC();
    } else if (language === 'C++') {
      createLinkedListCpp();
    }
  }

  useEffect(() => {
    if (!hasRun.current) {
      createLinkedListVisual(language);
      hasRun.current = true;
    }
  }, []);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setNodeVariables({});
    setHeadVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedNodeVariables([]);
    setHighlightedHeadVariables([]);
    setHighlightDataPart([]);
    setHighlightNextAddressPart([]);
    setHighlightNodeAddress([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {

    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null, time = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const createLinkedListPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'None' }
    }));

    const list = await linkedListPython(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const runLinkedListUpdatePython = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListUpdatePython(
      oldData,
      newData,
      globalList,
      setVariables,
      setNodeVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHeadVariables,
      setHighlightedVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(26);
    setIsRunning(false);
  };

  const createLinkedListJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'null' }
    }));

    const list = await linkedListJava(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const runLinkedListUpdateJava = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListUpdateJava(
      oldData,
      newData,
      globalList,
      setVariables,
      setNodeVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHeadVariables,
      setHighlightedVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(36);
    setIsRunning(false);
  };

  const createLinkedListCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'nullptr' }
    }));

    const list = await linkedListCpp(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const runLinkedListUpdateCpp = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListUpdateCpp(
      oldData,
      newData,
      globalList,
      setVariables,
      setNodeVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHeadVariables,
      setHighlightedVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(47);
    setIsRunning(false);
  };

  const createLinkedListC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'nullptr' }
    }));

    const list = await linkedListC(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const runLinkedListUpdateC = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListUpdateC(
      oldData,
      newData,
      globalList,
      setVariables,
      setNodeVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHeadVariables,
      setHighlightedVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(32);
    setIsRunning(false);
  };


  const runLinkedList = async () => {
    if (language === 'Python') {
      await runLinkedListUpdatePython();
    } else if (language === 'Java') {
      await runLinkedListUpdateJava();
    } else if (language === 'C') {
      await runLinkedListUpdateC();
    } else if (language === 'C++') {
      await runLinkedListUpdateCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    nodeVariables,
    headVariables,
    highlightedVariables,
    highlightedNodeVariables,
    highlightedHeadVariables,
    highlightDataPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    runLinkedList
  };
};
