import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program implements the <b>Merge Sort</b> algorithm, a popular divide-and-conquer 
        sorting method. It recursively divides the array into two halves, sorts each half, 
        and then merges the sorted halves into a single sorted array. This approach ensures 
        stable and efficient sorting with a time complexity of O(l log n).
      </p>
      <p id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n log n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Sorting Large Datasets:</b> Used in database systems and search engines to efficiently sort large datasets that cannot fit into memory.</li>
            <li><b>External Sorting:</b> Ideal for sorting data stored on disk as it works well with sequential data and minimizes memory usage.</li>
            <li><b>Parallel Processing:</b> Merge Sort is often implemented in parallel processing systems, as the divide-and-conquer approach allows for splitting tasks across multiple processors.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
