const generateMemoryAddress = () => {
  return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
};

class Node {
  constructor(data, nodeAddress) {
    this.data = data;
    this.next = null;
    this.address = nodeAddress;
  }
}

class GraphForPython {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setmapVariables
  ) {
    
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    
    if (!directed) {
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }
  }

  async dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  ) {
    if (!this.graph.has(start)) {
      await logMessage("Not a valid vertex to start.");
      return;
    }

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(5);
    let visited = new Set();
    setSetsVariables((vars) => ({ ...vars, visited: { variable_name: 'visited', value: visited } }));
    setHighlightedSetVariables(['visited']);
    await customSleep(5);
    setHighlightedSetVariables([]);

    await highlightLine(6);
    let stack = [""];
    setStackVariables({ stack: { variable_name: 'stack', value: stack } });
    setHighlightedStackIndex([{ stackName: 'stack', index: stack.length - 1 }]);
    await customSleep(null, 1200);
    stack[stack.length - 1] = start;
    setStackVariables({ stack: { variable_name: 'stack', value: stack } });
    await customSleep(6);
    setHighlightedStackIndex([]); 

    if (!(stack.length > 0)) {
      await highlightLine(7);
      setHighlightedStackVariables(['stack']);
      await customSleep(7);
      setHighlightedStackVariables([]);
    }

    while (stack.length > 0) {
      await highlightLine(7);
      setHighlightedStackVariables(['stack']);
      await customSleep(7);
      setHighlightedStackVariables([]);

      await highlightLine(8);
      setHighlightedStackIndex([{ stackName: 'stack', index: stack.length - 1 }]);
      await customSleep(null, 1200);
      setHighlightedStackIndex([]);
      let vertex = stack.pop();
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setHighlightedVariables(['vertex']);
      await customSleep(8);
      setHighlightedVariables([]);

      await highlightLine(9);
      setHighlightedVariables(['vertex']);
      setHighlightedSetIndex([{ setName: 'visited', key: vertex }]);
      await customSleep(9);
      setHighlightedVariables([]);
      setHighlightedSetIndex([]);
      if (!visited.has(vertex)) {
        await highlightLine(10);
        setHighlightedVariables(['vertex']);
        setHighlightedGraphVariables([this.address[vertex]]);
        await logMessage(`${vertex} `);
        await customSleep(10);
        setHighlightedVariables([]);
        setHighlightedGraphVariables([]);

        await highlightLine(11);
        setHighlightedVariables(['vertex']);
        setHighlightedGraphVariables([this.address[vertex]]);
        visited.add(vertex);
        setHighlightedSetIndex([{ setName: 'visited', key: vertex }]);
        await customSleep(11);
        setHighlightedVariables([]);
        setHighlightedGraphVariables([]);
        setHighlightedSetIndex([]);

        if (this.graph.get(vertex).length === 0) {
          await highlightLine(12);
          await logMessage(`Vertex ${vertex} has no neighbors.`);
          await customSleep(12);
        }

        let countNeighbor = 0;
        for (let neighbor of this.graph.get(vertex).slice().reverse()) {
          countNeighbor += 1;

          await highlightLine(12);
          setHighlightedMapIndex([{ mapName: 'graph', key:  `${vertex}`}]);
          setVariables((vars) => ({ ...vars, 
            neighbor: { variable_name: 'neighbor', value: neighbor }
          }));
          setHighlightedVariables(['neighbor']);
          await customSleep(12);
          setHighlightedVariables([]);

          await highlightLine(13);
          setHighlightedVariables(['neighbor']);
          setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
          await customSleep(13);
          setHighlightedVariables([]);
          setHighlightedSetIndex([]);
          if (!visited.has(neighbor)) {
            await highlightLine(14);
            setHighlightedVariables(['neighbor']);
            stack.push("");
            setHighlightedStackIndex([{ stackName: 'stack', index: stack.length - 1 }]);
            await customSleep(null, 1200);
            stack[stack.length - 1] = neighbor;
            setStackVariables({ stack: { variable_name: 'stack', value: stack } });
            await customSleep(14);
            setHighlightedVariables([]);
            setHighlightedStackIndex([]);
          } else {
            await logMessage(`Neighbor ${neighbor} already visited. Iterating the next neighbor.`);
          }

          if (this.graph.get(vertex).length === countNeighbor) {
            await highlightLine(12);
            setVariables((vars) => {
              const { neighbor, ...rest } = vars;  // Destructure and exclude `neighbor`
              return { ...rest };  // Return the updated state without `neighbor`
            });
            await logMessage(`Loop through all the neighbors completed.`);
            await customSleep(12);
          }
        }
        setHighlightedMapIndex([]);
      }

      if (!(stack.length > 0)) {
        await highlightLine(7);
        setHighlightedStackVariables(['stack']);
        await customSleep(7);
        setHighlightedStackVariables([]);
      }
    }

    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));

    // Emptying set variable
    setSetsVariables(() => ({}));

  }

}

export const graphPython = async (
  graphInput,
  edgeDirection,
  setGraphVariables,
  setmapVariables
) => {
  const graph = new GraphForPython();
  
  setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
  
  await graph.addEdge(
    graphInput[0][0],
    graphInput[0][1],
    edgeDirection[0],
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[1][0],
    graphInput[1][1],
    edgeDirection[1],
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[2][0],
    graphInput[2][1],
    edgeDirection[2],
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[3][0],
    graphInput[3][1],
    edgeDirection[3],
    setGraphVariables,
    setmapVariables
  );
  
  await graph.addEdge(
    graphInput[4][0],
    graphInput[4][1],
    edgeDirection[4],
    setGraphVariables,
    setmapVariables
  );

  return graph;
}

export const graphDFSPython = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setSetsVariables,
  setHighlightedMapIndex,
  setHighlightedSetIndex,
  setHighlightedSetVariables,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  );
}


class GraphForJava {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setmapVariables
  ) {
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    
    if (!directed) {
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }
  }

  async dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  ) {
    if (!this.graph.has(start)) {
      await logMessage("Not a valid vertex to start.");
      return;
    }

    await highlightLine(9);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    await customSleep(9);
    setHighlightedVariables([]);

    await highlightLine(10);
    let visited = new Set();
    setSetsVariables((vars) => ({ ...vars, visited: { variable_name: 'visited', value: visited } }));
    setHighlightedSetVariables(['visited']);
    await customSleep(10);
    setHighlightedSetVariables([]);

    await highlightLine(11);
    let stack = [];
    setStackVariables({ stack: { variable_name: 'stack', value: stack } });
    setHighlightedStackVariables(['stack']);
    await customSleep(11);
    setHighlightedStackVariables([]);

    await highlightLine(12);
    stack = [""];
    setStackVariables({ stack: { variable_name: 'stack', value: stack } });
    setHighlightedStackIndex([{ stackName: 'stack', index: stack.length - 1 }]);
    await customSleep(null, 1200);
    stack[stack.length - 1] = start;
    setStackVariables({ stack: { variable_name: 'stack', value: stack } });
    await customSleep(12);
    setHighlightedStackIndex([]); 

    if (!(stack.length > 0)) {
      await highlightLine(13);
      setHighlightedStackVariables(['stack']);
      await customSleep(13);
      setHighlightedStackVariables([]);
    }

    while (stack.length > 0) {
      await highlightLine(13);
      setHighlightedStackVariables(['stack']);
      await customSleep(13);
      setHighlightedStackVariables([]);

      await highlightLine(14);
      setHighlightedStackIndex([{ stackName: 'stack', index: stack.length - 1 }]);
      await customSleep(null, 1200);
      setHighlightedStackIndex([]);
      let vertex = stack.pop();
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setHighlightedVariables(['vertex']);
      await customSleep(14);
      setHighlightedVariables([]);

      await highlightLine(15);
      setHighlightedVariables(['vertex']);
      setHighlightedSetIndex([{ setName: 'visited', key: vertex }]);
      await customSleep(15);
      setHighlightedVariables([]);
      setHighlightedSetIndex([]);
      if (!visited.has(vertex)) {
        await highlightLine(16);
        setHighlightedVariables(['vertex']);
        setHighlightedGraphVariables([this.address[vertex]]);
        await logMessage(`${vertex} `);
        await customSleep(16);
        setHighlightedVariables([]);
        setHighlightedGraphVariables([]);

        await highlightLine(17);
        setHighlightedVariables(['vertex']);
        setHighlightedGraphVariables([this.address[vertex]]);
        visited.add(vertex);
        setHighlightedSetIndex([{ setName: 'visited', key: vertex }]);
        await customSleep(17);
        setHighlightedVariables([]);
        setHighlightedGraphVariables([]);
        setHighlightedSetIndex([]);

        if (this.graph.get(vertex).length === 0) {
          await highlightLine(18);
          await logMessage(`Vertex ${vertex} has no neighbors.`);
          await customSleep(18);
        }

        let countNeighbor = 0;
        for (let neighbor of this.graph.get(vertex).slice().reverse()) {
          countNeighbor += 1;

          await highlightLine(18);
          setHighlightedMapIndex([{ mapName: 'graph', key:  `${vertex}`}]);
          setVariables((vars) => ({ ...vars, 
            neighbor: { variable_name: 'neighbor', value: neighbor }
          }));
          setHighlightedVariables(['neighbor']);
          await customSleep(18);
          setHighlightedVariables([]);

          await highlightLine(19);
          setHighlightedVariables(['neighbor']);
          setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
          await customSleep(19);
          setHighlightedVariables([]);
          setHighlightedSetIndex([]);
          if (!visited.has(neighbor)) {
            await highlightLine(20);
            setHighlightedVariables(['neighbor']);
            stack.push("");
            setHighlightedStackIndex([{ stackName: 'stack', index: stack.length - 1 }]);
            await customSleep(null, 1200);
            stack[stack.length - 1] = neighbor;
            setStackVariables({ stack: { variable_name: 'stack', value: stack } });
            await customSleep(20);
            setHighlightedVariables([]);
            setHighlightedStackIndex([]);
          } else {
            await logMessage(`Neighbor ${neighbor} already visited. Iterating the next neighbor.`);
          }

          if (this.graph.get(vertex).length === countNeighbor) {
            await highlightLine(18);
            setVariables((vars) => {
              const { neighbor, ...rest } = vars;  // Destructure and exclude `neighbor`
              return { ...rest };  // Return the updated state without `neighbor`
            });
            await logMessage(`Loop through all the neighbors completed.`);
            await customSleep(18);
          }
        }
        setHighlightedMapIndex([]);
      }

      if (!(stack.length > 0)) {
        await highlightLine(13);
        setHighlightedStackVariables(['stack']);
        await customSleep(13);
        setHighlightedStackVariables([]);
      }
    }

    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));

    // Emptying set variable
    setSetsVariables(() => ({}));

  }

}

export const graphJava = async (
  graphInput,
  edgeDirection,
  setGraphVariables,
  setmapVariables
  ) => {
    const graph = new GraphForJava();
    
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      edgeDirection[0],
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      edgeDirection[1],
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      edgeDirection[2],
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      edgeDirection[3],
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      edgeDirection[4],
      setGraphVariables,
      setmapVariables
    );  // Directed edge

    return graph;
}

export const graphDFSJava = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setSetsVariables,
  setHighlightedMapIndex,
  setHighlightedSetIndex,
  setHighlightedSetVariables,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  );
}

class GraphForCpp {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setmapVariables
  ) {
    
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));

      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    }

    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    
    if (!directed) {
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }

      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));

      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }
  }

  async dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  ) {
    if (!this.graph.has(start)) {
      await logMessage("Not a valid vertex to start.");
      return;
    }

    await highlightLine(14);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    await customSleep(14);
    setHighlightedVariables([]);

    await highlightLine(15);
    let visited = new Set();
    setSetsVariables((vars) => ({ ...vars, visited: { variable_name: 'visited', value: visited } }));
    setHighlightedSetVariables(['visited']);
    await customSleep(15);
    setHighlightedSetVariables([]);

    await highlightLine(16);
    let s = [];
    setStackVariables({ s: { variable_name: 's', value: s } });
    setHighlightedStackVariables(['s']);
    await customSleep(16);
    setHighlightedStackVariables([]);

    await highlightLine(17);
    s = [""];
    setStackVariables({ s: { variable_name: 's', value: s } });
    setHighlightedStackIndex([{ stackName: 's', index: s.length - 1 }]);
    await customSleep(null, 1200);
    s[s.length - 1] = start;
    setStackVariables({ s: { variable_name: 's', value: s } });
    await customSleep(17);
    setHighlightedStackIndex([]); 

    if (!(s.length > 0)) {
      await highlightLine(18);
      setHighlightedStackVariables(['s']);
      await customSleep(18);
      setHighlightedStackVariables([]);
    }

    while (s.length > 0) {
      await highlightLine(18);
      setHighlightedStackVariables(['s']);
      await customSleep(18);
      setHighlightedStackVariables([]);

      await highlightLine(19);
      setHighlightedStackIndex([{ stackName: 's', index: s.length - 1 }]);
      await customSleep(null, 1200);
      let vertex = s[s.length - 1];
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setHighlightedVariables(['vertex']);
      await customSleep(19);
      setHighlightedStackIndex([]);
      setHighlightedVariables([]);

      await highlightLine(20);
      setHighlightedStackIndex([{ stackName: 's', index: s.length - 1 }]);
      await customSleep(null, 1200);
      setHighlightedStackIndex([]);
      s.pop();
      await customSleep(20);

      await highlightLine(21);
      setHighlightedVariables(['vertex']);
      setHighlightedSetIndex([{ setName: 'visited', key: vertex }]);
      await customSleep(21);
      setHighlightedVariables([]);
      setHighlightedSetIndex([]);
      if (!visited.has(vertex)) {
        await highlightLine(22);
        setHighlightedVariables(['vertex']);
        setHighlightedGraphVariables([this.address[vertex]]);
        await logMessage(`${vertex} `);
        await customSleep(22);
        setHighlightedVariables([]);
        setHighlightedGraphVariables([]);

        await highlightLine(23);
        setHighlightedVariables(['vertex']);
        setHighlightedGraphVariables([this.address[vertex]]);
        visited.add(vertex);
        setHighlightedSetIndex([{ setName: 'visited', key: vertex }]);
        await customSleep(23);
        setHighlightedVariables([]);
        setHighlightedGraphVariables([]);
        setHighlightedSetIndex([]);

        if (this.graph.get(vertex).length === 0) {
          await highlightLine(24);
          await logMessage(`Vertex ${vertex} has no neighbors.`);
          await customSleep(24);
        }

        let countNeighbor = 0;
        for (let neighbor of this.graph.get(vertex).slice().reverse()) {
          countNeighbor += 1;

          await highlightLine(24);
          setHighlightedMapIndex([{ mapName: 'graph', key:  `${vertex}`}]);
          setVariables((vars) => ({ ...vars, 
            neighbor: { variable_name: 'neighbor', value: neighbor }
          }));
          setHighlightedVariables(['neighbor']);
          await customSleep(24);
          setHighlightedVariables([]);

          await highlightLine(25);
          setHighlightedVariables(['neighbor']);
          setHighlightedSetIndex([{ setName: 'visited', key: neighbor }]);
          await customSleep(25);
          setHighlightedVariables([]);
          setHighlightedSetIndex([]);
          if (!visited.has(neighbor)) {
            await highlightLine(26);
            setHighlightedVariables(['neighbor']);
            s.push("");
            setHighlightedStackIndex([{ stackName: 's', index: s.length - 1 }]);
            await customSleep(null, 1200);
            s[s.length - 1] = neighbor;
            setStackVariables({ s: { variable_name: 's', value: s } });
            await customSleep(26);
            setHighlightedVariables([]);
            setHighlightedStackIndex([]);
          } else {
            await logMessage(`Neighbor ${neighbor} already visited. Iterating the next neighbor.`);
          }

          if (this.graph.get(vertex).length === countNeighbor) {
            await highlightLine(24);
            setVariables((vars) => {
              const { neighbor, ...rest } = vars;  // Destructure and exclude `neighbor`
              return { ...rest };  // Return the updated state without `neighbor`
            });
            await logMessage(`Loop through all the neighbors completed.`);
            await customSleep(24);
          }
        }
        setHighlightedMapIndex([]);
      }

      if (!(s.length > 0)) {
        await highlightLine(18);
        setHighlightedStackVariables(['s']);
        await customSleep(18);
        setHighlightedStackVariables([]);
      }
    }

    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));

    // Emptying set variable
    setSetsVariables(() => ({}));

  }

}

export const graphCpp = async (
  graphInput,
  edgeDirection,
  setGraphVariables,
  setmapVariables
  ) => {
    const graph = new GraphForCpp();
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      edgeDirection[0],
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      edgeDirection[1],
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      edgeDirection[2],
      setGraphVariables,
      setmapVariables
    );
    
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      edgeDirection[3],
      setGraphVariables,
      setmapVariables
    );

    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      edgeDirection[4],
      setGraphVariables,
      setmapVariables
    );  // Directed edge

  return graph;
}

export const graphDFSCpp = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setSetsVariables,
  setHighlightedMapIndex,
  setHighlightedSetIndex,
  setHighlightedSetVariables,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setSetsVariables,
    setHighlightedMapIndex,
    setHighlightedSetIndex,
    setHighlightedSetVariables,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    customSleep
  );
}

class GraphForC {
  constructor(maxVertices) {
    this.graph = new Map();
    this._graph = {};
    this.address = {};
    this.adjLists = new Array(maxVertices).fill(null);
    this.maxVertices = maxVertices;
  }

  async createNode(
    v,
    setNodeVariables
  ) {

    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(v, nodeAddress);
    setNodeVariables({
      [newNode.address]: { 
        variable_name: newNode.address,
        value: { data: '', next: '' },
        address: newNode.address,
      }
    });
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'NULL'},
        address: newNode.address,
      }
    }));
    
    return newNode;
  }

  async createGraph(
    setVariables,
    setAdjList,
    setMapVariablesForC
  ) {

    ;
    setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    // let _graph = {};
    for (let i = 0; i < this.maxVertices; i++) {
      this._graph[i] = ``;
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
  
        if (updatedList[i]) {
          updatedList[i] = [...updatedList[i], ``];
        } else {
          updatedList[i] = ``;
        }
  
        return updatedList;
      });
    }
    setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: this._graph } }));
    

    for (let i = 0; i < this.maxVertices; i++) {
      setVariables((vars) => ({ ...vars, 
        i: { variable_name: 'i', value: i }
      }));
      
      this._graph[i] = 'NULL';
      setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: this._graph } }));
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
  
        if (updatedList[i]) {
          updatedList[i] = [...updatedList[i], 'NULL'];
        } else {
          updatedList[i] = 'NULL';
        }
  
        return updatedList;
      });
    }

  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setVariables,
    setAdjList,
    setNodeVariables
  ) {

    let newNode = await this.createNode(
      neighbor,
      setNodeVariables
    )
    
    newNode.next = this.adjLists[vertex];
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: `${this._graph[vertex]}`},
        address: newNode.address,
      }
    }));
    
    setNodeVariables(() => ({}));
    this.adjLists[vertex] = newNode;
    this._graph[vertex] = newNode.address;
    setAdjList((prevAdjList) => {
      const updatedList = [...prevAdjList];
      let node = {
        'address': newNode.address,
        'data': newNode.data,
        'next': newNode.next === null ? 'NULL' : newNode.next.address,
      };

      if (updatedList[vertex] && typeof updatedList[vertex] !== 'string') {
        updatedList[vertex] = [node, ...updatedList[vertex]];
      } else {
        updatedList[vertex] = [node];
      }

      return updatedList;
    });
    
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);
      this.address[vertex] = generateMemoryAddress();

      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
    }

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);
      this.address[neighbor] = generateMemoryAddress();

      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
    }

    this.graph.get(vertex).push(neighbor);
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));

    
    if (!directed) {
      let newNode = await this.createNode(
        vertex,
        setNodeVariables
      )
      
      newNode.next = this.adjLists[neighbor];
      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: `${this._graph[neighbor]}`},
          address: newNode.address
        }
      }));
      
      setNodeVariables(() => ({}));
      this.adjLists[neighbor] = newNode;
      this._graph[neighbor] = newNode.address;
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
        let node = {
          'address': newNode.address,
          'data': newNode.data,
          'next': newNode.next === null ? 'NULL' : newNode.next.address
        };
  
        if (updatedList[neighbor] && typeof updatedList[neighbor] !== 'string') {
          updatedList[neighbor] = [node, ...updatedList[neighbor]];
        } else {
          updatedList[neighbor] = [node];
        }
  
        return updatedList;
      });

      this.graph.get(neighbor).push(vertex);
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
    }

    // Emptying vaiables
    setVariables(() => ({}));
  }

  async dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setHighlightedMapVariablesForC,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    setHighlightedMapIndexForC,
    setHighlightedNodeVariables,
    setNodeVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    logMessage,
    customSleep
  ) {

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, 
        start: { variable_name: 'start', value: start }
    }));
    setHighlightedVariables(['start']);
    setHighlightedMapVariablesForC(['graph']);
    await customSleep(5);
    setHighlightedVariables([]);
    setHighlightedMapVariablesForC([]);

    await highlightLine(6);
    const visited = new Array(this.maxVertices).fill(false);
    const _visited = new Array(this.maxVertices).fill(false);
    setStackVariables({ visited: { variable_name: 'visited', value: _visited } });
    setHighlightedStackVariables(['visited']);
    setHighlightedVariables(['MAX_VERTICES']);
    await customSleep(null, 1200);
    setHighlightedStackVariables([]);
    setHighlightedStackIndex([{ stackName: 'visited', index: 0 }]);
    await customSleep(null, 1200);
    _visited[0] = 0;
    setStackVariables({ visited: { variable_name: 'visited', value: _visited } });
    await customSleep(6);
    setHighlightedStackIndex([]);
    setHighlightedVariables([]);

    await highlightLine(7);
    let stack = [];
    let _stack = new Array(this.maxVertices).fill(false);
    setHighlightedVariables(['MAX_VERTICES']);
    setStackVariables((vars) => ({ ...vars, stack: { variable_name: 'stack', value: _stack } }));
    setHighlightedStackVariables(['stack']);
    await customSleep(7);
    setHighlightedStackVariables([]);
    setHighlightedVariables([]);

    await highlightLine(8);
    let top = -1;
    setVariables((vars) => ({ ...vars, 
        top: { variable_name: 'top', value: top }
    }));
    setHighlightedVariables(['top']);
    await customSleep(8);
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['top']);
    await customSleep(null, 1200);
    top += 1;
    setVariables((vars) => ({ ...vars, 
        top: { variable_name: 'top', value: top }
    }));
    await customSleep(null, 1200);
    _stack[top] = " ";
    setStackVariables((vars) => ({ ...vars, stack: { variable_name: 'stack', value: _stack } }));
    setStrikeThroughStackIndex((prevState) =>
        prevState.filter(item => item.stackName !== 'stack' || item.index !== top)
    );
    setHighlightedStackIndex([{ stackName: 'stack', index: top }]);
    await customSleep(null, 1200);
    stack.push(start);
    _stack[top] = start;
    setStackVariables((vars) => ({ ...vars, stack: { variable_name: 'stack', value: _stack } }));
    await customSleep(10);
    setHighlightedStackIndex([]);
    setHighlightedVariables([]);

    if (!(top >= 0)) {
      await highlightLine(12);
      setHighlightedVariables(['top']);
      await customSleep(12);
      setHighlightedVariables([]);
    }
    while (top >= 0) {
      await highlightLine(12);
      setHighlightedVariables(['top']);
      await customSleep(12);
      setHighlightedVariables([]);

      await highlightLine(13);
      setHighlightedStackIndex([{ stackName: 'stack', index: top }]);
      setHighlightedVariables(['top']);
      await customSleep(null, 1200);
      const vertex = stack.pop();
      setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex }
      }));
      setHighlightedVariables(['vertex', 'top']);
      await customSleep(null, 1200);
      setHighlightedVariables(['top']);
      let strikeIndex = top;
      setStrikeThroughStackIndex((prevState) => [
          ...prevState, 
          { stackName: 'stack', index: strikeIndex }
      ]);
      top -= 1;
      setVariables((vars) => ({ ...vars, 
        top: { variable_name: 'top', value: top }
      }));
      await customSleep(13);
      setHighlightedStackIndex([]);
      setHighlightedVariables([]);

      await highlightLine(14);
      setHighlightedVariables(['vertex']);
      setHighlightedStackIndex([{ stackName: 'visited', index: vertex }]);
      await customSleep(14);
      setHighlightedVariables([]);
      setHighlightedStackIndex([]);
      if (!visited[vertex]) {
        await highlightLine(15);
        setHighlightedGraphVariables([this.address[vertex]]);
        setHighlightedVariables(['vertex']);
        await logMessage(`${vertex} `);
        await customSleep(15);
        setHighlightedGraphVariables([]);
        setHighlightedVariables([]);

        await highlightLine(16);
        setHighlightedVariables(['vertex']);
        setHighlightedStackIndex([{ stackName: 'visited', index: vertex }]);
        await customSleep(null, 1200);
        visited[vertex] = true;
        _visited[vertex] = 1;
        setStackVariables((vars) => ({ ...vars, 
          visited: { variable_name: 'visited', value: _visited } 
        }));
        await customSleep(16);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);
      }

      await highlightLine(18);
      setHighlightedVariables(['vertex']);
      setHighlightedGraphVariables([this.address[vertex]]);
      setHighlightedMapIndexForC([{ mapName: 'graph', key: `${vertex}` }]);
      let temp = this.adjLists[vertex];
      setHighlightedNodeVariables([`${temp ? temp.address : ''}`]);
      setHighlightNodeAddress([`${temp ? temp.address : ''}`]);
      await customSleep(null, 1200);
      setHighlightedNodeVariables([]);
      let nodeAddress = generateMemoryAddress();
      setNodeVariables({ nodeAddress: { 
        variable_name: nodeAddress,
        node_name: 'temp',
        value: {
          data: temp ? temp.data : 'NULL',
          next: temp ? temp.next ? temp.next.address : 'NULL' : 'NULL'
        },
        address: nodeAddress,
      }});
      setHighlightedNodeVariables([`${nodeAddress}`]);
      await customSleep(18);
      setHighlightedVariables([]);
      setHighlightedGraphVariables([]);
      setHighlightedMapIndexForC([]);
      setHighlightedNodeVariables([]);
      setHighlightNodeAddress([]);

      if (!(temp)) {
        await highlightLine(19);
        setHighlightedNodeVariables([`${nodeAddress}`]);
        await customSleep(19);
        setHighlightedNodeVariables([]);
      }
      while (temp) {
        await highlightLine(19);
        setHighlightedNodeVariables([`${nodeAddress}`]);
        await customSleep(19);
        setHighlightedNodeVariables([]);

        await highlightLine(20);
        setHighlightDataPart([`${nodeAddress}`]);
        setHighlightedStackIndex([{ stackName: 'visited', index: temp.data }]);
        await customSleep(20);
        setHighlightedNodeVariables([]);
        setHighlightedStackIndex([]);
        setHighlightDataPart([]);
        if (!visited[temp.data]) {
          await highlightLine(21);
          setHighlightedVariables(['top']);
          await customSleep(null, 1200);
          top += 1;
          setVariables((vars) => ({ ...vars, 
              top: { variable_name: 'top', value: top }
          }));
          await customSleep(null, 1200);
          _stack[top] = " ";
          setStackVariables((vars) => ({ ...vars, stack: { variable_name: 'stack', value: _stack } }));
          setStrikeThroughStackIndex((prevState) =>
              prevState.filter(item => item.stackName !== 'stack' || item.index !== top)
          );
          setHighlightedStackIndex([{ stackName: 'stack', index: top }]);
          await customSleep(null, 1200);
          setHighlightDataPart([`${nodeAddress}`]);
          setHighlightedGraphVariables([this.address[temp.data]]);
          await customSleep(null, 1200);
          stack.push(temp.data);
          _stack[top] = temp.data;
          setStackVariables((vars) => ({ ...vars, stack: { variable_name: 'stack', value: _stack } }));
          await customSleep(21);
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
          setHighlightDataPart([]);
          setHighlightedGraphVariables([]);
        }

        await highlightLine(23);
        setHighlightNextAddressPart([`${nodeAddress}`]);
        setHighlightedNodeVariables([`${temp.next ? temp.next.address : ''}`]);
        await customSleep(null, 1200);
        temp = temp.next;
        nodeAddress = generateMemoryAddress();
        setNodeVariables({ nodeAddress: { 
          variable_name: nodeAddress,
          node_name: 'temp',
          value: {
            data: temp ? temp.data : 'NULL',
            next: temp ? temp.next ? temp.next.address : 'NULL' : 'NULL'
          },
          address: nodeAddress,
        }});
        setHighlightedNodeVariables([`${nodeAddress}`]);
        await customSleep(23);
        setHighlightedNodeVariables([]);

        if (!(temp)) {
          await highlightLine(19);
          setHighlightedNodeVariables([`${nodeAddress}`]);
          await customSleep(19);
          setHighlightedNodeVariables([]);
        }
      }

      if (!(top >= 0)) {
        await highlightLine(12);
        setHighlightedVariables(['top']);
        await customSleep(12);
        setHighlightedVariables([]);
      }

      // Emptying node vaiables
      setNodeVariables(() => ({}));
    }

    // Emptying vaiables
    setVariables(() => ({}));

    // Emptying stack variable
    setStackVariables(() => ({}));
  }

}

export const graphC = async (
  maxVertices,
  graphInput,
  edgeDirection,
  setGraphVariables,
  setVariables,
  setAdjList,
  setMapVariablesForC,
  setNodeVariables,
  ) => {
    setVariables((vars) => ({ ...vars, 
      MAX_VERTICES: { variable_name: 'MAX_VERTICES', value: maxVertices }
    }));
    
    const graph = new GraphForC(maxVertices);
    await graph.createGraph(
      setVariables,
      setAdjList,
      setMapVariablesForC
    );
    
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      edgeDirection[0],
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
    
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      edgeDirection[1],
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
    
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      edgeDirection[2],
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
    
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      edgeDirection[3],
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );

    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      edgeDirection[4],
      setGraphVariables,
      setVariables,
      setAdjList,
      setNodeVariables
    );
  return graph;
}

export const graphDFSC = async (
  start,
  globalGraphInstance,
  highlightLine,
  setVariables,
  setHighlightedVariables,
  setHighlightedGraphVariables,
  setHighlightedMapVariablesForC,
  setStackVariables,
  setHighlightedStackVariables,
  setHighlightedStackIndex,
  setStrikeThroughStackIndex,
  setHighlightedMapIndexForC,
  setHighlightedNodeVariables,
  setNodeVariables,
  setHighlightDataPart,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  logMessage,
  customSleep
) => {

  await globalGraphInstance.dfs(
    start,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedGraphVariables,
    setHighlightedMapVariablesForC,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    setHighlightedMapIndexForC,
    setHighlightedNodeVariables,
    setNodeVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    logMessage,
    customSleep
  );
}
