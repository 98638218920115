import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This program demonstrates the <b>immutability of strings</b>. When a new string is 
        created by concatenating <b>"Hello"</b> with <b>" World"</b>, the reference is updated to point to 
        the new string. The original string "Hello" remains unchanged in memory, showcasing the immutable nature of strings.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>Security:</b> Immutable strings ensure data integrity for sensitive information, such as passwords or cryptographic keys.</li>
            <li><b>Caching:</b> String immutability allows safe reuse in caching mechanisms without risk of unintended modifications.</li>
            <li><b>Concurrency:</b> Immutability makes strings inherently thread-safe in multi-threaded applications.</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
