import { useState, useEffect, useRef } from 'react';
import { 
  binaryTreePython,
  binaryTreeJava,
  binaryTreeCpp,
  binaryTreeC,
  binaryTreeLevelorderTraversalPython,
  binaryTreeLevelorderTraversalJava,
  binaryTreeLevelorderTraversalCpp,
  binaryTreeLevelorderTraversalC
} from './binaryTreeAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useBinaryTree = (language, data, breakpoints) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [secondCallingLine, setSecondCallingLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [nodeVariables, setNodeVariables] = useState({});
  const [singleNodeVariable, setSingleNodeVariable] = useState({});
  const [highlightSingleNodeDataPart, setHighlightSingleNodeDataPart] = useState([]);
  const [highlightSingleNodeLeftPart, setHighlightSingleNodeLeftPart] = useState([]);
  const [highlightSingleNodeRightPart, setHighlightSingleNodeRightPart] = useState([]);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedNodeVariables, setHighlightedNodeVariables] = useState([]);
  const [highlightedSingleNodeVariables, setHighlightedSingleNodeVariables] = useState([]);
  const [rootVariables, setRootVariables] = useState({});
  const [highlightedRootVariables, setHighlightedRootVariables] = useState([]);
  const [highlightDataPart, setHighlightDataPart] = useState([]);
  const [highlightLeftPart, setHighlightLeftPart] = useState([]);
  const [highlightRightPart, setHighlightRightPart] = useState([]);
  const [highlightNodeAddress, setHighlightNodeAddress] = useState([]);
  const [stackVariables, setStackVariables] = useState({});
  const [highlightedStackVariables, setHighlightedStackVariables] = useState([]);
  const [highlightedStackIndex, setHighlightedStackIndex] = useState([]);
  const [strikeThroughStackIndex, setStrikeThroughStackIndex] = useState([]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const hasRun = useRef(false);
  const [globalBinaryTree, setGlobalBinaryTree] = useState(null);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  const updateVariables = () => {
    resetState();
    createTreeVisual(language);
  };

  const createTreeVisual = (language) => {
    if (language === 'Python') {
      createBinaryTreePython();
    } else if (language === 'Java') {
      createBinaryTreeJava();
    } else if (language === 'C') {
      createBinaryTreeC();
    } else if (language === 'C++') {
      createBinaryTreeCpp();
    }
  }

  useEffect(() => {
    if (!hasRun.current) {
      createTreeVisual(language);
      hasRun.current = true;
    }
  }, []);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setCallingLine(null);
    setSecondCallingLine(null);
    setVariables({});
    setRootVariables({});
    setNodeVariables({});
    setSingleNodeVariable({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedNodeVariables([]);
    setHighlightedSingleNodeVariables([]);
    setHighlightedRootVariables([]);
    setHighlightSingleNodeLeftPart([]);
    setHighlightSingleNodeDataPart([]);
    setHighlightSingleNodeRightPart([]);
    setHighlightLeftPart([]);
    setHighlightDataPart([]);
    setHighlightRightPart([]);
    setHighlightNodeAddress([]);
    setStackVariables({});
    setHighlightedStackVariables([]);
    setHighlightedStackIndex([]);
    setStrikeThroughStackIndex([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setSecondCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const highlightSecondCallingLine = async (lineNumber) => {
    setSecondCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null, time=null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  // Run for Python
  const createBinaryTreePython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'None' }
    }));
    
    const binaryTree = await binaryTreePython(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables
    );

    setGlobalBinaryTree(binaryTree);

    setIsRunning(false);
  };

  // Run for Python
  const createBinaryTreeJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'None' }
    }));
    
    const binaryTree = await binaryTreeJava(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables
    );

    setGlobalBinaryTree(binaryTree);

    setIsRunning(false);
  };

  // Run for Cpp
  const createBinaryTreeCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'None' }
    }));
    
    const binaryTree = await binaryTreeCpp(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables
    );

    setGlobalBinaryTree(binaryTree);

    setIsRunning(false);
  };

  const runBinaryTreeLevelorderTraversalPython = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(24);
    await customSleep(24);
    
    await highlightCallingLine(24);
    await binaryTreeLevelorderTraversalPython(
      globalBinaryTree,
      setRootVariables,
      setHighlightedRootVariables,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      logMessage,
      highlightLine,
      customSleep
    );
    await highlightLine(24);
    await customSleep(24);

    await focusEndLine(24);
    setIsRunning(false);
  }

  const runBinaryTreeLevelorderTraversalJava = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(35);
    await customSleep(35);
    
    await highlightCallingLine(35);
    await binaryTreeLevelorderTraversalJava(
      globalBinaryTree,
      setRootVariables,
      setHighlightedRootVariables,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      logMessage,
      highlightLine,
      customSleep
    );
    await highlightLine(35);
    await customSleep(35);

    await focusEndLine(35);
    setIsRunning(false);
  }

  const runBinaryTreeLevelorderTraversalCpp = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(37);
    await customSleep(37);
    
    await highlightCallingLine(37);
    await binaryTreeLevelorderTraversalCpp(
      globalBinaryTree,
      setRootVariables,
      setHighlightedRootVariables,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      logMessage,
      highlightLine,
      customSleep
    );
    await highlightLine(37);
    await customSleep(37);

    await highlightLine(38);
    await customSleep(38);

    await focusEndLine(39);
    setIsRunning(false);
  }

  // Run for Cpp
  const createBinaryTreeC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'None' }
    }));
    
    const binaryTree = await binaryTreeC(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables
    );

    setGlobalBinaryTree(binaryTree);

    setIsRunning(false);
  };

  const runBinaryTreeLevelorderTraversalC = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(36);
    await customSleep(36);
    
    await highlightCallingLine(36);
    await binaryTreeLevelorderTraversalC(
      globalBinaryTree,
      setVariables,
      setRootVariables,
      setHighlightedVariables,
      setHighlightedRootVariables,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      setStrikeThroughStackIndex,
      logMessage,
      highlightLine,
      highlightSecondCallingLine,
      customSleep
    );
    await highlightLine(36);
    await customSleep(36);

    await highlightLine(37);
    await customSleep(37);

    await focusEndLine(38);
    setIsRunning(false);
  }

  // Main function to run based on language
  const runBinaryTree = async () => {
    if (language === 'Python') {
      await runBinaryTreeLevelorderTraversalPython();
    } else if (language === 'Java') {
      await runBinaryTreeLevelorderTraversalJava();
    } else if (language === 'C') {
      await runBinaryTreeLevelorderTraversalC();
    } else if (language === 'C++') {
      await runBinaryTreeLevelorderTraversalCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    secondCallingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    rootVariables,
    nodeVariables,
    singleNodeVariable,
    stackVariables,
    highlightedVariables,
    highlightedRootVariables,
    highlightedNodeVariables,
    highlightedSingleNodeVariables,
    highlightSingleNodeDataPart,
    highlightSingleNodeLeftPart,
    highlightSingleNodeRightPart,
    highlightLeftPart,
    highlightDataPart,
    highlightRightPart,
    highlightNodeAddress,
    highlightedStackVariables,
    highlightedStackIndex,
    strikeThroughStackIndex,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setDelay,
    setIsPaused,
    setCallingLine,
    setSecondCallingLine,
    runBinaryTree,
  };
};
