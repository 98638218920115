const codeSamples = {
    Python: [
      "def binary_search(sorted_list, target_element):",
      "    low, high = 0, len(sorted_list) - 1",
      "    while low <= high:",
      "        mid = (low + high) // 2",
      "        mid_element = sorted_list[mid]",
      "        if mid_element == target_element:",
      "            return mid",
      "        elif mid_element < target_element:",
      "            low = mid + 1",
      "        else:",
      "            high = mid - 1",
      "    return -1",
      "  ",
      "if __name__ == '__main__':",
      "    sorted_list = [2, 3, 4, 10, 40]",
      "    target_element = 10",
      "    result = binary_search(sorted_list, target_element)",
      "    if result != -1:",
      "        print(f'Element {target_element} found at index {result}.')",
      "    else:",
      "        print(f'Element {target_element} not found in the list.')"
    ],
    Java: [
      "public class BinarySearch {",
      "    int binarySearch(int sortedArray[], int targetElement) {",
      "        int low = 0, high = sortedArray.length - 1;",
      "        while (low <= high) {",
      "            int mid = (low + high) / 2;",
      "            if (sortedArray[mid] == targetElement) {",
      "                return mid;",
      "            }",
      "            else if (sortedArray[mid] < targetElement){",
      "                low = mid + 1;",
      "            }",
      "            else {",
      "                high = mid - 1;",
      "            }",
      "        }",
      "        return -1;",
      "    }",
      "    public static void main(String args[]) {",
      "        BinarySearch bs = new BinarySearch();",
      "        int sortedArray[] = {2, 3, 4, 10, 40};",
      "        int targetElement = 10;",
      "        int result = bs.binarySearch(sortedArray, targetElement);",
      "        if (result != -1) {",
      "            System.out.println('Element found at index ' + result);",
      "        }",
      "        else {",
      "            System.out.println('Element not found');",
      "        }",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "  ",
      "int binarySearch(int sortedArray[], int size, int targetElement) {",
      "    int low = 0, high = size - 1;",
      "    while (low <= high) {",
      "        int mid = (low + high) / 2;",
      "        if (sortedArray[mid] == targetElement) {",
      "            return mid;",
      "        }",
      "        else if (sortedArray[mid] < targetElement) {",
      "            low = mid + 1;",
      "        }",
      "        else {",
      "            high = mid - 1;",
      "        }",
      "    }",
      "    return -1;",
      "}",
      "  ",
      "int main() {",
      "    int sortedArray[] = {2, 3, 4, 10, 40};",
      "    int size = sizeof(sortedArray) / sizeof(sortedArray[0]);",
      "    int targetElement = 10;",
      "    int result = binarySearch(sortedArray, size, targetElement);",
      "    if (result != -1) {",
      "        printf('Element found at index %d', result);",
      "    }",
      "    else {",
      "        printf('Element not found');",
      "    }",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "int binarySearch(int sortedArray[], int size, int targetElement) {",
      "    int low = 0, high = size - 1;",
      "    while (low <= high) {",
      "        int mid = (low + high) / 2;",
      "        if (sortedArray[mid] == targetElement) {",
      "            return mid;",
      "        }",
      "        else if (sortedArray[mid] < targetElement) {",
      "            low = mid + 1;",
      "        }",
      "        else {",
      "            high = mid - 1;",
      "        }",
      "    }",
      "    return -1;",
      "}",
      "  ",
      "int main() {",
      "    int sortedArray[] = {2, 3, 4, 10, 40};",
      "    int size = sizeof(sortedArray) / sizeof(sortedArray[0]);",
      "    int targetElement = 10;",
      "    int result = binarySearch(array, size, targetElement);",
      "    if (result != -1) {",
      "        cout << 'Element found at index ' << result;",
      "    }",
      "    else {",
      "        cout << 'Element not found';",
      "    }",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  