import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        Illustrates the use of <b>enum</b> to define a set of constants representing workdays. 
        It checks if the given input matches a day in the WorkDay <b>enum</b>, printing a specific 
        message for Friday or a generic work message for other days, while handling invalid input gracefully.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        <ul>
            <li><b>State Representation:</b> Use enums for fixed sets of values like days of the week, months, or status codes.</li>
            <li><b>Input Validation:</b> Validate and handle input with restricted values, reducing bugs caused by invalid data.</li>
            <li><b>Code Readability:</b> Improve clarity by replacing arbitrary constants with descriptive names.</li>
            <li><b>Business Logic:</b> Enums simplify business rules involving predefined categories (e.g., weekdays vs. weekends).</li>
        </ul>
      </p>
    </div>
  );
};

export default AlgorithmDescription;
