export const dogConstructorForJava = async (
    initialClass,
    highlightLine,
    setStringVariables,
    setHighlightedStringVariables,
    customSleep
  ) => {

    await highlightLine(3);
    setStringVariables((vars) => ({ ...vars, name: { variable_name: 'name', value: initialClass.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep();
    setHighlightedStringVariables([]);

    await customSleep(3, 800);

    await highlightLine(4);
    setHighlightedStringVariables(['name']);
    await customSleep(4);
    setHighlightedStringVariables([]);
  };


  export const dogBarkFunctionForJava = async (
    initialClass,
    highlightLine,
    setHighlightedStringVariables,
    customSleep,
    logMessage
  ) => {

    await highlightLine(7);
    await customSleep(7);

    await highlightLine(8);
    setHighlightedStringVariables(['name']);
    await logMessage(`${initialClass.name} says: Woof!`);
    await customSleep(8);
    setHighlightedStringVariables([]);
  };


  export const dogSleepFunctionForJava = async (
    initialClass,
    highlightLine,
    setHighlightedStringVariables,
    customSleep,
    logMessage
  ) => {

    await highlightLine(11);
    await customSleep(11);

    await highlightLine(12);
    setHighlightedStringVariables(['name']);
    await logMessage(`${initialClass.name} is sleeping... Zzz...`);
    await customSleep(12);
    setHighlightedStringVariables([]);
  };

  export const dogInitForPython = async (
    initialClass,
    highlightLine,
    setStringVariables,
    setHighlightedStringVariables,
    customSleep
  ) => {

    await highlightLine(1);
    setStringVariables((vars) => ({ ...vars, name: { variable_name: 'name', value: initialClass.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep();
    setHighlightedStringVariables([]);

    await customSleep(1, 800);

    await highlightLine(2);
    setHighlightedStringVariables(['name']);
    await customSleep(2);
    setHighlightedStringVariables([]);
  };


  export const dogBarkFunctionForPython = async (
    initialClass,
    highlightLine,
    setHighlightedStringVariables,
    customSleep,
    logMessage
  ) => {

    await highlightLine(4);
    await customSleep(4);

    await highlightLine(5);
    setHighlightedStringVariables(['name']);
    await logMessage(`${initialClass.name} says: Woof!`);
    await customSleep(5);
    setHighlightedStringVariables([]);
  };


  export const dogSleepFunctionForPython = async (
    initialClass,
    highlightLine,
    setHighlightedStringVariables,
    customSleep,
    logMessage
  ) => {

    await highlightLine(7);
    await customSleep(7);

    await highlightLine(8);
    setHighlightedStringVariables(['name']);
    await logMessage(`${initialClass.name} is sleeping... Zzz...`);
    await customSleep(8);
    setHighlightedStringVariables([]);
  };

  export const dogConstructorForCpp = async (
    initialClass,
    highlightLine,
    setStringVariables,
    setHighlightedStringVariables,
    customSleep
  ) => {

    await highlightLine(8);
    setStringVariables((vars) => ({ ...vars, name: { variable_name: 'name', value: initialClass.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep(8);
    setHighlightedStringVariables([]);
  };


  export const dogBarkFunctionForCpp = async (
    initialClass,
    highlightLine,
    setHighlightedStringVariables,
    customSleep,
    logMessage
  ) => {

    await highlightLine(10);
    await customSleep(10);

    await highlightLine(11);
    setHighlightedStringVariables(['name']);
    await logMessage(`${initialClass.name} says: Woof!`);
    await customSleep(11);
    setHighlightedStringVariables([]);
  };


  export const dogSleepFunctionForCpp = async (
    initialClass,
    highlightLine,
    setHighlightedStringVariables,
    customSleep,
    logMessage
  ) => {

    await highlightLine(14);
    await customSleep(14);

    await highlightLine(15);
    setHighlightedStringVariables(['name']);
    await logMessage(`${initialClass.name} is sleeping... Zzz...`);
    await customSleep(15);
    setHighlightedStringVariables([]);
  };
